// import crypto from "crypto";

export function limitCharacter(sentence: string, maxLength: number): string {
	if (sentence?.length <= maxLength) {
		return sentence;
	} else if (sentence?.length >= maxLength) {
		return sentence?.slice(0, maxLength) + "...";
	} else if (sentence === "undefine" || sentence === null) {
		return "";
	}
}

export const convertByteIntoKbMb = (byte: number) => {
	const kb = byte / 1000;
	if (kb > 1000) {
		return (kb / 1000).toFixed(2) + " " + "mb";
	} else {
		return kb.toFixed(2) + " " + "kb";
	}
};

export function generateRandomId(length) {
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let randomId = "";
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		randomId += characters.charAt(randomIndex);
	}
	return randomId;
}

// Usage
const randomId = generateRandomId(10); // Generates a random ID of length 10
console.log(randomId);

// export function filterUnreadMessagesToTop(data) {
// 	const unreadMsgs = data.filter((item) => item.last_msg?.is_unread === true);
// 	const readMsgs = data.filter((item) => item.last_msg?.is_unread !== true);

// 	return [...unreadMsgs, ...readMsgs];
// }

// export const sortConversationsByLastMessage = (conversations) => {
// 	// Create a new array to avoid directly modifying the original one
// 	const sortedConversations = conversations.slice();

// 	sortedConversations.sort((a, b) => {
// 		const dateA = new Date(a?.last_message_created_at);
// 		const dateB = new Date(b?.last_message_created_at);
// 		return dateB - dateA;
// 	});

// 	return sortedConversations;
// };

export const organizeHistoryArray = (histories: any[], extNumber: string) => {
	const groups: any[][] = [];
	let currentGroup: any[] = [];

	for (let i = 0; i < histories.length; i++) {
		const currentHistory = histories[i];
		const lastHistory = currentGroup[currentGroup.length - 1];

		const currentIsInbound = String(currentHistory.cdr.dst) === String(extNumber);
		const currentComparisonValue = currentIsInbound ? currentHistory.cdr.src : currentHistory.cdr.dst;
		const currentStatus = currentHistory.cdr.status;

		if (currentGroup.length === 0) {
			currentGroup.push(currentHistory);
		} else {
			const lastIsInbound = String(lastHistory.cdr.dst) === String(extNumber);
			const lastComparisonValue = lastIsInbound ? lastHistory.cdr.src : lastHistory.cdr.dst;
			const lastStatus = lastHistory.cdr.status;

			if (
				String(currentComparisonValue) === String(lastComparisonValue) &&
				String(currentStatus) === String(lastStatus)
			) {
				currentGroup.push(currentHistory);
			} else {
				groups.push(currentGroup);
				currentGroup = [currentHistory];
			}
		}
	}

	if (currentGroup.length > 0) {
		groups.push(currentGroup);
	}

	return groups;
};

export const formatMilliseconds = (milliseconds: number) => {
	var seconds = Math.floor(Number(milliseconds) / 1000);
	var minutes = Math.floor(seconds / 60);
	var hours = Math.floor(minutes / 60);

	seconds %= 60;
	minutes %= 60;

	var result = "";
	if (hours > 0) {
		result += hours + "hr ";
	}
	if (minutes > 0 || hours > 0) {
		result += minutes + "min ";
	}
	result += seconds + " sec";

	return result;
};

export const compareCacheTimeOver = (cachedTime: string, cachedFor: number): boolean => {
	if (cachedTime && cachedFor) {
		const lastTime = new Date(parseInt(cachedTime, 10));
		const currentTime = new Date();
		const timeDifference = lastTime?.getTime() - currentTime?.getTime();
		const cachedForInMillis = cachedFor * 60 * 60 * 1000;
		return timeDifference >= cachedForInMillis;
	} else {
		return true;
	}
};
