import { IIcon } from "constants/interfaces";

const GroupIcon = ({ color }: IIcon) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
			<path
				d="M7.79029 3.41529C7.20424 4.00134 6.875 4.7962 6.875 5.625C6.875 6.4538 7.20424 7.24866 7.79029 7.83471C8.37634 8.42076 9.1712 8.75 10 8.75C10.8288 8.75 11.6237 8.42076 12.2097 7.83471C12.7958 7.24866 13.125 6.4538 13.125 5.625C13.125 4.7962 12.7958 4.00134 12.2097 3.41529C11.6237 2.82924 10.8288 2.5 10 2.5C9.1712 2.5 8.37634 2.82924 7.79029 3.41529Z"
				fill={`var(--${color ? color : "icon-primary"})`}
			/>
			<path
				d="M13.8572 6.35723C13.3884 6.82607 13.125 7.46196 13.125 8.125C13.125 8.78804 13.3884 9.42393 13.8572 9.89277C14.3261 10.3616 14.962 10.625 15.625 10.625C16.288 10.625 16.9239 10.3616 17.3928 9.89277C17.8616 9.42393 18.125 8.78804 18.125 8.125C18.125 7.46196 17.8616 6.82607 17.3928 6.35723C16.9239 5.88839 16.288 5.625 15.625 5.625C14.962 5.625 14.3261 5.88839 13.8572 6.35723Z"
				fill={`var(--${color ? color : "icon-primary"})`}
			/>
			<path
				d="M2.60723 6.35723C2.13839 6.82607 1.875 7.46196 1.875 8.125C1.875 8.78804 2.13839 9.42393 2.60723 9.89277C3.07607 10.3616 3.71196 10.625 4.375 10.625C5.03804 10.625 5.67393 10.3616 6.14277 9.89277C6.61161 9.42393 6.875 8.78804 6.875 8.125C6.875 7.46196 6.61161 6.82607 6.14277 6.35723C5.67393 5.88839 5.03804 5.625 4.375 5.625C3.71196 5.625 3.07607 5.88839 2.60723 6.35723Z"
				fill={`var(--${color ? color : "icon-primary"})`}
			/>
			<path
				d="M7.29635 10.6912C6.46757 11.1452 5.76663 11.8009 5.25833 12.5975V12.5983C4.56872 13.6831 4.26921 14.9705 4.40917 16.2483C4.41997 16.3461 4.45367 16.4398 4.50754 16.5221C4.5614 16.6043 4.63391 16.6727 4.71917 16.7217C6.275 17.615 8.07917 18.125 10 18.125C11.8528 18.1277 13.6738 17.6438 15.2808 16.7217C15.3661 16.6727 15.4386 16.6043 15.4925 16.5221C15.5463 16.4398 15.58 16.3461 15.5908 16.2483C15.6792 15.4618 15.6004 14.6654 15.3597 13.9114C15.119 13.1574 14.7217 12.4627 14.1939 11.8728C13.6661 11.2829 13.0197 10.8112 12.297 10.4885C11.5743 10.1657 10.7915 9.99928 10 10C9.05501 9.99947 8.12514 10.2372 7.29635 10.6912Z"
				fill={`var(--${color ? color : "icon-primary"})`}
			/>
			<path
				d="M3.14507 16.1574C3.03028 14.6502 3.4132 13.1469 4.23507 11.8783C3.81577 11.897 3.40456 12.0001 3.02597 12.1813C2.64737 12.3625 2.30916 12.6181 2.0315 12.9328C1.75385 13.2476 1.54244 13.6151 1.4099 14.0133C1.27736 14.4115 1.2264 14.8324 1.26007 15.2508L1.2684 15.3516C1.27542 15.442 1.30852 15.5285 1.36369 15.6006C1.41886 15.6726 1.49373 15.7271 1.57923 15.7574L1.67507 15.7908C2.15249 15.9588 2.64466 16.0816 3.14507 16.1574Z"
				fill={`var(--${color ? color : "icon-primary"})`}
			/>
			<path
				d="M15.7651 11.8783C16.5869 13.1469 16.9699 14.6502 16.8551 16.1574C17.3617 16.0808 17.8526 15.9566 18.3251 15.7899L18.4209 15.7566C18.5063 15.7263 18.5811 15.6719 18.6362 15.6001C18.6914 15.5282 18.7246 15.4419 18.7317 15.3516L18.7401 15.2508C18.7737 14.8324 18.7228 14.4115 18.5902 14.0133C18.4577 13.6151 18.2463 13.2476 17.9686 12.9328C17.691 12.6181 17.3528 12.3625 16.9742 12.1813C16.5956 12.0001 16.1844 11.897 15.7651 11.8783Z"
				fill={`var(--${color ? color : "icon-primary"})`}
			/>
		</svg>
	);
};

export default GroupIcon;
