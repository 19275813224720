import Button from "components/UI/Forms/Button";
import styles from "./NoActions.module.scss";
import PlusIcon from "components/UI/Icons/Sidecar/Plus";
import { useDispatch } from "react-redux";
import { setShowButtonProgramming, setShowSidecarSidebar } from "redux/sidecar/sidecarSlice";
import { useNavigate } from "react-router";
import routePaths from "./../../../constants/routes";

const NoActions = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	return (
		<section className={styles.noAction}>
			<div className={styles.noActionBox}>
				<h1>No Actions</h1>
				<p>When you add actions you’ll see them here</p>
				<div className={styles.addActionBox}>
					<Button fill onClick={()=>{
							// navigate(routePaths.SIDECAR.ROUTE);
							dispatch(setShowButtonProgramming(true));
							dispatch(setShowSidecarSidebar(false));
						}} >
						<PlusIcon />
						Add Action
					</Button>
				</div>
			</div>
		</section>
	);
};

export default NoActions;
