import UserStatusIcon from "components/UI/Icons/UserStatus";
import React from "react";
import styles from "./ChooseStatus.module.scss";
import AvailableIcon from "components/UI/Icons/Status/Available";
import AwayIcon from "components/UI/Icons/Status/Away";
import DNDIcon from "components/UI/Icons/Status/DND";
import DNDThisDeviceIcon from "components/UI/Icons/Status/DNDThisDevice";

const ChooseStatus = ( {onClick}:{onClick:(val:number)=>void}) => {
	return (
		<div className={styles.statusBox}>
			<h1>Choose Status</h1>
			<div>
				<p onClick={()=>{onClick(1)}}>
					<span>
						<AvailableIcon />
					</span>
					<span>Available</span>
				</p>
				<p onClick={()=>{onClick(2)}}>
					<span>
						<AwayIcon />
					</span>
					<span>Away</span>
				</p>
				<p onClick={()=>{onClick(3)}}>
					<span>
						<DNDIcon />
					</span>
					<span>Do not Disturb</span>
				</p>
				<p onClick={()=>{onClick(4)}}>
					<span>
						<DNDThisDeviceIcon />
					</span>
					<span>
						Do not Disturb
						 <b>(This device only)</b> 
					</span>
				</p>
			</div>
		</div>
	);
};

export default ChooseStatus;
