import Backdrop from "components/UI/Backdrop";
import CloseIcon from "components/UI/Icons/Close";
import React, { useState } from "react";
import styles from "./KeypressDialogue.module.scss";
import KeypressIcon from "components/UI/Icons/Sidecar/Keypress";
import VoicemailIcon from "components/UI/Icons/Sidebar/Voicemail";
import { useDispatch } from "react-redux";
import { addSidecarConfigNewTempActionList, setShowChooseActions, setShowKeypressDialogue } from "redux/sidecar/sidecarSlice";

const KeypressDialogue = ({uuid = "", numberValue=""}) => {
	const dispatch = useDispatch()
	const [number, setNumber] = useState(numberValue);
	const keyArray = [
		{ number: "1", letter: <VoicemailIcon /> },
		{ number: "2", letter: "ABC" },
		{ number: "3", letter: "DEF" },
		{ number: "4", letter: "GHI" },
		{ number: "5", letter: "JKL" },
		{ number: "6", letter: "MNO" },
		{ number: "7", letter: "PQRS" },
		{ number: "8", letter: "TUV" },
		{ number: "9", letter: "WXYZ" },
		{ number: "*", letter: "" },
		{ number: "0", letter: "+" },
		{ number: "#", letter: "" },
	];
	const back = ()=>{
		dispatch(setShowKeypressDialogue({uuid:"", show:false}));
	}
	return (
		<>
			<Backdrop  onCancel={()=>{dispatch(setShowKeypressDialogue({uuid:"", show:false}));if(!uuid)dispatch(setShowChooseActions(true))}}/>
			<div className={styles.dialogueBox}>
				<h1 className={styles.topHeading}>
					<span>
						<KeypressIcon />
						<span>Key Press</span>
					</span>

					<span className={styles.closeBox} onClick={()=>{dispatch(setShowKeypressDialogue({uuid:"", show:false}));if(!uuid)dispatch(setShowChooseActions(true))}}>
						<CloseIcon />
					</span>
				</h1>

				<div className={styles.keypad}>
					{keyArray?.map((item) => (
						<span className={ `${styles.key} ${item.number === number ? styles.selected :{}}` } onClick={()=>{if(item.number === number){setNumber("")}else{setNumber(item.number)}}}>
							<span className={styles.number}>{item.number}</span>
							<span className={styles.letter}>{item.letter}</span>
						</span>
					))}
				</div>

				<div className={styles.btnBox}>
					<button 
						disabled={number === "" ? true:false}
						className={`${number === "" ? styles.disabled:{}}`}
						onClick={()=>{dispatch(addSidecarConfigNewTempActionList({name:"KeyPress", uuid:uuid, number:number===""?"0":number })); back()} }>{uuid ? "Update":"Add Action"}</button>
				</div>
			</div>
		</>
	);
};

export default KeypressDialogue;
