import styles from "./GeneralContactDialog.module.scss";
import Backdrop from "components/UI/Backdrop";
import CloseIcon from "components/UI/Icons/Close";
import { useDispatch } from "react-redux";
import ContactCard from "components/Contact/ContactCard";
import SearchBar from "components/UI/SearchBar";
import { useState } from "react";

const GeneralContactDialog = ({setNumber, setShowSelectContact}:{setShowSelectContact:React.Dispatch<React.SetStateAction<boolean>>, setNumber:React.Dispatch<React.SetStateAction<string>>}) => {
	const contact = localStorage.getItem("contacts");
	const contactList = contact? JSON.parse(contact):[];
	const [searchValue, setSearchValue] = useState("")
	const filterWithPhoneNumber = (item: { phone?: string; })=>{
		return item.phone
	}
	const search = (item: { name: any; phone: string; first_name: string; last_name: string; })=>{
		if(
			(item?.phone && item?.phone.toLowerCase().includes(searchValue.toLowerCase()) || 
			(item?.first_name && item?.first_name.toLowerCase().includes(searchValue.toLowerCase())) ||
			(item?.last_name && item?.last_name.toLowerCase().includes(searchValue.toLowerCase()))
			)){
			return true
		}else{
			return false;
		}
	}
	return (
		<>
			<Backdrop onCancel={ ()=>{}}/>
			<div className={styles.dialogueBox}>
				<h1 className={styles.topHeading}>
					<span>
						<span>Select Contact</span>
					</span>

					<span className={styles.closeBox} onClick={()=>{setShowSelectContact(false);}}>
						<CloseIcon />
					</span>
				</h1>
				<div className={styles.searchBox}>
					<SearchBar placeholder="Search contact..." onChange={(e)=>{setSearchValue(e.target.value)}} />
				</div>
				<div className={styles.contactListBox}>
					{contactList?.filter(filterWithPhoneNumber)?.filter(search)?.map((contact: any, index:number) => {
						return (
							<ContactCard
									id={contact.id}
									first_name={contact.first_name}
									last_name={contact.last_name}
									phone={contact.phone}
									clicked={() => {
										setNumber(contact.phone)
										setShowSelectContact(false);
									}}
									key={index}
								/>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default GeneralContactDialog;
