import React from "react";

const StatusIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
			<g clipPath="url(#clip0_2744_10732)">
				<path
					d="M5.99996 10.4999C5.99996 10.4999 7.12496 11.9999 8.99996 11.9999C10.875 11.9999 12 10.4999 12 10.4999M6.74996 6.74995H6.75683M11.25 6.74995H11.2568M9.71242 16.4623H8.96242C7.44868 16.4547 5.97266 15.9893 4.72841 15.1271C3.48416 14.265 2.52992 13.0465 1.99114 11.6318C1.45236 10.2172 1.35425 8.67264 1.70972 7.20121C2.06519 5.72978 2.8576 4.40035 3.98277 3.3877C5.10794 2.37505 6.5132 1.72658 8.01381 1.52753C9.51443 1.32848 11.0402 1.58818 12.3904 2.27248C13.7407 2.95679 14.8523 4.03366 15.579 5.36154C16.3058 6.68942 16.6138 8.20614 16.4624 9.71231M14.2124 16.4623C12.9698 16.4623 11.9624 15.455 11.9624 14.2123C11.9624 12.9697 12.9698 11.9623 14.2124 11.9623C15.4551 11.9623 16.4624 12.9697 16.4624 14.2123C16.4624 15.455 15.4551 16.4623 14.2124 16.4623Z"
					stroke="#6C7A8B"
					strokeWidth="1.5"
					strokeLinecap="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2744_10732">
					<rect width="18" height="18" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default StatusIcon;
