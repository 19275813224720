import React from "react";
import { RouteObject } from "react-router-dom";
import routePaths from "./../../constants/routes";
import ProtectedRoutes from "utils/ProtectedRoutes";
import Loader from "components/UI/Loader";

const Conference = React.lazy(() => import("./../../pages/Conference"));

export const conferenceRoutes: RouteObject[] = [
	routePaths.CONFERENCE.__PATH,
	routePaths.CONFERENCE.GROUPS.ROUTE,
	routePaths.CONFERENCE.CALL_HISTORY.ROUTE,
].map((route) => ({
	path: route,
	element: (
		<ProtectedRoutes>
			<React.Suspense fallback={<Loader />}>
				<Conference />
			</React.Suspense>
		</ProtectedRoutes>
	),
}));
