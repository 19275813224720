// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OnOffSwitch_switch__la1vO {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.OnOffSwitch_switch__la1vO input {
  opacity: 0;
  width: 0;
  height: 0;
}

.OnOffSwitch_slider__CsU8C {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--background-emphasis);
  transition: 0.4s;
}

.OnOffSwitch_slider__CsU8C:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: var(--background-secondary);
  transition: 0.4s;
}

input:checked + .OnOffSwitch_slider__CsU8C {
  border-radius: 22px;
  background: var(--primary-default, #0c6dc7);
}

input:focus + .OnOffSwitch_slider__CsU8C {
  box-shadow: 0 0 1px var(--primary-default, #0c6dc7);
}

input:checked + .OnOffSwitch_slider__CsU8C:before {
  transform: translateX(16px);
}

/* Rounded sliders */
.OnOffSwitch_slider__CsU8C.OnOffSwitch_round__ZnzeO {
  border-radius: 34px;
}

.OnOffSwitch_slider__CsU8C.OnOffSwitch_round__ZnzeO:before {
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/OnOffSwitch/OnOffSwitch.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,YAAA;AACD;;AAEA;EACC,UAAA;EACA,QAAA;EACA,SAAA;AACD;;AAEA;EACC,kBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,sCAAA;EAEA,gBAAA;AACD;;AAEA;EACC,kBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;EAEA,6CAAA;EAEA,gBAAA;AAAD;;AAGA;EACC,mBAAA;EACA,2CAAA;AAAD;;AAGA;EACC,mDAAA;AAAD;;AAGA;EAGC,2BAAA;AAAD;;AAGA,oBAAA;AACA;EACC,mBAAA;AAAD;;AAGA;EACC,kBAAA;AAAD","sourcesContent":[".switch {\n\tposition: relative;\n\tdisplay: inline-block;\n\twidth: 40px;\n\theight: 24px;\n}\n\n.switch input {\n\topacity: 0;\n\twidth: 0;\n\theight: 0;\n}\n\n.slider {\n\tposition: absolute;\n\tcursor: pointer;\n\ttop: 0;\n\tleft: 0;\n\tright: 0;\n\tbottom: 0;\n\tbackground: var(--background-emphasis);\n\t-webkit-transition: 0.4s;\n\ttransition: 0.4s;\n}\n\n.slider:before {\n\tposition: absolute;\n\tcontent: \"\";\n\theight: 20px;\n\twidth: 20px;\n\tleft: 2px;\n\tbottom: 2px;\n\n\tbackground-color: var(--background-secondary);\n\t-webkit-transition: 0.4s;\n\ttransition: 0.4s;\n}\n\ninput:checked + .slider {\n\tborder-radius: 22px;\n\tbackground: var(--primary-default, #0c6dc7);\n}\n\ninput:focus + .slider {\n\tbox-shadow: 0 0 1px var(--primary-default, #0c6dc7);\n}\n\ninput:checked + .slider:before {\n\t-webkit-transform: translateX(16px);\n\t-ms-transform: translateX(16px);\n\ttransform: translateX(16px);\n}\n\n/* Rounded sliders */\n.slider.round {\n\tborder-radius: 34px;\n}\n\n.slider.round:before {\n\tborder-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": `OnOffSwitch_switch__la1vO`,
	"slider": `OnOffSwitch_slider__CsU8C`,
	"round": `OnOffSwitch_round__ZnzeO`
};
export default ___CSS_LOADER_EXPORT___;
