import React from "react";
import { IContactCard } from "./../../../constants/interfaces";
import { contactAbbreviation } from "../../../utils";
import styles from "./ContactCard.module.scss";

const ContactCard: React.FC<IContactCard> = ({ id, first_name, last_name, phone, email, fax, clicked }: any) => {
	return (
		<button className={styles.contact} onClick={clicked}>
			<span className={styles.contact_circle}>{contactAbbreviation(first_name, last_name, phone, email)}</span>
			<div className={styles.contact_name}>
				<span style={{textAlign: "left"}}>
					{first_name || last_name ? `${first_name} ${last_name}` : phone || email || fax}
				</span>
				{/* <SuitcaseIcon /> */}
			</div>
		</button>
	);
};

export default ContactCard;
