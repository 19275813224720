import { RootState } from "./../../redux/store";

export const showSidecarSidebar = (state: RootState) => state.sidecar.showSidecarSidebar;
export const sidecarConfigNewTemp = (state: RootState) => state.sidecar.sidecarConfigNewTemp;
export const showButtonProgramming = (state: RootState) => state.sidecar.showButtonProgramming;
export const showChooseActions = (state: RootState) => state.sidecar.showChooseActions;
export const tabSelected = (state: RootState) => state.sidecar.tabSelected;
export const showPauseDialogue = (state: RootState) => state.sidecar.showPauseDialogue;
export const showDialDialogue = (state: RootState) => state.sidecar.showDialDialogue;
export const showConferenceDialogue = (state: RootState) => state.sidecar.showConferenceDialogue;
export const showMainStatusDialogue = (state: RootState) => state.sidecar.showMainStatusDialogue;
export const showAdditionalStatusDialogue = (state: RootState) => state.sidecar.showAdditionalStatusDialogue;
export const showSendMessageDialogue = (state: RootState) => state.sidecar.showSendMessageDialogue;
export const showKeypressDialogue = (state: RootState) => state.sidecar.showKeypressDialogue;
export const showTransferDialogue = (state: RootState) => state.sidecar.showTransferDialogue;
export const sidecarConfig = (state: RootState) => state.sidecar.sidecarConfig;
export const sendMessage = (state: RootState) => state.sidecar.sendMessage;
export const uploadToServer = (state: RootState) => state.sidecar.uploadToServer;
export const updateStatus = (state: RootState) => state.sidecar.updateStatus;
export const lastSync = (state: RootState) => state.sidecar.lastSync;
export const fullScreen = (state: RootState) => state.sidecar.fullScreen;
export const sidecarView = (state: RootState) => state.sidecar.sidecarView;