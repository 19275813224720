import React from "react";

const KeypressIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
			<path
				d="M13.875 2.625L4.125 2.625M13.875 2.625C15.4637 2.625 16.2447 5.04794 16.4462 5.78793C16.4845 5.92873 16.5 6.07362 16.5 6.21954V13.5C16.5 14.3284 15.8284 15 15 15H3C2.17157 15 1.5 14.3284 1.5 13.5V6.21954C1.5 6.07362 1.51547 5.92873 1.5538 5.78793C1.75529 5.04794 2.53632 2.625 4.125 2.625M13.875 2.625C14.7034 2.625 15.375 3.29657 15.375 4.125V10.5C15.375 11.3284 14.7034 12 13.875 12H4.125C3.29657 12 2.625 11.3284 2.625 10.5V4.125C2.625 3.29657 3.29657 2.625 4.125 2.625M9.75 4.875L12 7.125M12 7.125L9.75 9.375M12 7.125H6"
				stroke="#6C7A8B"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default KeypressIcon;
