// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Select_optionBox__o5h87 {
  width: 100%;
  position: relative;
  height: 32px;
}
.Select_optionBox__o5h87 select {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid var(--border-secondary, #c8d3e0);
  background: var(--background-tertiary, #f7f9fc);
  color: var(--text-primary, #1f2023);
  padding: 4px 12px;
  transition: color 0.3s, border 0.3s;
}
.Select_optionBox__o5h87 select:hover {
  border: 1px solid var(--border-hover, #1480e1);
}
.Select_optionBox__o5h87 span {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  display: flex;
}
.Select_optionBox__o5h87 span svg {
  width: 16px;
  height: 16px;
}

.Select_iconTrue__83CdV select {
  padding-left: 25px;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Forms/Select/Select.module.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,kBAAA;EACA,YAAA;AACD;AACC;EACC,WAAA;EACA,YAAA;EAEA,kBAAA;EACA,kDAAA;EACA,+CAAA;EAEA,mCAAA;EAEA,iBAAA;EAEA,mCACC;AAJH;AAOE;EACC,8CAAA;AALH;AASC;EACC,kBAAA;EACA,QAAA;EACA,SAAA;EACA,2BAAA;EACA,aAAA;AAPF;AASE;EACC,WAAA;EACA,YAAA;AAPH;;AAaC;EACC,kBAAA;AAVF","sourcesContent":[".optionBox {\n\twidth: 100%;\n\tposition: relative;\n\theight: 32px;\n\n\t& select {\n\t\twidth: 100%;\n\t\theight: 100%;\n\n\t\tborder-radius: 8px;\n\t\tborder: 1px solid var(--border-secondary, #c8d3e0);\n\t\tbackground: var(--background-tertiary, #f7f9fc);\n\n\t\tcolor: var(--text-primary, #1f2023);\n\n\t\tpadding: 4px 12px;\n\n\t\ttransition:\n\t\t\tcolor 0.3s,\n\t\t\tborder 0.3s;\n\n\t\t&:hover {\n\t\t\tborder: 1px solid var(--border-hover, #1480e1);\n\t\t}\n\t}\n\n\t& span {\n\t\tposition: absolute;\n\t\ttop: 50%;\n\t\tleft: 8px;\n\t\ttransform: translateY(-50%);\n\t\tdisplay: flex;\n\n\t\tsvg {\n\t\t\twidth: 16px;\n\t\t\theight: 16px;\n\t\t}\n\t}\n}\n\n.iconTrue {\n\t& select {\n\t\tpadding-left: 25px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionBox": `Select_optionBox__o5h87`,
	"iconTrue": `Select_iconTrue__83CdV`
};
export default ___CSS_LOADER_EXPORT___;
