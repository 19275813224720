// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OpenApp_openApp__NNO-W {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--background-secondary);
  padding: 10px;
  z-index: 9999;
}
.OpenApp_openApp__NNO-W .OpenApp_mainBtn__BXz2w {
  padding: 6px 13px;
  background: var(--text-secondary);
  color: black;
  border-radius: 5px;
  font-size: 0.8em;
  position: relative;
  z-index: 999;
}
.OpenApp_openApp__NNO-W button {
  margin-left: 15px;
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/OpenApp/OpenApp.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,uCAAA;EACA,aAAA;EACA,aAAA;AACD;AACC;EACC,iBAAA;EACA,iCAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,YAAA;AACF;AAEC;EACC,iBAAA;EACA,aAAA;EACA,mBAAA;AAAF","sourcesContent":[".openApp {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: flex-end;\n\ttop: 0;\n\tleft: 0;\n\twidth: 100%;\n\tbackground: var(--background-secondary);\n\tpadding: 10px;\n\tz-index: 9999;\n\n\t.mainBtn {\n\t\tpadding: 6px 13px;\n\t\tbackground: var(--text-secondary);\n\t\tcolor: black;\n\t\tborder-radius: 5px;\n\t\tfont-size: 0.8em;\n\t\tposition: relative;\n\t\tz-index: 999;\n\t}\n\n\tbutton {\n\t\tmargin-left: 15px;\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"openApp": `OpenApp_openApp__NNO-W`,
	"mainBtn": `OpenApp_mainBtn__BXz2w`
};
export default ___CSS_LOADER_EXPORT___;
