// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MiniActiveCallControl_container__JrEJ7 {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--border-tertiary, #e3eaf2);
  padding-inline: 11px;
  background: var(--background-tertiary, #f7f9fc);
  color: var(--text-primary, #1f2023);
  cursor: default;
}
.MiniActiveCallControl_container__JrEJ7 .MiniActiveCallControl_callDetail__RckR1 {
  flex-direction: column;
  align-items: start;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}
.MiniActiveCallControl_container__JrEJ7 .MiniActiveCallControl_timer__q2efo {
  min-width: 70px;
  border-radius: 22px;
  border: 1px solid var(--border-tertiary);
  background: var(--background-primary);
  padding: 4px 10px;
  margin: auto;
  justify-content: center;
}
.MiniActiveCallControl_container__JrEJ7 .MiniActiveCallControl_hangup__\\+Ev6E {
  cursor: pointer;
}
.MiniActiveCallControl_container__JrEJ7 .MiniActiveCallControl_hangup__\\+Ev6E svg {
  height: 30px;
  width: 30px;
}
.MiniActiveCallControl_container__JrEJ7 .MiniActiveCallControl_transfer__G84qn .MiniActiveCallControl_icon__Hbat1 {
  margin: auto;
  display: flex;
  cursor: pointer;
}
.MiniActiveCallControl_container__JrEJ7 .MiniActiveCallControl_transfer__G84qn .MiniActiveCallControl_icon__Hbat1 svg {
  height: 23px;
  width: 30px;
}
.MiniActiveCallControl_container__JrEJ7 .MiniActiveCallControl_transfer__G84qn .MiniActiveCallControl_transferCard__bYXS8 {
  position: relative;
  top: 146px;
  right: 205px;
}
.MiniActiveCallControl_container__JrEJ7 .MiniActiveCallControl_conf__LJ7Q1 {
  cursor: pointer;
}
.MiniActiveCallControl_container__JrEJ7 .MiniActiveCallControl_conf__LJ7Q1 svg {
  height: 28px;
  width: 28px;
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/MiniActiveCallControl/MiniActiveCallControl.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;EACA,iDAAA;EACA,oBAAA;EACA,+CAAA;EACA,mCAAA;EACA,eAAA;AACJ;AAAI;EACI,sBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AAER;AAAI;EACI,eAAA;EACA,mBAAA;EACA,wCAAA;EACA,qCAAA;EACA,iBAAA;EACA,YAAA;EACA,uBAAA;AAER;AAAI;EACI,eAAA;AAER;AADQ;EACI,YAAA;EACA,WAAA;AAGZ;AACQ;EACI,YAAA;EACA,aAAA;EACA,eAAA;AACZ;AAAY;EACI,YAAA;EACA,WAAA;AAEhB;AACQ;EACI,kBAAA;EACA,UAAA;EACA,YAAA;AACZ;AAEI;EACI,eAAA;AAAR;AACQ;EACI,YAAA;EACA,WAAA;AACZ","sourcesContent":[".container{\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    border-radius: 8px;\n    border: 1px solid var(--border-tertiary, #e3eaf2);\n    padding-inline: 11px;\n    background: var(--background-tertiary, #f7f9fc);\n    color: var(--text-primary, #1f2023);\n    cursor: default;\n    .callDetail{\n        flex-direction: column;\n        align-items: start;\n        font-size: 13px;\n        font-style: normal;\n        font-weight: 500;\n    }\n    .timer{\n        min-width:70px;\n        border-radius: 22px;\n        border: 1px solid var(--border-tertiary);\n        background: var(--background-primary);\n        padding: 4px 10px;\n        margin: auto;\n        justify-content: center;\n    }\n    .hangup{\n        cursor: pointer;\n        svg{\n            height: 30px;\n            width: 30px;\n        }\n    }\n    .transfer{\n        .icon{\n            margin: auto;\n            display: flex;\n            cursor: pointer;\n            svg{\n                height: 23px;\n                width: 30px;\n            }\n        }\n        .transferCard{\n            position: relative;\n            top: 146px;\n            right: 205px;\n        }\n    }\n    .conf{\n        cursor: pointer;\n        svg{\n            height: 28px;\n            width: 28px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MiniActiveCallControl_container__JrEJ7`,
	"callDetail": `MiniActiveCallControl_callDetail__RckR1`,
	"timer": `MiniActiveCallControl_timer__q2efo`,
	"hangup": `MiniActiveCallControl_hangup__+Ev6E`,
	"transfer": `MiniActiveCallControl_transfer__G84qn`,
	"icon": `MiniActiveCallControl_icon__Hbat1`,
	"transferCard": `MiniActiveCallControl_transferCard__bYXS8`,
	"conf": `MiniActiveCallControl_conf__LJ7Q1`
};
export default ___CSS_LOADER_EXPORT___;
