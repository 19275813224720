import React from "react";

const OnCallIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path
				d="M1.46881 15.5088L0.330596 14.3708C0.128361 14.1675 0.000479481 13.5723 0.000479482 13.5545C-0.0299059 9.95172 1.38497 6.48316 3.93491 3.9341C6.49115 1.37777 9.97389 -0.0385044 13.5876 0.000796634C13.5876 0.000796634 14.1683 0.131274 14.3695 0.334049L15.5088 1.4721C15.9259 1.88969 16.1146 2.68925 15.928 3.24935L15.8473 3.48932C15.6607 4.04942 15.0424 4.63468 14.4733 4.78977L12.3698 5.36298C11.7996 5.51861 10.9884 5.30953 10.5713 4.89248L9.81039 4.13168C7.04453 4.87779 4.87609 7.04645 4.12883 9.81028L4.88974 10.5711C5.30689 10.9882 5.51539 11.8013 5.36028 12.3714L4.78594 14.4746C4.63187 15.0426 4.04709 15.6608 3.48634 15.8484L3.24637 15.928C2.68561 16.1145 1.88596 15.9259 1.46881 15.5088Z"
				fill="#F5C400"
			/>
		</svg>
	);
};

export default OnCallIcon;
