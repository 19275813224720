import React, { useEffect, useState } from "react";
import styles from "./Queues.module.scss";
import QueuesCard from "../QueuesCard";
import { useGetAllQueuesQuery, useGetMyQueuesQuery } from "services/queues";
import { useGetAllExtensionsQuery, useGetExtensionsQuery } from "services/extension";
import { ClipLoader } from "react-spinners";
import ExtensionSkeleton from "../ExtensionSkeleton";
import ExtensionCard from "../ExtensionCard";
import { useGetStatusAllQuery } from "services/status";
import AddQueueMember from "../AddQueueMember";
import RemoveQueueMember from "../RemoveQueueMember";
import Logout from "components/UI/Icons/Sidecar/Logout";
import Login from "components/UI/Icons/Sidecar/Login";
import BtnMedium from "components/UI/BtnMedium";
import { useSelector } from "react-redux";

const Queues = ({ showList = true }: { showList?: boolean }) => {
	const [tabSelected, setTabSelected] = useState("all_queues");
	const [extensionList, setExtensionList] = useState<any[]>([]);
	const [searchText, setSearchText] = useState("");
	const [typeFilter, setTypeFilter] = useState("All Extensions");
	const [sortState, setSortState] = useState("ext.number");
	const [activeList, setActiveList] = useState(-1);
	const {
		data: allQueues,
		isLoading: loading,
		isFetching: fetching,
		refetch: allQueuesRefresh,
	} = useGetAllQueuesQuery("1");
	const {
		data: allExtensions,
		isLoading: allExtensionLoading,
		isFetching: allExtensionIsFetching,
	} = useGetAllExtensionsQuery(localStorage.getItem("instance_id"));
	const {
		data: getStatusAll,
		isLoading: getStatusAllLoading,
		isFetching: getStatusAllFetching,
	} = useGetStatusAllQuery(null);
	const { data: extListData } = useGetExtensionsQuery(localStorage.getItem("instance_id"));
	const { extNumber } = useSelector((state: any) => state.sip);

	useEffect(() => {
		setExtensionList(allExtensions);
	}, [allExtensions]);
	const searchExtension = (item: { data: { extension?: string; name?: string } }) => {
		if (item?.data?.extension || item?.data?.name) {
			return (
				String(item?.data?.extension)?.toLowerCase().includes(searchText.toLowerCase()) ||
				String(item?.data?.name)?.toLowerCase().includes(searchText.toLowerCase())
			);
		} else {
			return false;
		}
	};
	const filterByType = (item: { presence_status: string }) => {
		if (typeFilter === "Active") {
			if (!["ready", "on_the_phone", "ring", "call"].includes(item?.presence_status)) {
				return false;
			}
		}
		return true;
	};
	const sortStatus = (e1: { data: { name: string; extension: any } }, e2: { data: { name: any; extension: any } }) => {
		if (sortState === "ext.name") {
			return e1?.data?.name.localeCompare(e2?.data?.name);
		}
		return Number(e1?.data?.extension) - Number(e2?.data?.extension);
	};
	const filterQueuesWithAllUserExtNum = (userExtArr: string | any[], queues: string | any[]) => {
		const matchingElements = [];

		for (let i = 0; i < queues?.length; i++) {
			const q = queues[i];
			for (let j = 0; j < userExtArr?.length; j++) {
				const e = userExtArr[j];
				if (q?.number === e?.data?.extension) {
					matchingElements.push(q);
				}
			}
		}
		return matchingElements;
	};

	const moveElementToFirst = (arr: any[], value: any) => {
		const valueElement = arr?.filter(
			(item: { data: { extension: any } }) => String(item?.data?.extension) === String(value),
		);
		if (valueElement?.length) {
			const remainingElements = arr.filter((item: any) => item !== valueElement[0]);
			remainingElements.unshift(valueElement[0]);
			return remainingElements;
		}
		return arr;
	};
	const lists = moveElementToFirst(
		extensionList?.filter(searchExtension)?.filter(filterByType).sort(sortStatus),
		extNumber ? extNumber : "",
	); //update with wss
	const myQueues = filterQueuesWithAllUserExtNum(extListData, allQueues);
	const [addQueue, setAddQueue] = useState<undefined | { queueName?: string; queueID: string; extensionList: any[] }>(
		undefined,
	);
	const [removeQueue, setRemoveQueue] = useState<
		undefined | { queueName?: string; queueID: string; extensionList: any[] }
	>(undefined);
	// console.log(allQueues, "all quesues");
	return (
		<div className={styles.queues}>
			{addQueue ? (
				<AddQueueMember
					allQueuesRefresh={allQueuesRefresh}
					allExtensionLists={lists}
					queueName={addQueue?.queueName}
					close={() => {
						setAddQueue(undefined);
					}}
					extensionList={addQueue.extensionList}
					queueID={addQueue.queueID}
					key={addQueue?.queueID}
				/>
			) : null}
			{removeQueue ? (
				<RemoveQueueMember
					allQueuesRefresh={allQueuesRefresh}
					allExtensionLists={lists}
					queueName={removeQueue?.queueName}
					close={() => {
						setRemoveQueue(undefined);
					}}
					extensionList={removeQueue.extensionList}
					queueID={removeQueue.queueID}
					key={removeQueue?.queueID}
				/>
			) : null}
			{loading || fetching ? (
				<div className={styles.loader}>
					{Array(6)
						.fill(null)
						.map((item, index) => (
							<ExtensionSkeleton key={index} />
						))}
				</div>
			) : (
				<>
					{allQueues?.map(
						(
							item: {
								number: string;
								members: any[];
								_id?: string;
								name:
									| string
									| number
									| boolean
									| React.ReactElement<any, string | React.JSXElementConstructor<any>>
									| Iterable<React.ReactNode>
									| React.ReactPortal
									| null
									| undefined;
							},
							index: React.SetStateAction<number>,
						) => {
							const filterQueueMember = (listItem: { data: { extension: any } }) => {
								if (listItem?.data?.extension) {
									for (let x = 0; x < item.members?.length; x++) {
										// console.log(String(listItem?.data?.extension)?.toLowerCase(), "===", String(item?.members[x].id)?.toLowerCase())
										if (String(listItem?.data?.extension)?.toLowerCase() === String(item?.members[x].id)?.toLowerCase())
											return item?.members[x];
									}
								}
								return undefined;
							};
							return (
								<div className={styles.listBlock}>
									<div
										className={styles.queueConfigList}
										onClick={() => {
											setActiveList(index === activeList ? -1 : index);
										}}>
										<span className={styles.queueMembers}>
											{item.name} {item.number ? `(${item.number})` : ""} -
											<span className={styles.memberCount}>
												{item?.members?.length > 1 ? " Members" : " Member"}{" "}
												{item?.members?.length ? item?.members?.length : "0"}{" "}
											</span>
										</span>
										<span className={styles.addQueue}>
											<BtnMedium
												btnType={"secondary"}
												isDisabled={false}
												type="button"
												isDanger={false}
												btnText="Login"
												onClick={() => {
													setAddQueue({
														queueName: String(item.name),
														queueID: item?._id ? item?._id : "",
														extensionList: item?.members?.length > 0 ? item?.members : [],
													});
												}}
												icon={<Login />}
											/>
											<BtnMedium
												btnType={"secondary"}
												isDisabled={false}
												type="button"
												isDanger={false}
												btnText="Logout"
												onClick={() => {
													setRemoveQueue({
														queueName: String(item.name),
														queueID: item?._id ? item?._id : "",
														extensionList: item?.members?.length > 0 ? item?.members : [],
													});
												}}
												icon={<Logout />}
											/>
										</span>
									</div>
									{(index === activeList || showList === false) &&
										(allExtensionLoading || allExtensionIsFetching || getStatusAllFetching || getStatusAllLoading ? (
											<div className={`${styles.loader} ${showList ? "" : styles.grid}`}>
												{Array(item?.members?.length ? item?.members?.length : 1)
													.fill(null)
													.map((item, index) => (
														<ExtensionSkeleton key={index} />
													))}
											</div>
										) : (
											<div className={showList ? "" : styles.grid}>
												{lists?.map((listItem, index) => {
													const queueMember = filterQueueMember(listItem);
													return queueMember ? (
														<ExtensionCard
															queueID={item?._id}
															queue={
																queueMember
																	? { queueMember: queueMember, allQueuesRefresh: allQueuesRefresh }
																	: undefined
															}
															status={getStatusAll}
															extensionData={listItem}
															key={listItem?._id ? listItem?._id : index}
														/>
													) : null;
												})}
											</div>
										))}
								</div>
							);
						},
					)}
				</>
			)}
		</div>
	);
};

export default Queues;
