import React from "react";

const TransferIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
			<path
				d="M12.75 8.25L15.75 5.25M15.75 5.25L12.75 2.25M15.75 5.25H6.75M5.25 15.75L2.25 12.75M2.25 12.75L5.25 9.75M2.25 12.75H11.25"
				stroke="#6C7A8B"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default TransferIcon;
