import { useState } from "react";
import styles from "./AdditionalStatus.module.scss";
import DeleteIcon from "components/UI/Icons/Delete";
import StatusIcon from "components/UI/Icons/Sidecar/Status";
import ArrowRight from "components/UI/Icons/ArrowRight";
import ReloadIcon from "components/UI/Icons/ReloadIcon";
import OnCallIcon from "components/UI/Icons/Status/OnCall";
import CalenderIcon from "components/UI/Icons/Status/Calender";
import AFKIcon from "components/UI/Icons/Status/AFK";
import EditIcon from "components/UI/Icons/Lines/Edit";
import { useDispatch } from "react-redux";
import { removeSidecarConfigNewTempActionList, setShowAdditionalStatusDialogue } from "redux/sidecar/sidecarSlice";
import OnLunchIcon from "components/UI/Icons/Status/OnLunch";
import HolidayIcon from "components/UI/Icons/Status/Holiday";
import MoveUpDown from "components/UI/Icons/Sidecar/MoveUpDown";

const AdditionalStatusActionCard = ({fromStatuses, toStatus, toggle, uuid, drag, changesMade}:{fromStatuses:Array<string>, toStatus:Array<string>, toggle: string, uuid:string, drag?:any, changesMade:()=>void}) => {
	const [editHover, setEditHover] = useState(false);
	const [deleteHover, setDeleteHover] = useState(false);
	const dispatch = useDispatch();
	return (
		<div className={styles.card} draggable onDragEnd={drag?.handlesort}  onDragEnter={()=>{drag?.dragEnter(drag)}} onDragStart={()=>{drag?.dragStart(drag)}}> 
			<p>
				{drag && <MoveUpDown />}
				<StatusIcon />
				<span>Additional Status</span>
			</p>
			<p>
				<span className={styles.extension}>
					<span className={styles.status}>
						{fromStatuses.indexOf("on_a_call") !== -1 && <span><OnCallIcon /></span>}
						{fromStatuses.indexOf("in_a_meeting") !== -1 && <span><CalenderIcon /></span>}
						{fromStatuses.indexOf("lunch") !== -1 && <span><OnLunchIcon /></span>}
						{fromStatuses.indexOf("holiday") !== -1 && <span><HolidayIcon /></span>}
						{fromStatuses.indexOf("afk") !== -1 && <span><AFKIcon /></span>}
					</span>
					<span>
						{toggle === "1"? < ReloadIcon />:<ArrowRight />}
					</span>
					<span className={styles.status}>
						{toStatus.indexOf("on_a_call") !== -1 && <span><OnCallIcon /></span>}
						{toStatus.indexOf("in_a_meeting") !== -1 && <span><CalenderIcon /></span>}
						{toStatus.indexOf("lunch") !== -1 && <span><OnLunchIcon /></span>}
						{toStatus.indexOf("holiday") !== -1 && <span><HolidayIcon /></span>}
						{toStatus.indexOf("afk") !== -1 && <span><AFKIcon /></span>}
					</span>
				</span>
				{uuid &&
				<>
					<span className={styles.actionButton} onClick={()=>{changesMade(); dispatch(setShowAdditionalStatusDialogue({uuid:`${uuid}`, show:true, toggleMode:`${toggle}`, fromValue:fromStatuses, toValue:toStatus }))}} onMouseOver={() => {setEditHover(true);}} onMouseOut={() => {setEditHover(false);}}><EditIcon color={editHover?"primary-default" : "icon-primary"}/></span>
					<span className={styles.actionButton} onClick={()=>{changesMade(); dispatch(removeSidecarConfigNewTempActionList(uuid))}} onMouseOver={() => {setDeleteHover(true);}} onMouseOut={() => {setDeleteHover(false);}}><DeleteIcon color={deleteHover?"support-danger-default" : "icon-primary"} /></span>
				</>}
				
			</p>
		</div>
	);
};

export default AdditionalStatusActionCard;
