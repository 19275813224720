import React from "react";
import styles from "./SearchBar.module.scss";
import { IInput } from "constants/interfaces";
import SearchIcon from "../Icons/Lines/Search";

const SearchBar: React.FC<IInput> = ({ type = "text", placeholder, required, onChange, value, disabled }) => {
	return (
		<div className={styles.search}>
			<input
				type={type}
				placeholder={placeholder}
				onChange={onChange}
				required={required}
				value={value}
				disabled={disabled}
				maxLength={50}
			/>
			<SearchIcon />
		</div>
	);
};

export default SearchBar;
