import React from "react";

const ContactBookIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
			<path
				d="M15 16.5H4.5C3.90326 16.5 3.33097 16.2629 2.90901 15.841C2.48705 15.419 2.25 14.8467 2.25 14.25V3.75C2.25 3.15326 2.48705 2.58097 2.90901 2.15901C3.33097 1.73705 3.90326 1.5 4.5 1.5H15C15.1989 1.5 15.3897 1.57902 15.5303 1.71967C15.671 1.86032 15.75 2.05109 15.75 2.25V15.75C15.75 15.9489 15.671 16.1397 15.5303 16.2803C15.3897 16.421 15.1989 16.5 15 16.5ZM14.25 15V13.5H4.5C4.30109 13.5 4.11032 13.579 3.96967 13.7197C3.82902 13.8603 3.75 14.0511 3.75 14.25C3.75 14.4489 3.82902 14.6397 3.96967 14.7803C4.11032 14.921 4.30109 15 4.5 15H14.25ZM9 7.5C9.39782 7.5 9.77936 7.34196 10.0607 7.06066C10.342 6.77936 10.5 6.39782 10.5 6C10.5 5.60218 10.342 5.22064 10.0607 4.93934C9.77936 4.65804 9.39782 4.5 9 4.5C8.60218 4.5 8.22064 4.65804 7.93934 4.93934C7.65804 5.22064 7.5 5.60218 7.5 6C7.5 6.39782 7.65804 6.77936 7.93934 7.06066C8.22064 7.34196 8.60218 7.5 9 7.5ZM6.75 10.5H11.25C11.25 9.90326 11.0129 9.33097 10.591 8.90901C10.169 8.48705 9.59674 8.25 9 8.25C8.40326 8.25 7.83097 8.48705 7.40901 8.90901C6.98705 9.33097 6.75 9.90326 6.75 10.5Z"
				fill="#6C7A8B"
			/>
		</svg>
	);
};

export default ContactBookIcon;
