// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Backdrop_backdrop__f7C5x {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 190;
  flex-shrink: 0;
  background: var(--overlay-primary, rgba(36, 40, 46, 0.1));
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Backdrop/Backdrop.module.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EAEA,cAAA;EAEA,yDAAA;EACA,gBAAA;AADD","sourcesContent":[".backdrop {\n\tposition: fixed;\n\ttop: 0;\n\tleft: 0;\n\twidth: 100%;\n\theight: 100%;\n\tz-index: 190;\n\n\tflex-shrink: 0;\n\n\tbackground: var(--overlay-primary, rgba(36, 40, 46, 0.1));\n\toverflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `Backdrop_backdrop__f7C5x`
};
export default ___CSS_LOADER_EXPORT___;
