import React, { useState } from "react";
import styles from "./Header.module.scss";
import SearchBar from "components/UI/SearchBar";
import ProfileAndExtension from "components/shared/ProfileAndExtension";
import { useDispatch, useSelector } from "react-redux";
import { sidecarConfigNewTemp } from "redux/sidecar/sidecarSelectors";
import Button from "components/UI/Forms/Button";
import { resetSidecarConfigNew, setFullScreen, setShowButtonProgramming } from "redux/sidecar/sidecarSlice";
import PlusIcon from "components/UI/Icons/Plus";
import BtnAction from "components/UI/BtnAction";
import CloseIcon from "components/UI/Icons/Lines/Close";

const Header = ({ setSearch, search }: { search: string; setSearch: React.Dispatch<React.SetStateAction<string>> }) => {
	const dispatch = useDispatch();
	const sidecarConfigNewTempState = useSelector(sidecarConfigNewTemp);
	const [cancelHover, setCancelHover] = useState(false);
	const close = ()=>{
		dispatch(setFullScreen(true))
	}
	return (
		<div className={styles.header}>
			<h1>Sidecar Manage</h1>
			<div className={styles.searchComp}>
				<SearchBar
					value={search}
					onChange={(e) => {
						setSearch(e.target.value);
					}}
					placeholder="Search"
				/>
			</div>
			<div className={styles.profile}>
				{/* <Button
					fill
					styles={{ padding: "5px 11px" }}
					onClick={() => {
						if (sidecarConfigNewTempState.uuid) {
							dispatch(resetSidecarConfigNew());
						}
						dispatch(setShowButtonProgramming(true));
					}}>
					<PlusIcon />
					<span>Add Action</span>
				</Button> */}
				<BtnAction
						btnType={"normal"}
						isDisabled={false}
						type="button"
						isActive={false}
						onMouseOut={() => {
							setCancelHover(false);
						}}
						onMouseOver={() => {
							setCancelHover(true);
						}}
						onClick={close}
						icon={<CloseIcon color={cancelHover ? "primary-default" : "icon-primary"} />}
					/>
			</div>
		</div>
	);
};

export default Header;
