import { IIcon } from "constants/interfaces";

const CallTransferIcon = ({ color }: IIcon) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
			<path
				d="M25.3333 2.66663L30.6666 7.99996L25.3333 13.3333V9.33329H18.6666V6.66663L25.3333 6.66663V2.66663Z"
				fill={`var(--${color ? color : "icon-primary"})`}
			/>
			<path
				d="M29.3332 26.5601V22.5601C29.3494 21.9062 29.1248 21.2691 28.7019 20.7701C28.2791 20.271 27.6876 19.9448 27.0399 19.8534C25.7598 19.6846 24.5029 19.3715 23.2932 18.9201C22.816 18.7407 22.2973 18.7019 21.7987 18.8082C21.3001 18.9146 20.8424 19.1616 20.4799 19.5201L18.7866 21.2134C15.4485 19.3153 12.6846 16.5515 10.7866 13.2134L12.4799 11.5201C12.8384 11.1576 13.0854 10.6999 13.1918 10.2013C13.2981 9.70264 13.2593 9.18399 13.0799 8.70676C12.6285 7.49706 12.3154 6.24018 12.1466 4.96009C12.056 4.31935 11.7355 3.7334 11.2449 3.31147C10.7543 2.88953 10.127 2.66039 9.47989 2.66676H5.47989C5.10968 2.6671 4.74359 2.74453 4.40495 2.89412C4.0663 3.0437 3.76251 3.26216 3.51291 3.53558C3.26332 3.80901 3.07339 4.13141 2.95522 4.48225C2.83706 4.8331 2.79324 5.20471 2.82656 5.57342C3.26421 9.69475 4.66653 13.655 6.91989 17.1334C8.96701 20.355 11.6983 23.0863 14.9199 25.1334C18.3826 27.379 22.3237 28.7809 26.4266 29.2268C26.7964 29.2602 27.1691 29.216 27.5209 29.097C27.8727 28.9781 28.1957 28.7869 28.4693 28.5359C28.743 28.2849 28.9611 27.9795 29.1099 27.6392C29.2587 27.299 29.3347 26.9314 29.3332 26.5601Z"
				fill={`var(--${color ? color : "icon-primary"})`}
			/>
		</svg>
	);
};

export default CallTransferIcon;
