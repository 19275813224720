import SearchBar from "components/UI/SearchBar";
import React, { useEffect, useState } from "react";
import ExtensionCard from "../ExtensionCard";
// import { GET_user_extension_API, extension_API } from "effects/apiEffect";
import { useGetActiveExtensionsQuery, useGetAllExtensionsQuery } from "services/extension";
import OnOffSwitch from "components/UI/OnOffSwitch";
import styles from "./Extension.module.scss";
import Select from "components/UI/Forms/Select";
import { useGetStatusAllQuery } from "services/status";
import ExtensionSkeleton from "../ExtensionSkeleton";
import { useSelector } from "react-redux";

const Extension = ({ showList = true }: { showList?: boolean }) => {
	const [extensionList, setExtensionList] = useState<any[]>([]);
	const [isActiveExtension, setIsActiveExtension] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [typeFilter, setTypeFilter] = useState("Active");
	const [sortState, setSortState] = useState("ext.number");
	const {
		data: allExtensions,
		isLoading: allExtensionLoading,
		isFetching: allExtensionIsFetching,
	} = useGetAllExtensionsQuery(localStorage.getItem("instance_id"));
	const {
		data: activeExtensions,
		isLoading: extensionLoading,
		isFetching: extensionIsFetching,
	} = useGetActiveExtensionsQuery(localStorage.getItem("instance_id"));
	const { data: getStatusAll } = useGetStatusAllQuery(null);
	const { extNumber } = useSelector((state: any) => state.sip);
	const { extensionPresenceChanged } = useSelector((state: any) => state.sidecar);

	useEffect(() => {
		isActiveExtension ? setExtensionList(activeExtensions) : setExtensionList(allExtensions);
	}, [isActiveExtension, allExtensions, activeExtensions]);

	const searchExtension = (item: { data: { extension?: string; name?: string } }) => {
		if (item?.data?.extension || item?.data?.name) {
			return (
				String(item?.data?.extension)?.toLowerCase().includes(searchText.toLowerCase()) ||
				String(item?.data?.name)?.toLowerCase().includes(searchText.toLowerCase())
			);
		} else {
			return false;
		}
	};
	const filterByType = (item: { presence_status: string }) => {
		if (typeFilter === "Active") {
			if (!["ready", "on_the_phone", "ring", "call"].includes(item?.presence_status)) {
				return false;
			}
		}
		return true;
	};
	const sortStatus = (e1: { data: { name: string; extension: any } }, e2: { data: { name: any; extension: any } }) => {
		if (sortState === "ext.name") {
			return e1?.data?.name.localeCompare(e2?.data?.name);
		}
		return Number(e1?.data?.extension) - Number(e2?.data?.extension);
	};
	const activeCallFilter = (item: { [x: string]: any; presence_status: string }) => {
		var ws = false;
		var status = item?.presence_status;
		for (let i = 0; i < extensionPresenceChanged.length; i++) {
			if (extensionPresenceChanged[i]?.extension_id === item?._id) {
				status = extensionPresenceChanged[i]?.status;
				break;
			}
		}
		if (status === "on_the_phone" || status === "ring" || status === "call") {
			return true;
		}
		return false;
	};
	const holdCallFilter = (item: { [x: string]: any; presence_status: string }) => {
		var ws = false;
		var status = item?.presence_status;
		for (let i = 0; i < extensionPresenceChanged.length; i++) {
			if (extensionPresenceChanged[i]?.extension_id === item?._id) {
				status = extensionPresenceChanged[i]?.status;
				break;
			}
		}
		if (status === "hold") {
			return true;
		}
		return false;
	};
	const readyFilter = (item: { presence_status: string }) => {
		var ws = false;
		var status = item?.presence_status;
		for (let i = 0; i < extensionPresenceChanged.length; i++) {
			if (extensionPresenceChanged[i]?.extension_id === item?._id) {
				status = extensionPresenceChanged[i]?.status;
				break;
			}
		}
		if (status === "ready") {
			return true;
		}
		return false;
	};
	const moveElementToFirst = (arr: any[], value: any) => {
		const valueElement = arr?.filter(
			(item: { data: { extension: any } }) => String(item?.data?.extension) === String(value),
		);
		if (valueElement?.length) {
			const remainingElements = arr.filter((item: any) => item !== valueElement[0]);
			remainingElements.unshift(valueElement[0]);
			return remainingElements;
		}
		return arr;
	};
	const lists = moveElementToFirst(
		extensionList?.filter(searchExtension)?.filter(filterByType).sort(sortStatus),
		extNumber ? extNumber : "",
	); //update with wss

	const readyCount = extensionList?.filter(readyFilter)?.length ? extensionList?.filter(readyFilter)?.length : 0;
	const activeCallCount = extensionList?.filter(activeCallFilter)?.length
		? extensionList?.filter(activeCallFilter)?.length
		: 0;
	const holdCallCount = extensionList?.filter(holdCallFilter)?.length
		? extensionList?.filter(holdCallFilter)?.length
		: 0;
	const totalCount = extensionList?.length ? extensionList?.length : 0;
	const filterdCount = lists?.length ? lists?.length : 0;

	return (
		<div className={styles.extension}>
			<div>
				<div className={styles.searchCard}>
					<span className={styles.gripColumn2}>
						<label>Search Extension</label>
						<SearchBar
							placeholder={"search"}
							value={searchText}
							onChange={(e) => {
								setSearchText(e.target.value);
							}}
						/>
					</span>
					<span className={styles.gripColumn1}>
						<label>Type</label>
						<Select
							selectedItem=""
							icon={null}
							options={[
								{ name: "Active", value: "Active" },
								{ name: "All Extensions", value: "All Extensions" },
							]}
							onChange={(e) => {
								setTypeFilter(e.target.value);
							}}
							defaultValue={""}
						/>
					</span>
				</div>

				<div className={styles.informaionBlock} style={{}}>
					<span>
						<span className={styles.noActivity}></span>No Activity:<b>{readyCount}</b>
					</span>
					<span>
						<span className={styles.activeCall}></span>Active Call:<b>{activeCallCount}</b>
					</span>
					<span>
						<span className={styles.onHold}></span>On Hold:<b>{holdCallCount}</b>
					</span>
					<span>
						<span className={styles.offline}></span>Offline:
						<b>{typeFilter === "All Extensions" ? totalCount - activeCallCount - readyCount - holdCallCount : "0"}</b>
					</span>
				</div>

				<div className={styles.extSortcard}>
					<span>Extensions: {filterdCount}</span>
					<span className={styles.sortBy}>Sort By:</span>
					<span className={styles.sortBySelect}>
						<Select
							selectedItem=""
							icon={null}
							options={[
								{ name: "Ext. Number", value: "ext.number" },
								{ name: "Ext. Name", value: "ext.name" },
							]}
							onChange={(e) => {
								setSortState(e.target.value);
							}}
							defaultValue={""}
						/>
					</span>
				</div>
			</div>
			{/* <div className={styles.activeExtension}>
				<span>Active Extension </span>
				<OnOffSwitch
					checked={isActiveExtension}
					onClick={() => {
						setIsActiveExtension(!isActiveExtension);
					}}
				/>
			</div> */}

			{allExtensionLoading || extensionLoading || allExtensionIsFetching || extensionIsFetching ? (
				<div className={showList ? "" : styles.grid}>
					{Array(48)
						.fill(null)
						.map((item, index) => (
							<ExtensionSkeleton key={index} />
						))}
				</div>
			) : null}
			<div className={showList ? "" : styles.grid}>
				{lists?.map((item, index) => (
					<ExtensionCard status={getStatusAll} extensionData={item} key={item?._id ? item?._id : index} />
				))}
			</div>
		</div>
	);
};

export default Extension;
