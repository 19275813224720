// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UpdateAvailable_bar__KwVQq {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 30px;
  color: var(--background-primary);
  background: var(--default-primary);
  -webkit-backdrop-filter: blur(16px);
          backdrop-filter: blur(16px);
}
.UpdateAvailable_bar_info__iVr8B {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  text-align: center;
  width: 100%;
}
.UpdateAvailable_bar_info__iVr8B > p {
  color: white;
  text-align: center;
}
.UpdateAvailable_bar_info__iVr8B button {
  padding: 2px 9px;
  background: rgb(62, 62, 145);
  margin-left: 10px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  color: white;
  font-size: 0.95em;
}`, "",{"version":3,"sources":["webpack://./src/components/UpdateAvailable/UpdateAvailable.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,iBAAA;EACA,gCAAA;EACA,kCAAA;EACA,mCAAA;UAAA,2BAAA;AACD;AACC;EACC,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;AACF;AACE;EACC,YAAA;EACA,kBAAA;AACH;AAEE;EACC,gBAAA;EACA,4BAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,iBAAA;AAAH","sourcesContent":[".bar {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\tpadding: 4px 30px;\n\tcolor: var(--background-primary);\n\tbackground: var(--default-primary);\n\tbackdrop-filter: blur(16px);\n\n\t&_info {\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\tfont-size: 13px;\n\t\ttext-align: center;\n\t\twidth: 100%;\n\n\t\t> p {\n\t\t\tcolor: white;\n\t\t\ttext-align: center;\n\t\t}\n\n\t\tbutton {\n\t\t\tpadding: 2px 9px;\n\t\t\tbackground: rgb(62, 62, 145);\n\t\t\tmargin-left: 10px;\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t\tborder-radius: 3px;\n\t\t\tcolor: white;\n\t\t\tfont-size: 0.95em;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bar": `UpdateAvailable_bar__KwVQq`,
	"bar_info": `UpdateAvailable_bar_info__iVr8B`
};
export default ___CSS_LOADER_EXPORT___;
