import { useSelector } from "react-redux";
import ActionBtnList from "../ActionBtnList";
import styles from "./ActionsBlock.module.scss";
import { sidecarConfig } from "redux/sidecar/sidecarSelectors";
import SearchBar from "components/UI/SearchBar";
import { useState } from "react";
import ActionSkeleton from "../ActionSkeleton";
import { useGetStatusAllQuery } from "services/status";
import { useGetAllExtensionsQuery } from "services/extension";

const ActionsBlock = ({showList=true, loading}:{loading:boolean,showList?:boolean}) => {
	const sidecarConfigList = useSelector(sidecarConfig);
	const [searchText, setSearchText] = useState("");
	const [showActionListID, setShowActionListID] = useState(-1);
	const { data: getStatusAll } = useGetStatusAllQuery(null);
	const { data: allExtensions, isLoading:allExtensionLoading, isFetching:allExtensionIsFetching } = useGetAllExtensionsQuery(localStorage.getItem("instance_id"));
	const filter = (item: { name: string; extension: string; }) => {
		if (item?.name || item?.extension) {
			return item?.name?.toLowerCase().includes(searchText.toLowerCase())||item?.extension?.toLowerCase().includes(searchText.toLowerCase());
		}else {
			return false;
		}
	};
	return (
		<section className={styles.section}>
			<div>
				<SearchBar
					placeholder={"search"}
					value={searchText}
					onChange={(e) => {
						setSearchText(e.target.value);
					}}
				/>
				<div className={`${styles.sidecarConfigList} ${showList? "" : styles.grid}` }>
					{sidecarConfigList?.filter(filter).map((sidecarAction, index) => {
						return (
							<ActionBtnList status={getStatusAll} allExtensions={allExtensions} showActionList={{showList:index === showActionListID, reset:()=>{setShowActionListID(-1)},show:()=>{setShowActionListID(index)}}} actionList={sidecarAction.actionList} uuid={sidecarAction.uuid} progress={sidecarAction.progress} name={sidecarAction.name} extension={sidecarAction.extension} actionCount={sidecarAction.actionList.length?.toString() }/>
						);
					})}
				</div>
				{loading? (
					<div className={`${styles.loader} ${showList? "" : styles.grid}` }>
						{Array(25)
						.fill(null)
						.map((item, index) => (
							<ActionSkeleton key={index} />
						))}
					</div>
				) : null}
			</div>
		</section>
	);
};

export default ActionsBlock;