import styles from "./RemoveQueueMember.module.scss";
import Backdrop from "components/UI/Backdrop";
import BtnAction from "components/UI/BtnAction";
import CloseIcon from "components/UI/Icons/Lines/Close";
import { useEffect, useState } from "react";
import BtnMedium from "components/UI/BtnMedium";
import { showToast } from "utils";
import { useLazyQueueLogoutQuery } from "services/queues";
import SearchBar from "components/UI/SearchBar";

const RemoveQueueMember = ({allQueuesRefresh, queueName, allExtensionLists, queueID, extensionList, close}:{allQueuesRefresh:()=>void,queueName?:string, extensionList:any[], allExtensionLists:any[],queueID:string, close:()=>void}) => {
	const [cancelHover, setCancelHover] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [ selectedList, setSelectedList] = useState<string[]>([]);
	const [memberExtension, setMemberExtension] = useState<string[]>([])
	const [queueLogout] = useLazyQueueLogoutQuery();
	useEffect(()=>{
		const memberExtensionList:string[] = []
		extensionList?.map((item)=>{
			if(item.membership!=="static"){
				memberExtensionList.push(String(item.id))
			}
		})
		setMemberExtension(memberExtensionList)
	},[extensionList])
	const queueUpdateError = (message:string)=>{
		showToast(`Queue ${message} failed, please try again later`, "error");
	}
	const queueUpdateSuccess = (message:string)=>{
		allQueuesRefresh();
		showToast(`Queue ${message} successful`, "success");
	}
	const save = ()=>{
		const fetch = async () => {
			try {
				const data = `[{"queue_id": "${queueID}","extension_ids": ${JSON.stringify(selectedList)}}]` 
				console.log(data)
				const result = await queueLogout(data);
				if(result.status === 'fulfilled'){
					queueUpdateSuccess("logout");
				}else{
					queueUpdateError("logout");
				}
			} catch (error) {
				queueUpdateError("logout")
			}
		}
		fetch();
		close()
	}
	const removeItemAll = (arr:string[], value:string)=>{
		var i = 0;
		while (i < arr?.length) {
		  if (arr[i] === value) {
			arr.splice(i, 1);
		  } else {
			++i;
		  }
		}
		return arr;
	}
	const selectClicked = (item: { _id?: string; })=>{
		if(!item._id) return
		if(selectedList?.includes(item?._id) ){
			setSelectedList(removeItemAll( [...selectedList], item?._id))
		}else{
			setSelectedList([item?._id, ...selectedList])
		}
	}
	const filter = (item: { data: { extension: string; name:string }; })=>{
		if(memberExtension.includes(String(item.data.extension)) && (String(item?.data?.extension)?.toLowerCase().includes(searchText.toLowerCase()) || String(item?.data?.name)?.toLowerCase().includes(searchText.toLowerCase()))){
			return true
		}
		return false 
	}
	return (
		<div className={styles.layer1}>
			<Backdrop
				onCancel={close}
			/>
			<div className={styles.layer2}>
				<div className={styles.headline}>
					<span className={`sub_headline_bold`}>{queueName} - Remove Queue Members</span>
					<BtnAction
						btnType={"normal"}
						isDisabled={false}
						type="button"
						isActive={false}
						onMouseOut={() => {
							setCancelHover(false);
						}}
						onMouseOver={() => {
							setCancelHover(true);
						}}
						onClick={close}
						icon={<CloseIcon color={cancelHover ? "primary-default" : "icon-primary"} />}
					/>
				</div>
				<p className={`footnote ${styles.heading}`}>Extension List</p>
				<span className={styles.search}>
					{/* <label>Search Extension</label> */}
					<SearchBar
						placeholder={"search"}
						value={searchText}
						onChange={(e) => {
							setSearchText(e.target.value);
						}}
					/>
				</span>
				<div className={styles.listBlock}>
					{allExtensionLists?.filter(filter)?.map((item)=>{
						return (
						<div className={styles.extensionItem}>
							<span
								className={`${styles.label} ${selectedList?.includes(item._id)? styles.activeLabel: ""}`}
								onClick={(e)=> {e.preventDefault();e.stopPropagation();selectClicked(item)} }
								>
								<div>
									<p>{item?.data?.name}</p>
									<span>{item?.data?.extension}</span>
								</div>
							</span>
						</div>)
					})}
					{
						allExtensionLists?.filter(filter).length > 0 ?"":<span className={styles.noData}>No more available extension</span>
					}
				</div>
				<div className={styles.addBtn}>
					<BtnMedium
						onClick={save}
						btnText="Remove Selected Extensions"
						btnType="secondary"
						isDanger={false}
						isDisabled={selectedList?.length === 0 ? true : false}
					/>
				</div>
				
			</div>
		</div>
	);
};

export default RemoveQueueMember;
