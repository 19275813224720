import { store } from "redux/store";
import styles from "./StatusMenu.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useLazySetStatusQuery, useLazySetStatusUserQuery } from "services/status";
import { emptyFunction } from "utils";
import Backdrop from "components/UI/Backdrop";
import BtnAction from "components/UI/BtnAction";
import CloseIcon from "components/UI/Icons/Lines/Close";
import { useState } from "react";
import BtnMedium from "components/UI/BtnMedium";
import UserStatusIcon from "components/UI/Icons/UserStatus";
import OnCallIcon from "components/UI/Icons/Status/OnCall";
import OnLunchIcon from "components/UI/Icons/Status/OnLunch";
import HolidayIcon from "components/UI/Icons/Status/Holiday";
import AFKIcon from "components/UI/Icons/Status/AFK";
import MeetIcon from "components/UI/Icons/Status/Meet";
import { updateShowStatusMenu } from "redux/sidecar/sidecarSlice";

const StatusMenu = ({
	setStatusByUser = false,
	userStatus,
}: {
	setStatusByUser?: boolean;
	userStatus?: { main_status: string; additional_status: string; userID?: string };
}) => {
	const { status } = useSelector((state: any) => state.sip);
	const currentStatus = userStatus ? userStatus : status;
	const [updateStatus] = useLazySetStatusQuery();
	const [setStatusUser] = useLazySetStatusUserQuery();
	const dispatch = useDispatch();
	const [cancelHover, setCancelHover] = useState(false);
	const update = (status: string, additional_status: string | null = "") => {
		if (status == "") return;
		if (additional_status == "" || additional_status == null || additional_status == undefined) {
			let userStatusMainStatus = status;
			if (setStatusByUser === false) {
				store.dispatch({
					type: "sip/status",
					payload: {
						main_status: userStatusMainStatus,
						additional_status: additional_status,
					},
				});
			}
		}
		const sendData =
			additional_status != ""
				? {
						main_status: {
							status: status,
						},
						additional_status: {
							status: additional_status,
							action_type: "manual",
							action: "set",
						},
				  }
				: {
						main_status: {
							status: status,
						},
				  };

		if (setStatusByUser) {
			setStatusUser({ data: JSON.stringify(sendData), userID: userStatus?.userID ? userStatus?.userID : "" });
			dispatch(updateShowStatusMenu(undefined));
		} else {
			updateStatus(JSON.stringify(sendData));
		}
	};

	return (
		<div className={styles.profileMenu}>
			<Backdrop
				onCancel={() => {
					store.dispatch({ type: "sip/statusMenu", payload: false });
					dispatch(updateShowStatusMenu(undefined));
				}}
			/>

			<div className={styles.profileBox} style={{ zIndex: "10001" }}>
				<div className={styles.headline}>
					<span className={`sub_headline_bold`}>Set Status</span>
					<BtnAction
						btnType={"normal"}
						isDisabled={false}
						type="button"
						isActive={false}
						onMouseOut={() => {
							setCancelHover(false);
						}}
						onMouseOver={() => {
							setCancelHover(true);
						}}
						onClick={() => {
							store.dispatch({ type: "sip/statusMenu", payload: false });
							dispatch(updateShowStatusMenu(undefined));
						}}
						icon={<CloseIcon color={cancelHover ? "primary-default" : "icon-primary"} />}
					/>
				</div>
				<p className={`footnote ${styles.heading}`}>Status</p>
				<div className={`body ${styles.status}`}>
					<div
						onClick={() => {
							update("available");
						}}
						className={`${styles.status_type}`}
						style={currentStatus.main_status === "available" ? { backgroundColor: "var(--border-tertiary)" } : {}}>
						<span>
							<UserStatusIcon color="status-success" />
							<span className={`${styles.status_type_active}`}>Available</span>
						</span>
					</div>
					<div
						onClick={() => {
							update("away");
						}}
						className={`${styles.status_type}`}
						style={currentStatus.main_status === "away" ? { backgroundColor: "var(--border-tertiary)" } : {}}>
						<span>
							<UserStatusIcon color="status-disabled" />
							<span className={`${styles.status_type_active}`}>Away</span>
						</span>
					</div>
					<div
						onClick={() => {
							update("do_not_disturb");
						}}
						className={`${styles.status_type}`}
						style={currentStatus.main_status === "do_not_disturb" ? { backgroundColor: "var(--border-tertiary)" } : {}}>
						<span>
							<UserStatusIcon color="status-danger" />
							<span className={`${styles.status_type_active}`}>Do not disturb</span>
						</span>
						<span>
							<span>This device only</span>
						</span>
					</div>
				</div>
				<p className={`footnote ${styles.heading}`}>Additional Status</p>
				<div className={`body ${styles.status}`}>
					<div
						onClick={() => {
							update(currentStatus.main_status, "on_a_call");
						}}
						className={`${styles.status_type}`}
						style={
							currentStatus.additional_status === "on_a_call" ? { backgroundColor: "var(--border-tertiary)" } : {}
						}>
						<span>
							<OnCallIcon />

							<span className={`${styles.status_type_active}`}>On a call</span>
						</span>
					</div>
					<div
						onClick={() => {
							update("do_not_disturb", "in_a_meeting");
						}}
						className={`${styles.status_type}`}
						style={
							currentStatus.additional_status === "in_a_meeting" ? { backgroundColor: "var(--border-tertiary)" } : {}
						}>
						<span>
							<MeetIcon />

							<span className={`${styles.status_type_active}`}>In a meeting</span>
						</span>
					</div>
					<div
						onClick={() => {
							update("do_not_disturb", "lunch");
						}}
						className={`${styles.status_type}`}
						style={currentStatus.additional_status == "lunch" ? { backgroundColor: "var(--border-tertiary)" } : {}}>
						<span>
							<OnLunchIcon />

							<span className={`${styles.status_type_active}`}>Lunch</span>
						</span>
						<span>{/* <span>DND :</span> */}</span>
					</div>
					<div
						onClick={() => {
							update(currentStatus.main_status, "holiday");
						}}
						className={`${styles.status_type}`}
						style={currentStatus.additional_status == "holiday" ? { backgroundColor: "var(--border-tertiary)" } : {}}>
						<span>
							<HolidayIcon />

							<span className={`${styles.status_type_active}`}>Holiday</span>
						</span>
						<span>{/* <span>DND :</span> */}</span>
					</div>
					<div
						onClick={() => {
							update(currentStatus.main_status, "afk");
						}}
						className={`${styles.status_type}`}
						style={currentStatus.additional_status == "afk" ? { backgroundColor: "var(--border-tertiary)" } : {}}>
						<span>
							<AFKIcon />
							<span className={`${styles.status_type_active}`}>AFK</span>
						</span>
					</div>
				</div>
				<BtnMedium
					onClick={() => {
						update("available", null);
					}}
					btnText="Clear Status"
					btnType="secondary"
					isDanger={false}
					isDisabled={false}
				/>
			</div>
		</div>
	);
};

export default StatusMenu;
