import React from "react";

const HangupIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
			<path
				d="M2.4393 11.6308L1.11835 10.3702C0.995225 10.2537 0.898963 10.1139 0.835732 9.95977C0.7725 9.80567 0.743692 9.64066 0.751153 9.47532C0.758614 9.30997 0.802179 9.14794 0.879059 8.99959C0.955939 8.85124 1.06444 8.71986 1.19761 8.61384C2.69976 7.46134 4.46425 6.66116 6.3493 6.27759C8.08921 5.90747 9.89318 5.90747 11.6331 6.27759C13.5259 6.66365 15.2969 7.46977 16.8024 8.63065C16.9352 8.73634 17.0434 8.86726 17.1203 9.01507C17.1971 9.16287 17.2408 9.32433 17.2487 9.48915C17.2566 9.65398 17.2284 9.81856 17.166 9.97242C17.1035 10.1263 17.0082 10.2661 16.8861 10.3828L15.5651 11.6434C15.3535 11.8493 15.0707 11.9748 14.7693 11.9965C14.468 12.0181 14.1686 11.9344 13.9271 11.7611C13.4487 11.4109 12.9302 11.1134 12.3816 10.8745C12.1648 10.7806 11.9807 10.6294 11.8511 10.4387C11.7216 10.2481 11.652 10.026 11.6507 9.79877L11.6507 8.73149C9.92154 8.27769 8.09608 8.27769 6.36692 8.73149L6.36691 9.79877C6.36558 10.026 6.29602 10.2481 6.16648 10.4387C6.03693 10.6294 5.85283 10.7806 5.63599 10.8745C5.08744 11.1134 4.56897 11.4109 4.09048 11.7611C3.84642 11.9364 3.54334 12.02 3.2389 11.996C2.93446 11.9719 2.64988 11.842 2.4393 11.6308Z"
				stroke="#6C7A8B"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default HangupIcon;
