import { RootState } from "./../../redux/store";

export const modalState = (state: RootState) => state.common.modal;
export const playPauseState = (state: RootState) => state.common.playPause;
export const notification = (state: RootState) => state.common.notification;
export const simpleNotification = (state: RootState) => state.common.simpleNotification;
export const loader = (state: RootState) => state.common.loader;
export const extChange = (state: RootState) => state.common.extChange;
export const sessionOut = (state: RootState) => state.common.sessionOut;
export const updates = (state: RootState) => state.common.updates;
export const version = (state: RootState) => state.common.version;
export const refreshVersion = (state: RootState) => state.common.refreshVersion;
export const contactName = (state: RootState) => state.common.contactName;
export const badgeCounter = (state: RootState) => state.common.badgeCounter;
export const showWarningModal = (state: RootState) => state.common.showWarningModal;
export const showSurveyModal = (state: RootState) => state.common.showSurveyModal;
