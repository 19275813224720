import { IIcon } from "constants/interfaces";

const EditPenIcon = ({ color }: IIcon) => {
	return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M10.9492 3.67019L14.204 6.94086L5.96526 15.2199L2.71234 11.9492L10.9492 3.67019ZM16.6737 2.88138L15.2222 1.42278C14.6612 0.859075 13.7504 0.859075 13.1875 1.42278L11.7971 2.81998L15.0519 6.09068L16.6737 4.46092C17.1088 4.02368 17.1088 3.31859 16.6737 2.88138ZM1.00906 16.5464C0.949825 16.8143 1.19051 17.0544 1.45711 16.9892L5.084 16.1055L1.83108 12.8348L1.00906 16.5464Z" fill={color ? color : "#00A2DD" }/>
		</svg>
	);
};

export default EditPenIcon;
