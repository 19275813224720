import Backdrop from "components/UI/Backdrop";
import React from "react";
import styles from "./ChooseActions.module.scss";
import CloseIcon from "components/UI/Icons/Close";
import DialIcon from "components/UI/Icons/Sidecar/Dial";
import PauseIcon from "components/UI/Icons/Sidecar/Pause";
import TransferIcon from "components/UI/Icons/Sidecar/Transfer";
import ConferenceIcon from "components/UI/Icons/Sidecar/Conference";
import MergeIcon from "components/UI/Icons/Sidecar/Merge";
import KeypressIcon from "components/UI/Icons/Sidecar/Keypress";
import HoldIcon from "components/UI/Icons/Sidecar/Hold";
import HangupIcon from "components/UI/Icons/Sidecar/Hangup";
import MessageIcon from "components/UI/Icons/Sidecar/Message";
import StatusIcon from "components/UI/Icons/Sidecar/Status";
import Status2Icon from "components/UI/Icons/Sidecar/Status2";
import { useDispatch } from "react-redux";
import { 
		setShowChooseActions,
		setShowPauseDialogue,
		setShowDialDialogue,
		setShowMainStatusDialogue,
		setShowAdditionalStatusDialogue,
		setShowSendMessageDialogue,
		setShowKeypressDialogue,
		setShowTransferDialogue,
		addSidecarConfigNewTempActionList,
		setShowConferenceDialogue,
		 } from "redux/sidecar/sidecarSlice";


const actionArray = [
	{ name: "Dial", icon: <DialIcon />, onClick:(dispatch: (arg0: { payload: any; type: "sidecar/setShowDialDialogue"|"sidecar/setShowChooseActions"; }) => void)=>{ dispatch(setShowChooseActions(false));dispatch(setShowDialDialogue({uuid:"", show:true})) } },
	{ name: "Pause", icon: <PauseIcon />, onClick:(dispatch: (arg0: { payload: any; type: "sidecar/setShowPauseDialogue"|"sidecar/setShowChooseActions"; }) => void)=>{ dispatch(setShowChooseActions(false));dispatch(setShowPauseDialogue({uuid:"", show:true})) } },
	{ name: "Hangup", icon: <HangupIcon />, onClick:(dispatch: (arg0: { payload: any; type: "sidecar/setShowChooseActions" | "sidecar/addSidecarConfigNewTempActionList"; }) => void)=>{dispatch(addSidecarConfigNewTempActionList({name:"Hangup", uuid:""}));dispatch(setShowChooseActions(false));}},
	{ name: "Transfer", icon: <TransferIcon />, onClick:(dispatch: (arg0: { payload: any; type: "sidecar/setShowTransferDialogue"|"sidecar/setShowChooseActions"; }) => void)=>{ dispatch(setShowChooseActions(false));dispatch(setShowTransferDialogue({uuid:"", show:true})) } },
	{ name: "Conference", icon: <ConferenceIcon />, onClick:(dispatch: (arg0: { payload: any; type: "sidecar/setShowChooseActions" | "sidecar/setShowConferenceDialogue"; }) => void)=>{dispatch(setShowChooseActions(false));dispatch(setShowConferenceDialogue({uuid:"", show:true})) } },
	{ name: "Merge", icon: <MergeIcon />, onClick:(dispatch: (arg0: { payload: any; type: "sidecar/setShowChooseActions" | "sidecar/addSidecarConfigNewTempActionList"; }) => void)=>{dispatch(addSidecarConfigNewTempActionList({name:"Merge", uuid:""}));dispatch(setShowChooseActions(false));}},
	{ name: "Key Press", icon: <KeypressIcon />, onClick:(dispatch: (arg0: { payload: any; type: "sidecar/setShowKeypressDialogue"|"sidecar/setShowChooseActions"; }) => void)=>{ dispatch(setShowChooseActions(false));dispatch(setShowKeypressDialogue({uuid:"", show:true})) } },
	{ name: "Hold", icon: <HoldIcon />, onClick:(dispatch: (arg0: { payload: any; type: "sidecar/setShowChooseActions" | "sidecar/addSidecarConfigNewTempActionList"; }) => void)=>{dispatch(addSidecarConfigNewTempActionList({name:"Hold", uuid:""}));dispatch(setShowChooseActions(false));}},
	{ name: "Send Message", icon: <MessageIcon />, onClick:(dispatch: (arg0: { payload: any; type: "sidecar/setShowSendMessageDialogue"|"sidecar/setShowChooseActions"; }) => void)=>{ dispatch(setShowChooseActions(false));dispatch(setShowSendMessageDialogue({uuid:"", show:true})) } },
	{ name: "Main Status", icon: <StatusIcon />, onClick:(dispatch: (arg0: { payload: any; type: "sidecar/setShowMainStatusDialogue"|"sidecar/setShowChooseActions"; }) => void)=>{ dispatch(setShowChooseActions(false));dispatch(setShowMainStatusDialogue({uuid:"", show:true})) } },
	{ name: "Additional Status", icon: <Status2Icon />, onClick:(dispatch: (arg0: { payload: any; type: "sidecar/setShowAdditionalStatusDialogue"|"sidecar/setShowChooseActions"; }) => void)=>{ dispatch(setShowChooseActions(false));dispatch(setShowAdditionalStatusDialogue({uuid:"", show:true})) } },
];

const ChooseActions = () => {
	const dispatch = useDispatch()
	return (
		<>
			<Backdrop onCancel={()=>dispatch(setShowChooseActions(false))}/>
			<div className={styles.chooseActions}>
				<h1 className={styles.topHeading}>
					<span>Choose Actions</span>
					<span className={styles.closeBox} onClick={()=>dispatch(setShowChooseActions(false))}>
						<CloseIcon />
					</span>
				</h1>
				<div className={styles.btmSection}>
					{actionArray?.map((item) => (
						<div key={item.name} className={styles.actionBox} onClick={()=>{
								item.onClick(dispatch)
							}}>
							<span>{item.icon}</span>
							<span>{item.name}</span>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default ChooseActions;
