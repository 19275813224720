import React from "react";

const DialIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
			<path
				d="M15.8752 12.3826V14.4451C15.876 14.6365 15.8368 14.8261 15.7601 15.0015C15.6834 15.1769 15.5709 15.3344 15.4298 15.4639C15.2887 15.5933 15.1221 15.6918 14.9407 15.7532C14.7594 15.8145 14.5672 15.8373 14.3765 15.8201C12.2609 15.5902 10.2288 14.8673 8.44335 13.7094C6.78224 12.6539 5.3739 11.2456 4.31835 9.58444C3.15646 7.79089 2.4334 5.74888 2.20773 3.62382C2.19055 3.4337 2.21314 3.24209 2.27407 3.06118C2.335 2.88028 2.43293 2.71404 2.56163 2.57306C2.69033 2.43207 2.84697 2.31943 3.02158 2.2423C3.1962 2.16517 3.38496 2.12525 3.57585 2.12507H5.63835C5.972 2.12178 6.29546 2.23993 6.54844 2.4575C6.80142 2.67506 6.96666 2.97719 7.01335 3.30757C7.10041 3.96761 7.26185 4.61569 7.4946 5.23944C7.5871 5.48551 7.60712 5.75295 7.55229 6.01005C7.49746 6.26715 7.37007 6.50314 7.18523 6.69007L6.3121 7.56319C7.2908 9.28438 8.71592 10.7095 10.4371 11.6882L11.3102 10.8151C11.4972 10.6302 11.7331 10.5028 11.9902 10.448C12.2474 10.3932 12.5148 10.4132 12.7609 10.5057C13.3846 10.7384 14.0327 10.8999 14.6927 10.9869C15.0267 11.0341 15.3317 11.2023 15.5497 11.4596C15.7677 11.7169 15.8836 12.0454 15.8752 12.3826Z"
				stroke="#6C7A8B"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default DialIcon;
