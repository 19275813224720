import React from "react";

const DNDThisDeviceIcon = () => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="7.99995" cy="7.99922" r="5.8" fill="#EE3939" stroke="white" strokeWidth="2" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.69742 5.22647C8.73646 5.2482 8.76711 5.28238 8.78446 5.32356C8.80181 5.36474 8.80487 5.41055 8.79315 5.45367L8.26195 7.40113H10.2001C10.2391 7.40114 10.2772 7.41253 10.3098 7.43391C10.3424 7.45529 10.368 7.48572 10.3835 7.52147C10.399 7.55722 10.4038 7.59673 10.3972 7.63514C10.3905 7.67355 10.3728 7.70919 10.3462 7.73767L7.54622 10.7377C7.51572 10.7704 7.47512 10.792 7.43092 10.7989C7.38671 10.8059 7.34146 10.7978 7.30238 10.776C7.26331 10.7542 7.23268 10.7199 7.21539 10.6786C7.1981 10.6374 7.19515 10.5915 7.20702 10.5483L7.73822 8.60113H5.80009C5.76111 8.60113 5.72298 8.58974 5.69039 8.56836C5.6578 8.54698 5.63217 8.51655 5.61665 8.48079C5.60112 8.44504 5.59638 8.40553 5.60301 8.36713C5.60964 8.32872 5.62734 8.29308 5.65395 8.2646L8.45395 5.2646C8.48445 5.23197 8.52499 5.21049 8.56911 5.20358C8.61324 5.19667 8.65841 5.20473 8.69742 5.22647Z"
				fill="white"
			/>
		</svg>
	);
};

export default DNDThisDeviceIcon;
