import { IIcon } from "constants/interfaces";

const MailIcon = ({ color }: IIcon) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path
				d="M14.6668 4.66699L8.68683 8.46699C8.48101 8.59594 8.24304 8.66433 8.00016 8.66433C7.75729 8.66433 7.51932 8.59594 7.3135 8.46699L1.3335 4.66699M2.66683 2.66699H13.3335C14.0699 2.66699 14.6668 3.26395 14.6668 4.00033V12.0003C14.6668 12.7367 14.0699 13.3337 13.3335 13.3337H2.66683C1.93045 13.3337 1.3335 12.7367 1.3335 12.0003V4.00033C1.3335 3.26395 1.93045 2.66699 2.66683 2.66699Z"
				stroke={`var(--${color ? color : "icon-primary"})`}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default MailIcon;
