import React from "react";
import { RouteObject } from "react-router-dom";
import routePaths from "./../../constants/routes";
import FallbackLoading from "components/FallbackLoading";

const Callback = React.lazy(() => import("pages/Callback"));

export const callbackRoutes: RouteObject[] = [
	{
		path: routePaths.CALLBACK.__PATH,
		element: (
			<React.Suspense fallback={<FallbackLoading />}>
				<Callback />
			</React.Suspense>
		),
	},
];
