import Backdrop from "components/UI/Backdrop";
import { useState } from "react";
import CloseIcon from "components/UI/Icons/Close";
import OnOffSwitch from "components/UI/OnOffSwitch";
import PlusIcon from "components/UI/Icons/Sidecar/Plus";
import ChooseStatus2 from "components/Sidecar/ChooseStatus2";
import styles from "../MainStatusDialogue/MainStatusDialogue.module.scss";
import Status2Icon from "components/UI/Icons/Sidecar/Status2";
import OnCall from "components/Sidecar/Status/OnCall";
import InMeeting from "components/Sidecar/Status/InMeeting";
import AFK from "components/Sidecar/Status/AFK";
import Holiday from "components/Sidecar/Status/Holiday";
import OnLunch from "components/Sidecar/Status/OnLunch";
import { useDispatch } from "react-redux";
import { addSidecarConfigNewTempActionList, setShowAdditionalStatusDialogue, setShowChooseActions } from "redux/sidecar/sidecarSlice";

const AdditionalStatusDialogue = ({to=[],from=[], uuid = "", toggleValue="0"} : {to?:Array<string>, from?:Array<string>, uuid:string, toggleValue?:string}) => {
	const dispatch = useDispatch()
	const [toForm, setToForm] = useState(false);
	const [fromForm, setFromForm] = useState(false);
	const [toVal, setToVal] = useState(to);
	const [fromVal, setFromVal] = useState(from);
	const [toggle, setToggle] = useState(toggleValue);
	const back = ()=>{
		dispatch(setShowAdditionalStatusDialogue({uuid:"", show:false}));
	}
	const calcFromVal = (value:number)=>{
		const newVal = [...fromVal]
		if(toggle==="1"){
			newVal.length = 0;
		}
		switch (value){
			case 1://oncall
				if(newVal.indexOf("on_a_call") === -1){
					newVal.push("on_a_call")
				}
				break;
			case -1://oncall
				if(newVal.indexOf("on_a_call") !== -1){
					newVal.splice(newVal.indexOf("on_a_call"), 1);
				}
				break; 
			case 2://inmetting
				if(newVal.indexOf("in_a_meeting") === -1){
					newVal.push("in_a_meeting")
				}
				break;
			case -2://inmetting
				if(newVal.indexOf("in_a_meeting") !== -1){
					newVal.splice(newVal.indexOf("in_a_meeting"), 1);
				}
				break;
			case 3://onlunch
				if(newVal.indexOf("lunch") === -1){
					newVal.push("lunch")
				}
				break;
			case -3://onlunch
				if(newVal.indexOf("lunch") !== -1){
					newVal.splice(newVal.indexOf("lunch"), 1);
				}
				break; 
			case 4://holiday
				if(newVal.indexOf("holiday") === -1){
					newVal.push("holiday")
				}
				break;
			case -4://holiday
				if(newVal.indexOf("holiday") !== -1){
					newVal.splice(newVal.indexOf("holiday"), 1);
				}
				break; 
			case 5://afk
				if(newVal.indexOf("afk") === -1){
					newVal.push("afk")
				}
				break;
			case -5://afk
				if(newVal.indexOf("afk") !== -1){
					newVal.splice(newVal.indexOf("afk"), 1);
				}
				break; 
		}
		setFromVal(newVal);
	}
	const calcToVal = (value:number)=>{
		const newVal = []
		switch (value){
			case 1://oncall
				newVal.push("on_a_call")
				break;
			case 2://inmetting
				newVal.push("in_a_meeting")
				break;
			case 3://onlunch
				newVal.push("lunch")
				break;
			case 4://holiday
				newVal.push("holiday")
				break; 
			case 5://afk
				newVal.push("afk")
				break; 
		}
		setToVal(newVal);
	}
	return (
		<>
			<Backdrop onCancel={()=>{dispatch(setShowAdditionalStatusDialogue({uuid:"", show:false}));if(!uuid)dispatch(setShowChooseActions(true))}}/>
			<div className={styles.dialogueBox} onClick={()=>{setToForm(false);setFromForm(false)}}>
				<h1 className={styles.topHeading}>
					<span>
						<Status2Icon />
						<span>Additional Status</span>
					</span>

					<span className={styles.closeBox} onClick={()=>{dispatch(setShowAdditionalStatusDialogue({uuid:"", show:false}));if(!uuid)dispatch(setShowChooseActions(true))}}>
						<CloseIcon />
					</span>
				</h1>

				<div className={styles.toggleBox}>
					<span>Use as a toggle</span>
					<OnOffSwitch onClick={()=>{setToggle(toggle==="0"?"1":"0");setFromVal([])}} checked={toggle==="0"?false:true} />
				</div>

				<div className={styles.fromStatus}>
					<span>From Status:</span>
					<span>
						{fromVal.indexOf("on_a_call") !== -1 && <OnCall onClick={()=>{calcFromVal(-1)}}/>}
						{fromVal.indexOf("in_a_meeting") !== -1 && <InMeeting onClick={()=>{calcFromVal(-2)}}/>}
						{fromVal.indexOf("lunch") !== -1 && <OnLunch onClick={()=>{calcFromVal(-3)}}/>}
						{fromVal.indexOf("holiday") !== -1 && <Holiday onClick={()=>{calcFromVal(-4)}}/>}
						{fromVal.indexOf("afk") !== -1 && <AFK onClick={()=>{calcFromVal(-5)}}/>}
						<span className={styles.plusIcon} onClick={(e)=>{setToForm(false);setFromForm(true);e.stopPropagation();}}>
							<PlusIcon />
						</span>
					</span>
					{fromForm && <ChooseStatus2 onClick={calcFromVal}/>}
				</div>

				<div className={styles.toStatus}>
					<span>To Status:</span>
					<span>
						{toVal.indexOf("on_a_call") !== -1 && <OnCall onClick={()=>{calcToVal(-1)}}/>}
						{toVal.indexOf("in_a_meeting") !== -1 && <InMeeting onClick={()=>{calcToVal(-2)}}/>}
						{toVal.indexOf("lunch") !== -1 && <OnLunch onClick={()=>{calcToVal(-3)}}/>}
						{toVal.indexOf("holiday") !== -1 && <Holiday onClick={()=>{calcToVal(-4)}}/>}
						{toVal.indexOf("afk") !== -1 && <AFK onClick={()=>{calcToVal(-5)}}/>}
						<span className={styles.plusIcon} onClick={(e)=>{setToForm(true);setFromForm(false);e.stopPropagation();}}>
							<PlusIcon />
						</span>
					</span>
					{toForm && <ChooseStatus2 onClick={calcToVal}/>}
				</div>

				<div className={styles.btnBox}>
				<button 
						disabled={ toVal.length === 0 || fromVal.length === 0 ? true:false}
						className={`${toVal.length === 0 || fromVal.length === 0 ? styles.disabled:{}}`}
						onClick={()=>{dispatch(addSidecarConfigNewTempActionList({name:"ModifyAditionalStatus", uuid:uuid, fromStatuses:fromVal, toStatus:toVal, toggleMode:toggle }));back();} }>{uuid ? "Update":"Add Action"}</button>
				</div>
			</div>
		</>
	);
};

export default AdditionalStatusDialogue;
