import UserStatusIcon from "components/UI/Icons/UserStatus";
import React from "react";
import styles from "../ChooseStatus/ChooseStatus.module.scss";
import CalenderIcon from "components/UI/Icons/Status/Calender";
import OnCallIcon from "components/UI/Icons/Status/OnCall";
import OnLunchIcon from "components/UI/Icons/Status/OnLunch";
import HolidayIcon from "components/UI/Icons/Status/Holiday";
import AFKIcon from "components/UI/Icons/Status/AFK";

const ChooseStatus2 = ( {onClick}:{onClick:(val:number)=>void}) => {
	return (
		<div className={styles.statusBox}>
			<h1>Choose Status</h1>
			<div>
				<p onClick={()=>{onClick(1)}}>
					<span>
						<OnCallIcon />
					</span>
					<span>On a call</span>
				</p>
				<p onClick={()=>{onClick(2)}}>
					<span>
						<CalenderIcon />
					</span>
					<span>In a meeting</span>
				</p>
				<p onClick={()=>{onClick(3)}}>
					<span>
						<OnLunchIcon />
					</span>
					<span>Lunch</span>
				</p>
				<p onClick={()=>{onClick(4)}}>
					<span>
						<HolidayIcon />
					</span>
					<span>Holiday</span>
				</p>
				<p onClick={()=>{onClick(5)}}>
					<span>
						<AFKIcon />
					</span>
					<span>AFK</span>
				</p>
			</div>
		</div>
	);
};

export default ChooseStatus2;
