// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SidecarManage_layer1__K5Yrm {
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
}
.SidecarManage_layer1__K5Yrm h2 {
  color: var(--text-secondary, #5c6168);
  font-family: Poppins;
  font-size: 0.9em;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.2px;
  padding: 15px 0;
}

.SidecarManage_layer2__6aDUF {
  overflow-y: auto;
}

.SidecarManage_addActionBox__uPJfW {
  width: -moz-fit-content;
  width: fit-content;
}
.SidecarManage_addActionBox__uPJfW button {
  color: var(--text-on-color-, #fff) !important;
}

.SidecarManage_table__sQ7my {
  width: 100%;
  border-collapse: collapse;
}

.SidecarManage_noActions__qcjOo {
  position: absolute;
  width: calc(100% - 70px);
  height: calc(100% - 114px);
}`, "",{"version":3,"sources":["webpack://./src/components/Sidecar/SidecarManage/SidecarManage.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EAKA,aAAA;EACA,sBAAA;AAHD;AAOC;EACC,qCAAA;EACA,oBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,eAAA;AALF;;AAQA;EACC,gBAAA;AALD;;AAOA;EACC,uBAAA;EAAA,kBAAA;AAJD;AAKC;EACC,6CAAA;AAHF;;AAMA;EACC,WAAA;EACA,yBAAA;AAHD;;AAKA;EACC,kBAAA;EACA,wBAAA;EACA,0BAAA;AAFD","sourcesContent":[".layer1 {\n\tpadding: 15px 30px;\n\n\t// width: 100%;\n\t// height: calc(100% - 54px);\n\n\tdisplay: flex;\n\tflex-direction: column;\n\n\t// background-color: green;\n\n\th2 {\n\t\tcolor: var(--text-secondary, #5c6168);\n\t\tfont-family: Poppins;\n\t\tfont-size: 0.9em;\n\t\tfont-style: normal;\n\t\tfont-weight: 400;\n\t\tline-height: 14px;\n\t\tletter-spacing: -0.2px;\n\t\tpadding: 15px 0;\n\t}\n}\n.layer2 {\n\toverflow-y: auto;\n}\n.addActionBox {\n\twidth: fit-content;\n\tbutton {\n\t\tcolor: var(--text-on-color-, #fff) !important;\n\t}\n}\n.table {\n\twidth: 100%;\n\tborder-collapse: collapse;\n}\n.noActions {\n\tposition: absolute;\n\twidth: calc(100% - 70px);\n\theight: calc(100% - 114px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layer1": `SidecarManage_layer1__K5Yrm`,
	"layer2": `SidecarManage_layer2__6aDUF`,
	"addActionBox": `SidecarManage_addActionBox__uPJfW`,
	"table": `SidecarManage_table__sQ7my`,
	"noActions": `SidecarManage_noActions__qcjOo`
};
export default ___CSS_LOADER_EXPORT___;
