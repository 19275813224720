import { IIcon } from "constants/interfaces";

const PhoneEndIcon = ({ color }: IIcon) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
			<path
				d="M0.803675 20.3065L3.68574 23.1351C4.1452 23.6089 4.76609 23.9006 5.43033 23.9545C6.09457 24.0083 6.75582 23.8208 7.28833 23.4274C8.3323 22.6415 9.4635 21.9741 10.6603 21.4379C11.1335 21.2273 11.5351 20.888 11.8178 20.4602C12.1004 20.0324 12.2522 19.534 12.2551 19.0241V16.6293C16.0278 15.611 20.0106 15.611 23.7834 16.6293V19.0241C23.7863 19.534 23.938 20.0324 24.2207 20.4602C24.5033 20.888 24.905 21.2273 25.3781 21.4379C26.575 21.9741 27.7061 22.6415 28.7501 23.4274C29.277 23.8164 29.9301 24.0042 30.5876 23.9556C31.2452 23.907 31.8622 23.6254 32.3239 23.1634L35.2059 20.3347C35.4724 20.0727 35.6804 19.7591 35.8167 19.4138C35.9529 19.0686 36.0144 18.6992 35.9972 18.3294C35.98 17.9595 35.8846 17.5972 35.7169 17.2656C35.5493 16.9339 35.3131 16.6401 35.0234 16.403C31.7386 13.798 27.8748 11.9892 23.7449 11.1229C19.9488 10.2924 16.0128 10.2924 12.2167 11.1229C8.10381 11.9836 4.25401 13.7791 0.9766 16.3652C0.686048 16.6031 0.449321 16.898 0.281584 17.2308C0.113847 17.5637 0.0187948 17.9273 0.00251604 18.2983C-0.0137627 18.6694 0.0490894 19.0396 0.18705 19.3854C0.325011 19.7312 0.535035 20.0449 0.803675 20.3065Z"
				fill={`var(--${color ? color : "icon-primary"})`}
			/>
		</svg>
	);
};

export default PhoneEndIcon;
