import React from "react";

const ArrowRight = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
			<path
				d="M11.6665 5L16.6665 10L11.6665 15M15.8332 10L2.9165 10"
				stroke="#9298A0"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default ArrowRight;
