import { useState, useEffect } from "react";
import {
	useLazyCreateConversationObjectQuery,
	useLazyGetConversationListsQuery,
	useLazySendOutboundMessageQuery,
} from "services/chat";
import { useLazyGetContactsQuery } from "services/contact";

const useChatAPI = () => {
	const [phone, setPhone] = useState("");
	const [msg, setMsg] = useState("");
	const [textingNumber, setTextingNumber] = useState("");
	const [del, setDel] = useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [error, setError] = useState<boolean>(false);
	const [response, setResponse] = useState<any>(null);
	const [
		getContact,
		{ data: contactData, isLoading: contactLoading, isFetching: contactFetching, isError: contactError },
	] = useLazyGetContactsQuery();
	const [
		getConversationLists,
		{ data: convListData, isLoading: convListLoading, isFetching: convListFetching, isError: convListError },
	] = useLazyGetConversationListsQuery();
	const [createConversationObject, { data: createConvData, isError: createConvError }] =
		useLazyCreateConversationObjectQuery();
	const [sendOutboundMessage, { data: outboundMessage, isFetching: isFetchingMsg }] = useLazySendOutboundMessageQuery();

	useEffect(() => {
		if (contactError || convListError) {
			setError(true);
		}
	}, [contactError, convListError]);

	useEffect(() => {
		if (outboundMessage) {
			setResponse(outboundMessage);
		}
	}, [outboundMessage]);

	const sendMessageHandler = async (id: string) => {
		await sendOutboundMessage({
			id,
			data: {
				text: msg,
				with_warning: false,
			},
		});
	};

	useEffect(() => {
		if (createConvData) {
			sendMessageHandler(createConvData?.id);
		}
	}, [createConvData]);

	const createNewConversation = async () => {
		if (contactData?.length) {
			await createConversationObject({
				recipients: [
					{
						number: contactData[0]?.phone,
					},
				],
				from_number: textingNumber,
				conversation_type: "direct",
			});
		}
	};

	useEffect(() => {
		if (contactData?.length) {
			getConversationLists(
				new URLSearchParams({
					contact_id: contactData[0]?.id,
				}),
			);
		}
	}, [contactData]);

	useEffect(() => {
		if (contactData?.length && !convListLoading && !convListFetching && !error) {
			if (convListData?.length) {
				sendMessageHandler(convListData[0]?.id);
			} else {
				createNewConversation();
			}
		}
	}, [convListData]);

	const sendMessage = ({
		phone,
		msg,
		texting_number,
		del,
	}: {
		phone: string;
		msg: string;
		texting_number: string;
		del?: boolean;
	}) => {
		const getContactHandler = async () => {
			setIsLoading(true);
			try {
				let cleanedNumber = phone?.replace(/[^0-9+]/g, "");
				cleanedNumber = cleanedNumber?.replace(/\s/g, "");

				await getContact({
					phone: cleanedNumber,
				});

				// setResponse(responseData);
			} catch (error) {
				setError(true);
				setIsLoading(false);
			} finally {
				setIsLoading(false);
			}
		};

		if (phone && msg) {
			setPhone(phone);
			setMsg(msg);
			setTextingNumber(texting_number);
			getContactHandler();
		}
	};

	return { isLoading, error, response, sendMessage };
};

export default useChatAPI;
