import styles from "./GeneralPromptDialog.module.scss";
import Backdrop from "components/UI/Backdrop";
import { ClipLoader } from "react-spinners";
import BtnLarge from "components/UI/BtnLarge";
import InfoIcon from "components/UI/Icons/Lines/Info";
const GeneralPromptDialog = ({ type, title, actionBtnTxt, children, onClick, loading, onCancel, loadingSecondary, actionBtnTxtSecondary}:{ type?:"warning", title:string, actionBtnTxt:string, children:React.ReactNode, onClick:(e?: any)=>void, loading?:string, onCancel:(e?: any)=>void , loadingSecondary?:string, actionBtnTxtSecondary?:string}) => {
	return (
		<div>
			<Backdrop onCancel={onCancel} />
			<div className={styles.delete}>
				<div className={styles.delete_cont}>
					<span>
						<InfoIcon />
					</span>
					<div className={styles.delete_head}>{title}</div>
					<div className={styles.delete_ques}>{children}</div>
				</div>

				<div className={styles.delete_btnCont}>
					<BtnLarge
						btnType={"secondary"}
						isDanger={false}
						isDisabled={false}
						type="button"
						btnText={loadingSecondary && typeof(loadingSecondary) === "string" ? loadingSecondary : (actionBtnTxtSecondary?actionBtnTxtSecondary:"Cancel")}
						onClick={onCancel}
					/>
					<BtnLarge
						btnType={"primary"}
						isDanger={type === "warning" ? true : false}
						isDisabled={false}
						type="button"
						btnText={loading && typeof(loading) === "string" ? loading : actionBtnTxt}
						icon={loading && typeof(loading) === "string" ? <ClipLoader color="white" size={14} /> : ""}
						onClick={onClick}
					/>
				</div>
			</div>
		</div>
	);
};

export default GeneralPromptDialog;
