import styles from "./SidecarManage.module.scss";
import ActionBtnCard from "../ActionBtnCard";
import Button from "components/UI/Forms/Button";
import PlusIcon from "components/UI/Icons/Sidecar/Plus";
import { useDispatch, useSelector } from "react-redux";
import { resetSidecarConfigNew, setShowButtonProgramming } from "redux/sidecar/sidecarSlice";
import { sidecarConfig, sidecarConfigNewTemp } from "redux/sidecar/sidecarSelectors";
import NoActions from "../NoActions";
import { useGetAllExtensionsQuery } from "services/extension";
import { useGetStatusAllQuery } from "services/status";

const SidecarManage = ({
	deleteConfirmation,
	search,
}: {
	deleteConfirmation: ({ actionName, uuid }: { actionName: string; uuid: string }) => void;
	search: string;
}) => {
	const dispatch = useDispatch();
	const sidecarConfigList = useSelector(sidecarConfig);
	const sidecarConfigNewTempState = useSelector(sidecarConfigNewTemp);
	const filter = (item: { name?: string }) => {
		if (item?.name) {
			return item?.name.toLowerCase().includes(search.toLowerCase());
		} else {
			return false;
		}
	};
	const { data: getStatusAll } = useGetStatusAllQuery(null);
	const { data: allExtensions, isLoading:allExtensionLoading, isFetching:allExtensionIsFetching } = useGetAllExtensionsQuery(localStorage.getItem("instance_id"));
	return (
		<div>
			{sidecarConfigList.length > 0 ? (
				<div className={styles.layer1}>
					<h2 className={styles.h2}>
						Button{sidecarConfigList?.filter(filter)?.length > 1 ? "s" : ""} (
						{sidecarConfigList?.filter(filter)?.length})
					</h2>
					<div className={styles.layer2}>
						<table className={styles.table}>
							{sidecarConfigList?.filter(filter)?.map((sidecarAction) => {
								return (
									<ActionBtnCard
										status={getStatusAll} 
										allExtensions={allExtensions} 
										uuid={sidecarAction.uuid}
										name={sidecarAction.name}
										deleteConfirmation={deleteConfirmation}
										extension={sidecarAction.extension}
										actionCount={sidecarAction.actionList?.length?.toString()}
									/>
								);
							})}
						</table>
					</div>
					<div className={styles.addActionBox}>
						<Button
							fill
							styles={{ padding: "5px 11px" }}
							onClick={() => {
								if (sidecarConfigNewTempState.uuid) {
									dispatch(resetSidecarConfigNew());
								}
								dispatch(setShowButtonProgramming(true));
							}}>
							<PlusIcon />
							Add Action
						</Button>
					</div>
				</div>
			) : (
				<div className={styles.noActions}>
					<NoActions />
				</div>
			)}
		</div>
	);
};

export default SidecarManage;
