import { apiService } from "./api";

export const extensionService = apiService.injectEndpoints({
	endpoints: (build) => ({
		getAllQueues: build.query({
			query: (page) => ({ method: "GET", url: `/pbx/queues/statuses?page=${page}&per_page=300` }),
		}),
		getMyQueues: build.query({
			query: (id) => ({ method: "GET", url: `/pbx/queues/${id}/status` }),
		}),
		queueLogout: build.query({
			query: (data) => ({ method: "POST", url: `/pbx/queues/logout`, data}),
		}),
		queueLogin: build.query({
			query: (data) => ({ method: "POST", url: `/pbx/queues/login`, data }),
		}),
	}),
});

export const { useGetAllQueuesQuery, useGetMyQueuesQuery, useLazyQueueLoginQuery, useLazyQueueLogoutQuery } = extensionService;
