import { IIcon } from "constants/interfaces";
import React from "react";

const ContactIcon: React.FC<IIcon> = ({ color }) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="line / contact" clipPath="url(#clip0_2129_3067)">
			<path
				id="Vector"
				d="M12.4312 12.9816C11.9136 12.2963 11.2439 11.7406 10.475 11.3582C9.70606 10.9757 8.85883 10.7771 8.00004 10.7779C7.14126 10.7771 6.29403 10.9757 5.5251 11.3582C4.75616 11.7406 4.08652 12.2963 3.56893 12.9816M12.4312 12.9816C13.4412 12.0832 14.1535 10.899 14.475 9.58607C14.7965 8.27311 14.7113 6.89343 14.2308 5.63C13.7502 4.36656 12.8969 3.27907 11.784 2.51174C10.6712 1.74441 9.35141 1.3335 7.99967 1.3335C6.64794 1.3335 5.32814 1.74441 4.21531 2.51174C3.10247 3.27907 2.24918 4.36656 1.76859 5.63C1.288 6.89343 1.20282 8.27311 1.52436 9.58607C1.84589 10.899 2.55893 12.0832 3.56893 12.9816M12.4312 12.9816C11.2119 14.0692 9.63384 14.6691 8.00004 14.6668C6.36599 14.6693 4.78837 14.0693 3.56893 12.9816M10.2223 6.33336C10.2223 6.92274 9.98814 7.48798 9.57139 7.90473C9.15465 8.32148 8.58941 8.55561 8.00004 8.55561C7.41067 8.55561 6.84544 8.32148 6.4287 7.90473C6.01195 7.48798 5.77782 6.92274 5.77782 6.33336C5.77782 5.74398 6.01195 5.17874 6.4287 4.76198C6.84544 4.34523 7.41067 4.1111 8.00004 4.1111C8.58941 4.1111 9.15465 4.34523 9.57139 4.76198C9.98814 5.17874 10.2223 5.74398 10.2223 6.33336Z"
				stroke={`var(--${color ? color : "icon-primary"})`}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2129_3067">
				<rect width="16" height="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default ContactIcon;
