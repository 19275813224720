import styles from "./Conference.module.scss";
import DeleteIcon from "components/UI/Icons/Delete";
import EditIcon from "components/UI/Icons/Lines/Edit";
import ConferenceIcon from "components/UI/Icons/Sidecar/Conference";
import MoveUpDown from "components/UI/Icons/Sidecar/MoveUpDown";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { removeSidecarConfigNewTempActionList, setShowConferenceDialogue } from "redux/sidecar/sidecarSlice";
const ConferenceActionCard = ({uuid, drag, numbers, changesMade}:{uuid:string, drag?:any, numbers:Array<string>, changesMade:()=>void }) => {
	const [editHover, setEditHover] = useState(false);
	const [deleteHover, setDeleteHover] = useState(false);
	const dispatch = useDispatch();
	return (
		<div className={styles.card} draggable onDragEnd={drag?.handlesort}  onDragEnter={()=>{drag?.dragEnter(drag)}} onDragStart={()=>{drag?.dragStart(drag)}}> 
			<p>
				{drag && <MoveUpDown />}
				<ConferenceIcon />
				<span>Conference</span>
			</p>
			<p>
				<span className={styles.extension}>{numbers.length} Members</span>
				{uuid &&
				<>
					<span className={styles.actionButton} onClick={()=>{changesMade(); dispatch(setShowConferenceDialogue({uuid:`${uuid}`, show:true, numbers:numbers }))}} onMouseOver={() => {setEditHover(true);}} onMouseOut={() => {setEditHover(false);}}><EditIcon color={editHover?"primary-default" : "icon-primary"}/></span>
					<span className={styles.actionButton} onClick={()=>{changesMade(); dispatch(removeSidecarConfigNewTempActionList(uuid))}} onMouseOver={() => {setDeleteHover(true);}} onMouseOut={() => {setDeleteHover(false);}}><DeleteIcon color={deleteHover?"support-danger-default" : "icon-primary"} /></span>
				</>}
			</p>
		</div>
	);
};

export default ConferenceActionCard;
