import React from "react";
import styles from "./Select.module.scss";
interface item {
	value?: string;
	name?: string;
	selected?: boolean;
}
const Select = ({
	icon,
	options,
	onChange = undefined,
	defaultValue,
	selectedItem,
}: {
	icon: any;
	options: item[];
	onChange: React.ChangeEventHandler<HTMLSelectElement> | undefined;
	defaultValue: string;
	selectedItem: string;
}) => {
	return (
		<div className={`${styles.optionBox} ${icon ? styles.iconTrue : ""}`}>
			<select
				className={`caption_1`}
				onChange={(e) => {
					onChange && onChange(e);
				}}
				defaultValue={defaultValue}>
				{options?.map((item: item) => (
					<option value={item.value} key={item.name} selected={item.value === selectedItem}>
						{item.name}
					</option>
				))}
			</select>
			<span>{icon}</span>
		</div>
	);
};

export default Select;
