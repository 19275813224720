import { IIcon } from "constants/interfaces";

const SearchIcon = ({ color }: IIcon) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<path
				d="M21 20.9998L15.803 15.8028M15.803 15.8028C17.2096 14.3962 17.9998 12.4885 17.9998 10.4993C17.9998 8.51011 17.2096 6.60238 15.803 5.19581C14.3965 3.78923 12.4887 2.99902 10.4995 2.99902C8.51035 2.99902 6.60262 3.78923 5.19605 5.19581C3.78947 6.60238 2.99927 8.51011 2.99927 10.4993C2.99927 12.4885 3.78947 14.3962 5.19605 15.8028C6.60262 17.2094 8.51035 17.9996 10.4995 17.9996C12.4887 17.9996 14.3965 17.2094 15.803 15.8028Z"
				stroke={`var(--${color ? color : "icon-primary"})`}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default SearchIcon;
