import { FadeLoader } from "react-spinners";
import styles from "./FallbackLoading.module.scss";

const FallbackLoading = () => (
	<div className={styles.FallbackLoading}>
		<FadeLoader color="var(--text-primary)" />
	</div>
);

export default FallbackLoading;
