// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactCard_contact__xS9pX {
  height: 54px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 10px;
  width: 100%;
}
.ContactCard_contact_circle__vjLpQ {
  width: 38px;
  height: 38px;
  flex-shrink: 0;
  font-size: 16px;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--background-primary);
  border-radius: 32px;
  background: var(--accent-blue-primary, #3b9ef7);
}
.ContactCard_contact_name__vjb35 {
  display: flex;
  align-items: center;
  gap: 5px;
}
.ContactCard_contact_name__vjb35 > span {
  color: var(--text-primary);
}
.ContactCard_contact__xS9pX:hover {
  background: var(--background-active);
}`, "",{"version":3,"sources":["webpack://./src/components/Contact/ContactCard/ContactCard.module.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;EACA,WAAA;AACD;AACC;EACC,WAAA;EACA,YAAA;EACA,cAAA;EACA,eAAA;EACA,qBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gCAAA;EACA,mBAAA;EACA,+CAAA;AACF;AAEC;EACC,aAAA;EACA,mBAAA;EACA,QAAA;AAAF;AAEE;EACC,0BAAA;AAAH;AAIC;EACC,oCAAA;AAFF","sourcesContent":[".contact {\n\theight: 54px;\n\tdisplay: flex;\n\talign-items: center;\n\tgap: 10px;\n\tpadding-left: 10px;\n\twidth: 100%;\n\n\t&_circle {\n\t\twidth: 38px;\n\t\theight: 38px;\n\t\tflex-shrink: 0;\n\t\tfont-size: 16px;\n\t\tdisplay: inline-block;\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\tcolor: var(--background-primary);\n\t\tborder-radius: 32px;\n\t\tbackground: var(--accent-blue-primary, #3b9ef7);\n\t}\n\n\t&_name {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tgap: 5px;\n\n\t\t> span {\n\t\t\tcolor: var(--text-primary);\n\t\t}\n\t}\n\n\t&:hover {\n\t\tbackground: var(--background-active);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contact": `ContactCard_contact__xS9pX`,
	"contact_circle": `ContactCard_contact_circle__vjLpQ`,
	"contact_name": `ContactCard_contact_name__vjb35`
};
export default ___CSS_LOADER_EXPORT___;
