import { RouteObject } from "react-router-dom";
import routePaths from "./../../constants/routes";
import ProtectedRoutes from "utils/ProtectedRoutes";
import React from "react";
import Loader from "components/UI/Loader";

const Chat = React.lazy(() => import("pages/Chat"));

export const chatRoutes: RouteObject[] = [
	{
		path: routePaths.CHAT.__PATH,
		element: (
			<ProtectedRoutes>
				<React.Suspense fallback={<Loader />}>
					<Chat />
				</React.Suspense>
			</ProtectedRoutes>
		),
	},
];
