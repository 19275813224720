import React, { useState } from "react";
import styles from "./Hold.module.scss";
import PauseIcon from "components/UI/Icons/Sidecar/Pause";
import DeleteIcon from "components/UI/Icons/Delete";
import TransferIcon from "components/UI/Icons/Sidecar/Transfer";
import MergeCallIcon from "components/UI/Icons/VideoCall/MergeCall";
import ConferenceIcon from "components/UI/Icons/Sidecar/Conference";
import HangupIcon from "components/UI/Icons/Sidecar/Hangup";
import HolidayIcon from "components/UI/Icons/Status/Holiday";
import HoldIcon from "components/UI/Icons/Sidecar/Hold";
import EditIcon from "components/UI/Icons/Lines/Edit";
import { useDispatch } from "react-redux";
import { removeSidecarConfigNewTempActionList } from "redux/sidecar/sidecarSlice";
import MoveUpDown from "components/UI/Icons/Sidecar/MoveUpDown";

const HoldActionCard = ({uuid, drag, changesMade}:{uuid:string, drag?:any, changesMade:()=>void}) => {
	const [editHover, setEditHover] = useState(false);
	const [deleteHover, setDeleteHover] = useState(false);
	const dispatch = useDispatch();
	return (
		<div className={styles.card} draggable onDragEnd={drag?.handlesort}  onDragEnter={()=>{drag?.dragEnter(drag)}} onDragStart={()=>{drag?.dragStart(drag)}}> 
			<p>
				{drag && <MoveUpDown />}
				<HoldIcon />
				<span>Hold</span>
			</p>
			<p>
				{/* <EditIcon color="icon-primary" /> */}
				{uuid &&
				<>
					<span className={styles.actionButton} onClick={()=>{changesMade(); dispatch(removeSidecarConfigNewTempActionList(uuid))}} onMouseOver={() => {setDeleteHover(true);}} onMouseOut={() => {setDeleteHover(false);}}><DeleteIcon color={deleteHover?"support-danger-default" : "icon-primary"} /></span>
				</>}
				
			</p>
		</div>
	);
};

export default HoldActionCard;
