import Backdrop from "components/UI/Backdrop";
import { useRef, useState } from "react";
import styles from "./ButtonProgramming.module.scss";
import CloseIcon from "components/UI/Icons/Close";
import PlusIcon from "components/UI/Icons/Sidecar/Plus";
import DialActionCard from "../Actions/Dial";
import PauseActionCard from "../Actions/Pause";
import TransferActionCard from "../Actions/Transfer";
import MergeActionCard from "../Actions/Merge";
import HangupActionCard from "../Actions/Hangup";
import KeypressActionCard from "../Actions/Keypress";
import HoldActionCard from "../Actions/Hold";
import SendMessageActionCard from "../Actions/SendMessage";
import MainStatusActionCard from "../Actions/MainStatus";
import AdditionalStatusActionCard from "../Actions/AdditionalStatus";
import { useDispatch, useSelector } from "react-redux";
import { setShowButtonProgramming, setShowChooseActions, setSidecarConfigNewTempName, setSidecarConfigNewTempExtension, addSidecarAction, resetSidecarConfigNew, moveSidecarActionList } from "redux/sidecar/sidecarSlice";
import { sidecarConfigNewTemp } from "redux/sidecar/sidecarSelectors";
import ConferenceActionCard from "../Actions/Conference";
import GeneralPromptDialog from "components/Modal/GeneralPromptDialog";
import BtnAction from "components/UI/BtnAction";

const ButtonProgramming = () => {
	const dispatch = useDispatch()
	const sidecarConfigNewTempState = useSelector(sidecarConfigNewTemp)
	const dragItem = useRef<number>(-1);
	const overItem = useRef<number>(-1);
	const changes = useRef<boolean>(false);
	const [showSaveConfirmationDialog, setSaveConfirmationDialog] = useState(false);
	const handlesort = ()=>{
		if(dragItem.current === overItem.current)return;
		dispatch(moveSidecarActionList({from:dragItem.current,to:overItem.current}));
	}
	const dragEnter = (drag: { overItem: { current: any; }; index: any; handlesort: () => void; dragItem: { current: any; }; })=>{
		drag.overItem.current = drag.index;
		if(drag.overItem.current === drag.index){
			drag.handlesort();
			drag.dragItem.current = drag.index
		}
	}
	const dragStart = (drag: { dragItem: { current: any; }; index: any; })=>{
		drag.dragItem.current = drag.index
	}
	const drag = (index:number) => {
		return{index:index,handlesort:handlesort,dragEnter:dragEnter,dragStart:dragStart,dragItem:dragItem,overItem:overItem}
	}
	const save = ()=>{
		dispatch(addSidecarAction({...sidecarConfigNewTempState}));
		dispatch(resetSidecarConfigNew());
		dispatch(setShowButtonProgramming(false));
		changesMade(false);
	}
	const closeButtonProgramming = ()=>{
		if(changes.current && sidecarConfigNewTempState.uuid){
			setSaveConfirmationDialog(true)
		}else{
			dispatch(setShowButtonProgramming(false))
		}
	}
	const changesMade = (value = true)=>{
		changes.current = value
	}
	const closeSaveConfirmationDialog = ()=>{
		setSaveConfirmationDialog(false); 
		changesMade(false);
		dispatch(setShowButtonProgramming(false)); 
		dispatch(resetSidecarConfigNew())
	}
	const [cancelHover, setCancelHover] = useState(false);
	return (
		showSaveConfirmationDialog ? 
		<>
			<GeneralPromptDialog  title="" actionBtnTxt="Save" onClick={save} onCancel={closeSaveConfirmationDialog} >
				Do you want to save your Sidecar changes?
			</GeneralPromptDialog>
		</>
		:
		<>
			<Backdrop onCancel={closeButtonProgramming}/>
			<div className={styles.buttonProgramming}>
				<h1 className={styles.topHeading}>
					<span>Button Programming</span>
					<span>
						<BtnAction
								btnType={"normal"}
								isDisabled={false}
								type="button"
								isActive={false}
								onMouseOut={() => {
									setCancelHover(false);
								}}
								onMouseOver={() => {
									setCancelHover(true);
								}}
								onClick={closeButtonProgramming}
								icon={<CloseIcon color={cancelHover ? "primary-default" : "icon-primary"} />} />
					</span>
				</h1>
				<div className={styles.btmSection}>
					<div>
						<h2>General</h2>
						<div className={styles.inputBox}>
							<label htmlFor="">Action Name</label>
							<input type="text" placeholder="Button 1" value={sidecarConfigNewTempState.name} onChange={(e) => {changesMade();dispatch(setSidecarConfigNewTempName(e.target.value))}} />
						</div>

						<div className={styles.inputBox}>
							<label htmlFor="">Watch Extension</label>
							<input type="text" placeholder=""  value={sidecarConfigNewTempState.extension} onChange={(e) => {changesMade();dispatch(setSidecarConfigNewTempExtension(e.target.value))}} />
						</div>
					</div>
					<div className={styles.actionsBox}>
						<h2>
							<span>Actions {sidecarConfigNewTempState.actionList.length>0?sidecarConfigNewTempState.actionList.length:""}</span>
							<span className={styles.blueIcon} onClick={()=>dispatch(setShowChooseActions(true))}>
								<PlusIcon/>
							</span>
						</h2>
						{
						sidecarConfigNewTempState.actionList?.length>0?
						<div className={styles.actionBox}>
							<div>
								{
									sidecarConfigNewTempState.actionList.map(
										(action, index)=>{
											switch(action.name){
												case "Dial": return <DialActionCard changesMade={changesMade} extension={action.number} drag={drag(index)} uuid={action.uuid}/>
												case "SendMessage": return <SendMessageActionCard changesMade={changesMade} fromNumber={action.fromNumber} toNumber={action.toNumber} message={action.message} drag={drag(index)} uuid={action.uuid}/>
												case "Pause": return< PauseActionCard changesMade={changesMade} sec={action.seconds} drag={drag(index)} uuid={action.uuid} />
												case "Transfer": return <TransferActionCard changesMade={changesMade} number={action.number} transferTypeValue={action.transferType} drag={drag(index)} uuid={action.uuid}/>
												case "Conference": return <ConferenceActionCard changesMade={changesMade} drag={drag(index)} uuid={action.uuid} numbers={action.numbers} />
												case "Merge": return <MergeActionCard changesMade={changesMade} drag={drag(index)} uuid={action.uuid}/>
												case "KeyPress": return <KeypressActionCard changesMade={changesMade} drag={drag(index)} keyPress={action.number} uuid={action.uuid} />
												case "Hangup": return <HangupActionCard changesMade={changesMade} drag={drag(index)} uuid={action.uuid} />
												case "Hold": return <HoldActionCard changesMade={changesMade} drag={drag(index)} uuid={action.uuid}/>
												case "ModifyMainStatus": return <MainStatusActionCard changesMade={changesMade} fromStatuses={action.fromStatuses} toStatus={action.toStatus} toggle={action.toggleMode} drag={drag(index)} uuid={action.uuid}/>
												case "ModifyAditionalStatus": return <AdditionalStatusActionCard changesMade={changesMade} fromStatuses={action.fromStatuses} toStatus={action.toStatus} toggle={action.toggleMode} drag={drag(index)} uuid={action.uuid}/>
											}
										}
									)
								}
							</div>
						</div>:
						<div className={styles.noAction}>
							<div>
								<h3>You don’t any actions here</h3>
								<p>Click on Add Action to add an action</p>
							</div>
						</div>
						}
					</div>
				</div>
				<div className={styles.btnBox}>
					<button 
						disabled={sidecarConfigNewTempState.actionList.length === 0 || sidecarConfigNewTempState.name?.length === 0 }
						className={`${sidecarConfigNewTempState.actionList.length === 0 || sidecarConfigNewTempState.name?.length === 0 ? {} : styles.activeBtn}`}
						onClick={save}
					>{sidecarConfigNewTempState.uuid?"Save Changes": "Save"}</button>
				</div>
			</div>
		</>
	);
};

export default ButtonProgramming;
