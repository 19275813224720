import { IIcon } from "constants/interfaces";

const ChevronRightIcon = ({ color }: IIcon) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<path
				d="M9.5 6L15.5 12L9.5 18"
				stroke={`var(--${color ? color : "icon-primary"})`}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default ChevronRightIcon;
