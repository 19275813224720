export const setBadge = async (count: number) => {
	if ("setAppBadge" in navigator) {
		try {
			await navigator.setAppBadge(count);
		} catch (error) {
			console.error("Failed to set badge:", error);
		}
	} else if ("setClientBadge" in navigator) {
		try {
			await navigator.setClientBadge(count);
		} catch (error) {
			console.error("Failed to set client badge:", error);
		}
	} else {
		console.warn("Badging API not supported.");
	}
};

export const clearBadge = async () => {
	if ("clearAppBadge" in navigator) {
		try {
			await navigator.clearAppBadge();
		} catch (error) {
			console.error("Failed to clear badge:", error);
		}
	} else if ("clearClientBadge" in navigator) {
		try {
			await navigator.clearClientBadge();
		} catch (error) {
			console.error("Failed to clear client badge:", error);
		}
	} else {
		console.warn("Badging API not supported.");
	}
};
