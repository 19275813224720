import React from "react";
import { RouteObject } from "react-router-dom";
import routePaths from "./../../constants/routes";
import ProtectedRoutes from "utils/ProtectedRoutes";
import Loader from "components/UI/Loader";

const Sidecar = React.lazy(() => import("./../../pages/Sidecar"));

export const sidecarRoutes: RouteObject[] = [
	{
		path: routePaths.SIDECAR.__PATH,
		element: (
			<ProtectedRoutes>
				<React.Suspense fallback={<Loader />}>
					<Sidecar />
				</React.Suspense>
			</ProtectedRoutes>
		),
	},
];
