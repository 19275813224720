// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActionSkeleton_card__tYLKa {
  position: relative;
  display: block;
  width: 100%;
  padding: 4px;
  gap: 4px;
}
.ActionSkeleton_card__tYLKa span {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Sidecar/ActionSkeleton/ActionSkeleton.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACG,cAAA;EACA,WAAA;EACA,YAAA;EACA,QAAA;AACJ;AAAC;EACC,WAAA;AAEF","sourcesContent":[".card {\n\tposition: relative;\n    display: block;\n    width: 100%;\n    padding: 4px;\n    gap: 4px;\n\tspan{\n\t\twidth: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `ActionSkeleton_card__tYLKa`
};
export default ___CSS_LOADER_EXPORT___;
