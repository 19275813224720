import styles from "./Sidecar.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import ChevronUpIcon from "components/UI/Icons/ChevronUp";
import {
	sidecarConfig,
	tabSelected,
	sidecarView,
	fullScreen,
	showButtonProgramming,
	showChooseActions,
	showAdditionalStatusDialogue,
	showConferenceDialogue,
	showDialDialogue,
	showKeypressDialogue,
	showMainStatusDialogue,
	showPauseDialogue,
	showSendMessageDialogue,
	showTransferDialogue,
} from "redux/sidecar/sidecarSelectors";
import SettingsIcon from "components/UI/Icons/Fills/Settings";
import { useLocation, useNavigate } from "react-router";
import { setTabSelected, setFullScreen, setSidecarView, removeSidecarAction } from "redux/sidecar/sidecarSlice";
import Queues from "components/Sidecar/Queues";
import Extension from "components/Sidecar/Extension";
import ActionsBlock from "components/Sidecar/ActionsBlock";
import NoActions from "components/Sidecar/NoActions";
import axios from "axios";
import Header from "components/Sidecar/Header";
import SidecarManage from "components/Sidecar/SidecarManage";
import { saveSidecarConfigToLocalStorage, sidecarUploadFromServer } from "utils/sidecar/sidecarXML";
import { compareCacheTimeOver } from "helpers";
import { useLazyGetSidecarConfigURLQuery } from "services/storage";
import GeneralPromptDialog from "components/Modal/GeneralPromptDialog";
import AdditionalStatusDialogue from "components/Sidecar/ActionsDialogue/AdditionalStatusDialogue";
import ConferenceDialogue from "components/Sidecar/ActionsDialogue/ConferenceDialogue";
import DialDialogue from "components/Sidecar/ActionsDialogue/DialDialogue";
import KeypressDialogue from "components/Sidecar/ActionsDialogue/KeypressDialogue";
import MainStatusDialogue from "components/Sidecar/ActionsDialogue/MainStatusDialogue";
import PauseDialogue from "components/Sidecar/ActionsDialogue/PauseDialogue";
import SendMessageDialogue from "components/Sidecar/ActionsDialogue/SendMessageDialogue";
import TransferDialogue from "components/Sidecar/ActionsDialogue/TransferDialogue";
import ButtonProgramming from "components/Sidecar/ButtonProgramming";
import ChooseActions from "components/Sidecar/ChooseActions";
import BtnAction from "components/UI/BtnAction";
import MiniActiveCallControl from "../MiniActiveCallControl";

const Sidecar = () => {
	const sidecarViewState = useSelector(sidecarView);
	const showButtonProgrammingState = useSelector(showButtonProgramming);
	const showChooseActionsState = useSelector(showChooseActions);
	const showPauseDialogueState = useSelector(showPauseDialogue);
	const showDialDialogueState = useSelector(showDialDialogue);
	const showMainStatusDialogueState = useSelector(showMainStatusDialogue);
	const showAdditionalStatusDialogueState = useSelector(showAdditionalStatusDialogue);
	const showSendMessageDialogueState = useSelector(showSendMessageDialogue);
	const showKeypressDialogueState = useSelector(showKeypressDialogue);
	const showTransferDialogueState = useSelector(showTransferDialogue);
	const showConferenceDialogueState = useSelector(showConferenceDialogue);
	const sidecarConfigList = useSelector(sidecarConfig);
	const [confLoadingFromServer, setConfLoadingFromServer] = useState(false);
	const [showDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState({ show: false, actionName: "" });
	const dispatch = useDispatch();
	const uuidDeleteBtn = useRef("");
	const sidecarApiCalled = useRef(false);
	const { userId, extAuth } = useSelector((state: any) => state.sip);

	const [getSidecarConfigURL] = useLazyGetSidecarConfigURLQuery();
	const deleteActionBtnHandler = () => {
		dispatch(removeSidecarAction(uuidDeleteBtn.current));
		setDeleteConfirmationDialog({ show: false, actionName: "" });
	};
	const deleteConfirmation = ({ actionName, uuid }: { actionName: string; uuid: string }) => {
		uuidDeleteBtn.current = uuid;
		setDeleteConfirmationDialog({ show: true, actionName: actionName });
	};
	const [search, setSearch] = useState("");
	const [settingHover, setSettingHover] = useState(false);

	const fullScreenState = useSelector(fullScreen);
	const selectedTab = useSelector(tabSelected);
	useEffect(() => {
		if (extAuth !== false) return;
		if (sidecarApiCalled.current) return;
		if (userId === undefined) return;
		sidecarApiCalled.current = true;
		if (sidecarConfigList.length !== 0) return;
		const fetchData = async () => {
			try {
				setConfLoadingFromServer(true);
				const { data } = await getSidecarConfigURL(userId);
				const url = data.url;
				// console.log(url);
				if (url) {
					//   console.log(data);
					const response = await axios.get(url, { responseType: "blob" });
					const blob = new Blob([response.data], { type: "application/xml" });
					const xmlString = await blob.text();
					sidecarUploadFromServer(xmlString);
					saveSidecarConfigToLocalStorage(xmlString);
					setConfLoadingFromServer(false);
				}
			} catch (err) {
				// handle error
				setConfLoadingFromServer(false);
			}
		};

		const sidecarLocal = localStorage?.getItem("sidecarConfig");

		if (sidecarLocal) {
			if (compareCacheTimeOver(JSON.parse(localStorage.getItem("next_fetch") || "{}")?.sidecar, 8)) {
				fetchData();
			} else {
				sidecarUploadFromServer(sidecarLocal);
			}
		} else {
			fetchData();
		}
	}, [userId]);
	const navigate = useNavigate();
	const pathname = useLocation().pathname;
	useEffect(() => {
		dispatch(setSidecarView(false));
	}, [pathname]);
	return pathname === "/dashboard" || sidecarViewState ? (
		<div className={`${styles.sidecar} ${sidecarViewState && styles.sidecar_active}`}>
			<div className={styles.dropdown}>
				<button
					onClick={() => {
						dispatch(setSidecarView(!sidecarViewState));
					}}
					className={styles.hiddenBtn}></button>
				<span>Sidecar</span>
				<div className={`${styles.chevron} ${sidecarViewState && styles.active}`}>
					<ChevronUpIcon />
				</div>
			</div>
			<div className={styles.sidecar_box}>
				<section className={styles.sidecarManage}>
					{fullScreenState === false ? (
						<>
							<Header setSearch={setSearch} search={search} />
							<SidecarManage deleteConfirmation={deleteConfirmation} search={search} />
						</>
					) : (
						<>
							<div className={styles.sidecarBar}>
								<div className={styles.miniActiveMobileScreen}>
									<MiniActiveCallControl />
								</div>
								<div className={styles.topSection}>
									<span className={styles.h1Span}>
										<h1>Sidecar</h1>
									</span>
									<div className={styles.containerSMCenter}>
										<div className={styles.miniActiveSmallScreen}>
											<MiniActiveCallControl />
										</div>
										<div className={styles.tabBox}>
											<span
												className={`${selectedTab === "extension" && styles.activeTab}`}
												onClick={() => {
													dispatch(setTabSelected("extension"));
												}}>
												Extension
											</span>
											<span
												className={`${selectedTab === "general" ? styles.activeTab : ""}`}
												onClick={() => {
													dispatch(setTabSelected("general"));
												}}>
												General
											</span>
											<span
												className={`${selectedTab === "queues" && styles.activeTab}`}
												onClick={() => {
													dispatch(setTabSelected("queues"));
												}}>
												Queues
											</span>
										</div>
									</div>
									<span className={styles.gear}>
										<div className={styles.miniActiveBigScreen}>
											<MiniActiveCallControl />
										</div>
										{
											selectedTab === "general" && (
												<BtnAction
													btnType={"normal"}
													isDisabled={false}
													type="button"
													isActive={false}
													onMouseOut={() => {
														setSettingHover(false);
													}}
													onMouseOver={() => {
														setSettingHover(true);
													}}
													onClick={() => {
														dispatch(setFullScreen(false));
													}}
													icon={<SettingsIcon color={settingHover ? "primary-default" : "icon-primary"} />}
												/>
											)

											// <span onClick={()=>{dispatch(setFullScreen(false));}}><SettingsIcon /></span>
										}
									</span>
								</div>
								<div className={styles.btmSection}>
									{selectedTab === "general" &&
										(sidecarConfigList.length > 0 || confLoadingFromServer ? (
											<ActionsBlock showList={false} loading={confLoadingFromServer} />
										) : (
											<NoActions />
										))}
									{selectedTab === "extension" && <Extension showList={false} />}
									{selectedTab === "queues" && (
										<>
											{/* <div className={styles.showList}>
												<Queues showList={true} />
											</div> */}
											<div className={styles.grid}>
												<Queues showList={false} />
											</div>
										</>
									)}
								</div>
							</div>
						</>
					)}
				</section>
				{/* { <SidecarSidebar />} */}

				{showButtonProgrammingState && <ButtonProgramming />}
				{showChooseActionsState && <ChooseActions />}
				{showPauseDialogueState.show && (
					<PauseDialogue
						uuid={showPauseDialogueState.uuid}
						secondsValue={showPauseDialogueState.secondsValue ? showPauseDialogueState.secondsValue : ""}
					/>
				)}
				{showDialDialogueState.show && (
					<DialDialogue
						uuid={showDialDialogueState.uuid}
						numberValue={showDialDialogueState.numberValue ? showDialDialogueState.numberValue : ""}
					/>
				)}
				{showConferenceDialogueState.show && (
					<ConferenceDialogue
						uuid={showConferenceDialogueState.uuid}
						numbers={showConferenceDialogueState.numbers ? showConferenceDialogueState.numbers : []}
					/>
				)}
				{showMainStatusDialogueState.show && (
					<MainStatusDialogue
						to={showMainStatusDialogueState.toValue}
						from={showMainStatusDialogueState.fromValue}
						uuid={showMainStatusDialogueState.uuid}
						toggleValue={showMainStatusDialogueState.toggleMode}
					/>
				)}
				{showAdditionalStatusDialogueState.show && (
					<AdditionalStatusDialogue
						to={showAdditionalStatusDialogueState.toValue}
						from={showAdditionalStatusDialogueState.fromValue}
						uuid={showAdditionalStatusDialogueState.uuid}
						toggleValue={showAdditionalStatusDialogueState.toggleMode}
					/>
				)}
				{showSendMessageDialogueState.show && (
					<SendMessageDialogue
						uuid={showSendMessageDialogueState.uuid}
						fromNumber={showSendMessageDialogueState.fromNumber}
						toNumber={showSendMessageDialogueState.toNumber}
						message={showSendMessageDialogueState.message}
					/>
				)}
				{showKeypressDialogueState.show && (
					<KeypressDialogue
						uuid={showKeypressDialogueState.uuid}
						numberValue={showKeypressDialogueState.numberValue ? showKeypressDialogueState.numberValue : ""}
					/>
				)}
				{showTransferDialogueState.show && (
					<TransferDialogue
						uuid={showTransferDialogueState.uuid}
						numberValue={showTransferDialogueState.numberValue ? showTransferDialogueState.numberValue : ""}
						transferTypeValue={
							showTransferDialogueState.transferTypeValue ? showTransferDialogueState.transferTypeValue : "0"
						}
					/>
				)}
				{showDeleteConfirmationDialog.show && (
					<GeneralPromptDialog
						type="warning"
						title="Delete Action"
						actionBtnTxt="Delete"
						onClick={deleteActionBtnHandler}
						onCancel={() => {
							setDeleteConfirmationDialog({ show: false, actionName: "" });
						}}>
						Are you sure that you want to delete <b>"{showDeleteConfirmationDialog.actionName}"</b> action from the
						button?
					</GeneralPromptDialog>
				)}
			</div>
		</div>
	) : null;
};
export default Sidecar;
