// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__M78kq {
  width: 100%;
  height: 54px;
  flex-shrink: 0;
  border-bottom: 1px solid var(--border-tertiary, #e3eaf2);
  /* Blur/Background Blur */
  -webkit-backdrop-filter: blur(16px);
          backdrop-filter: blur(16px);
  padding: 17px 24px;
  position: relative;
}
.Header_header__M78kq h1 {
  color: var(--text-primary, #1f2023);
  /* Subheadline/Bold */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: -0.5px;
}

.Header_searchComp__dVutC {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
}

.Header_profile__H30NP {
  position: absolute;
  top: 12px;
  right: 25px;
  color: var(--text-primary);
}
.Header_profile__H30NP button {
  border-radius: 5px;
  margin-top: unset;
  font-size: 0.75em;
}

@media only screen and (max-width: 800px) {
  .Header_header__M78kq {
    height: unset !important;
  }
  .Header_header__M78kq h1 {
    line-height: 100px;
  }
  .Header_searchComp__dVutC {
    position: relative !important;
    width: 100%;
    transform: none;
    left: unset;
    top: unset;
    margin-top: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Sidecar/Header/Header.module.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,YAAA;EACA,cAAA;EACA,wDAAA;EACA,yBAAA;EACA,mCAAA;UAAA,2BAAA;EACA,kBAAA;EACA,kBAAA;AACD;AAAC;EACC,mCAAA;EACA,qBAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;EACA,sBAAA;AAEF;;AAEA;EACC,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EAEA,UAAA;AAAD;;AAGA;EACC,kBAAA;EACA,SAAA;EACA,WAAA;EACA,0BAAA;AAAD;AACC;EACC,kBAAA;EACA,iBAAA;EACA,iBAAA;AACF;;AAYA;EACC;IACC,wBAAA;EATA;EAWA;IACC,kBAAA;EATD;EAaD;IACC,6BAAA;IACA,WAAA;IACA,eAAA;IACA,WAAA;IACA,UAAA;IACA,gBAAA;EAXA;AACF","sourcesContent":[".header {\n\twidth: 100%;\n\theight: 54px;\n\tflex-shrink: 0;\n\tborder-bottom: 1px solid var(--border-tertiary, #e3eaf2);\n\t/* Blur/Background Blur */\n\tbackdrop-filter: blur(16px);\n\tpadding: 17px 24px;\n\tposition: relative;\n\th1 {\n\t\tcolor: var(--text-primary, #1f2023);\n\t\t/* Subheadline/Bold */\n\t\tfont-family: Poppins;\n\t\tfont-size: 16px;\n\t\tfont-style: normal;\n\t\tfont-weight: 600;\n\t\tline-height: 20px; /* 125% */\n\t\tletter-spacing: -0.5px;\n\t}\n}\n\n.searchComp {\n\tposition: absolute;\n\ttop: 50%;\n\tleft: 50%;\n\ttransform: translate(-50%, -50%);\n\n\twidth: 40%;\n}\n\n.profile {\n\tposition: absolute;\n\ttop: 12px;\n\tright: 25px;\n\tcolor: var(--text-primary);\n\tbutton {\n\t\tborder-radius: 5px;\n\t\tmargin-top: unset;\n\t\tfont-size: 0.75em;\n\t}\n\n\t// path {\n\t// \tstroke: white;\n\t// }\n\n\t// span {\n\t// \tmargin-left: 5px;\n\t// \tcolor: white;\n\t// }\n}\n\n@media only screen and (max-width: 800px) {\n\t.header {\n\t\theight: unset !important;\n\n\t\th1 {\n\t\t\tline-height: 100px;\n\t\t}\n\t}\n\n\t.searchComp {\n\t\tposition: relative !important;\n\t\twidth: 100%;\n\t\ttransform: none;\n\t\tleft: unset;\n\t\ttop: unset;\n\t\tmargin-top: 10px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__M78kq`,
	"searchComp": `Header_searchComp__dVutC`,
	"profile": `Header_profile__H30NP`
};
export default ___CSS_LOADER_EXPORT___;
