import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./Sidebar.module.scss";
import routePaths from "./../../../constants/routes";
import SettingsIcon from "components/UI/Icons/Sidebar/Settings";
import VoicemailIcon from "components/UI/Icons/Sidebar/Voicemail";
import RecentsIcon from "components/UI/Icons/Sidebar/Recents";
import ChatIcon from "components/UI/Icons/Sidebar/Chat";
import ContactIcon from "components/UI/Icons/Sidebar/Contact";
import KeypadIcon from "components/UI/Icons/Sidebar/Keypad";
import SidecarIcon from "components/UI/Icons/Sidebar/Sidecar";
import MeetIcon from "components/UI/Icons/Sidebar/Meet";
import { useDispatch, useSelector } from "react-redux";
import Logo from "components/UI/Logo";
import { ClipLoader } from "react-spinners";
import { badgeCounter, loader } from "redux/common/commonSelectors";
import { unreadMessageCount } from "redux/chat/chatSelectors";
import { setUnreadMessageCount } from "redux/chat/chatSlice";
import FaxIcon from "components/UI/Icons/Sidebar/Fax";
import UserGroupIcon from "components/UI/Icons/Sidebar/UserGroup";
import { getCookie } from "typescript-cookie";
import { setShowSidecarSidebar, setSidecarView } from "redux/sidecar/sidecarSlice";
import { showSidecarSidebar, fullScreen, sidecarView } from "redux/sidecar/sidecarSelectors";
import { emptyFunction } from "utils";
import { useLazyGetVoicemailsQuery } from "services/voicemail";
import { unreadVoicemailCount } from "redux/voicemail/voicemailSelectors";
import { setUnreadVoicemailCount } from "redux/voicemail/voicemailSlice";
import { useLazyGetConversationListsQuery } from "services/chat";
import { setBadgeCounter } from "redux/common/commonSlice";
import { setBadge } from "utils/badge";

interface ISidebarLinks {
	path?: string;
	icon: React.ReactElement;
	unread: number;
	name: string;
	onClick?: () => any;
}

const Sidebar = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const showSidecarSidebarState = useSelector(showSidecarSidebar);
	const sidecarFullScreen = useSelector(fullScreen);
	const sidecarViewState = useSelector(sidecarView);
	const [getVoicemail, { data: voicemailData, isLoading: voicemailLoading, isFetching: voicemailFetching }] =
		useLazyGetVoicemailsQuery();
	const [getMessages, { data: messagesData, isLoading: messagesLoading, isFetching: messagesFetching }] =
		useLazyGetConversationListsQuery();
	const unreadVoicemail = useSelector(unreadVoicemailCount);
	const [sidebarTopLinks, setSidebarTopLinks] = useState<any>([]);

	// useEffect(() => {
	// 	if (showSidecarSidebarState === true) {
	// 		setIsCollapsed(false);
	// 	}
	// }, [showSidecarSidebarState]);

	const unreadCount = useSelector(unreadMessageCount);
	const mainLoader = useSelector(loader);
	// const { extAuth } = useSelector((state: any) => state.sip);

	const [isCollapsed, setIsCollapsed] = useState(false);
	const [tabHovered, setTabHovered] = useState("");

	let idToken = getCookie("id_token");
	const badgeNumber = useSelector(badgeCounter);

	const setTopSidebarMenu = () => {
		setSidebarTopLinks(
			!idToken
				? [
						{
							path: routePaths.DASHBOARD.ROUTE,
							icon: (
								<KeypadIcon
									color={`${
										tabHovered === "Keypad" || location.pathname === "/dashboard" ? "primary-default" : "icon-primary"
									}`}
								/>
							),
							name: "Keypad",
							unread: 0,
						},
						// {
						// 	path: routePaths.CONTACT.ROUTE,
						// 	icon: (
						// 		<ContactIcon
						// 			color={`${
						// 				tabHovered === "Contacts" || location.pathname === "/contact" ? "primary-default" : "icon-primary"
						// 			}`}
						// 		/>
						// 	),
						// 	name: "Contacts",
						// 	unread: 0,
						// },
						// {
						// 	path: routePaths.CHAT.ROUTE,
						// 	icon: (
						// 		<ChatIcon
						// 			color={`${
						// 				tabHovered === "Texting" || location.pathname === "/texting" ? "primary-default" : "icon-primary"
						// 			}`}
						// 		/>
						// 	),
						// 	name: "Texting",
						// 	unread: unreadCount,
						// },
						// {
						// 	path: routePaths.CALL_HISTORY.ROUTE,
						// 	icon: (
						// 		<RecentsIcon
						// 			color={`${
						// 				tabHovered === "Recent" || location.pathname === "/call-history" ? "primary-default" : "icon-primary"
						// 			}`}
						// 		/>
						// 	),
						// 	name: "Recent",
						// 	unread: 0,
						// },
						// {
						// 	path: routePaths.VOICEMAIL.ROUTE,
						// 	icon: (
						// 		<VoicemailIcon
						// 			color={`${
						// 				tabHovered === "Voicemail" || location.pathname === "/voicemail" ? "primary-default" : "icon-primary"
						// 			}`}
						// 		/>
						// 	),
						// 	name: "Voicemail",
						// 	unread: 0,
						// },
						// {
						// 	path: routePaths.FAX.ROUTE,
						// 	icon: (
						// 		<FaxIcon
						// 			color={`${tabHovered === "Fax" || location.pathname === "/fax" ? "primary-default" : "icon-primary"}`}
						// 		/>
						// 	),
						// 	name: "Fax",
						// 	unread: 0,
						// },
				  ]
				: [
						{
							path: routePaths.DASHBOARD.ROUTE,
							icon: (
								<KeypadIcon
									color={`${
										tabHovered === "Keypad" || location.pathname === "/dashboard" ? "primary-default" : "icon-primary"
									}`}
								/>
							),
							name: "Keypad",
							unread: 0,
						},
						{
							path: routePaths.CONTACT.ROUTE,
							icon: (
								<ContactIcon
									color={`${
										tabHovered === "Contacts" || location.pathname === "/contact" ? "primary-default" : "icon-primary"
									}`}
								/>
							),
							name: "Contacts",
							unread: 0,
						},
						{
							path: routePaths.CHAT.ROUTE,
							icon: (
								<ChatIcon
									color={`${
										tabHovered === "Texting" || location.pathname === "/texting" ? "primary-default" : "icon-primary"
									}`}
								/>
							),
							name: "Texting",
							unread: unreadCount,
						},

						{
							path: routePaths.CALL_HISTORY.ROUTE,
							icon: (
								<RecentsIcon
									color={`${
										tabHovered === "Recent" || location.pathname === "/call-history"
											? "primary-default"
											: "icon-primary"
									}`}
								/>
							),
							name: "Recent",
							unread: 0,
						},

						{
							path: routePaths.VOICEMAIL.ROUTE,
							icon: (
								<VoicemailIcon
									color={`${
										tabHovered === "Voicemail" || location.pathname === "/voicemail"
											? "primary-default"
											: "icon-primary"
									}`}
								/>
							),
							name: "Voicemail",
							unread: unreadVoicemail,
						},
						{
							path: routePaths.FAX.ROUTE,
							icon: (
								<FaxIcon
									color={`${tabHovered === "Fax" || location.pathname === "/fax" ? "primary-default" : "icon-primary"}`}
								/>
							),
							name: "Fax",
							unread: 0,
						},
						// {
						// 	path: routePaths.CONFERENCE.GROUPS.ROUTE,
						// 	icon: (
						// 		<UserGroupIcon
						// 			color={`${tabHovered === "Conference" || location.pathname === "/" ? "primary-default" : "icon-primary"}`}
						// 		/>
						// 	),
						// 	name: "Conference",
						// 	unread: 0,
						// },
				  ],
		);
	};

	useEffect(() => {
		const fetchVoicemails = async () => {
			await getVoicemail("per_page=1&per_page=99");
		};

		const fetchMessages = async () => {
			await getMessages("per_page=1&per_page=99&sort=last_activity");
		};

		fetchVoicemails();
		fetchMessages();

		setInterval(fetchVoicemails, 600000);
		setInterval(fetchMessages, 600000);
	}, []);

	useEffect(() => {
		if (voicemailData && !voicemailFetching && !voicemailLoading) {
			const unreadVoicemails = voicemailData?.filter((voicemail: any) => voicemail?.listened == false);
			if (unreadVoicemails?.length >= 0) dispatch(setUnreadVoicemailCount(unreadVoicemails?.length));

			if (unreadVoicemails?.length >= 0) {
				dispatch(setBadgeCounter(badgeNumber + unreadVoicemails?.length));
			}
		}
	}, [voicemailData, voicemailFetching, voicemailLoading]);

	useEffect(() => {
		if (messagesData && !messagesFetching && !messagesLoading) {
			let count = 0;

			for (var i = 0; i < messagesData?.length; ++i) {
				count += messagesData[i]?.unread_msg_count;
			}

			dispatch(setUnreadMessageCount(count));

			if (count >= 0) {
				dispatch(setBadgeCounter(badgeNumber + count));
			}
		}
	}, [messagesData, messagesFetching, messagesLoading]);

	useEffect(() => {
		if (badgeNumber >= 0) {
			setBadge(badgeNumber);
		}
	}, [badgeNumber]);

	useEffect(() => {
		setTopSidebarMenu();
	}, [unreadVoicemail, unreadCount]);

	const sidebarBtmLinks: ISidebarLinks[] = !idToken
		? [
				// { path: routePaths.SIDECAR.ROUTE, icon: <SidecarIcon />, name: "Sidecar", unread: 0 },
				// { path: routePaths.MEET.ROUTE, icon: <MeetIcon />, name: "RingPlan Meet", unread: 0 },
				{
					path: routePaths.SETTINGS.ROUTE,
					icon: (
						<SettingsIcon
							color={`${
								tabHovered === "Settings" || location.pathname === "/settings" ? "primary-default" : "icon-primary"
							}`}
						/>
					),
					name: "Settings",
					unread: 0,
				},
		  ]
		: [
				{
					icon: (
						<SidecarIcon
							color={`${tabHovered === "Sidecar" || sidecarViewState ? "primary-default" : "icon-primary"}`}
						/>
					),
					name: "Sidecar",
					unread: 0,
					onClick: (e: { stopPropagation: () => void }) => {
						e.stopPropagation();
						dispatch(setSidecarView(!sidecarViewState));
					},
				},
				{ path: routePaths.MEET.ROUTE, icon: <MeetIcon />, name: "RingPlan Meet", unread: 0 },
				{
					path: routePaths.SETTINGS.ROUTE,
					icon: (
						<SettingsIcon
							color={`${
								tabHovered === "Settings" || location.pathname === "/settings" ? "primary-default" : "icon-primary"
							}`}
						/>
					),
					name: "Settings",
					unread: 0,
				},
		  ];

	const unreadHandler = (path?: string) => {
		if (path === location.pathname) {
			dispatch(setSidecarView(false));
		}
		if (path === "/texting") {
			dispatch(setUnreadMessageCount(0));
		}
	};

	return (
		<section
			className={styles.sidebarBox}
			style={{ width: `${!isCollapsed ? "64px" : "calc(100vw - 15px)"}` }}
			onClick={(j) => {
				if (sidecarViewState === false) {
					setIsCollapsed(!isCollapsed);
				}
			}}>
			{mainLoader ? (
				<div className={styles.spinnerMain}>
					<ClipLoader size={13} color="var(--text-secondary)" />
					<p>Loading...</p>
				</div>
			) : null}
			<section className={styles.sidebar} style={{ width: `${!isCollapsed ? "64px" : "280px"}` }}>
				<div
					className={styles.sidebar_logoIcon}
					onClick={() => {
						if (sidecarViewState === false) {
							setIsCollapsed(!isCollapsed);
						}
					}}>
					<Logo type="ri" />
				</div>
				<div className={styles.sidebar_tabBox}>
					<div className={styles.sidebar_topTab}>
						{sidebarTopLinks.map((link: ISidebarLinks) => (
							<NavLink
								to={link.path ? link.path : {}}
								className={({ isActive }: { isActive: boolean }) =>
									[styles.sidebar_tab, isActive ? styles.active_tab : ""].join(" ")
								}
								key={link.name}
								onClick={
									link.onClick
										? link.onClick
										: (e) => {
												e.stopPropagation();
												location.pathname === link.path && setIsCollapsed(!isCollapsed);
												unreadHandler(link?.path);
										  }
								}
								onMouseOver={() => {
									setTabHovered(link?.name);
								}}
								onMouseOut={() => {
									setTabHovered("");
								}}>
								{/* <span className={`${!isCollapsed && link.unread > 0 ? styles.sidebar_icon : ""}`}>{link.icon}</span> */}
								<span>{link.icon}</span>
								{!isCollapsed && link.unread ? (
									<span className={styles.unreadCount}>
										{link.unread < 100 ? link.unread : 99}
										{link.unread >= 100 ? <span>+</span> : null}
									</span>
								) : null}
								{isCollapsed && (
									<span className={`${styles.sidebar_tabExpanded}`}>
										<span>{link.name}</span>
										{link.unread > 0 && <span className={styles.sidebar_unreadMsg}>{link.unread}</span>}
									</span>
								)}
							</NavLink>
						))}
					</div>
					<div className={styles.sidebar_topTab}>
						{sidebarBtmLinks.map((link: ISidebarLinks) => {
							// if (localStorage.getItem("extAuth") === "true" && link.name === "RingPlan Meet") return null;

							return (
								<NavLink
									to={link.path ? link.path : {}}
									className={({ isActive }: { isActive: boolean }) =>
										[
											styles.sidebar_tab,
											(isActive && link.path) || link?.name?.toLowerCase() === window.location?.pathname?.slice(1)
												? styles.active_tab
												: null,
										].join(" ")
									}
									key={link.name}
									onClick={
										link.onClick
											? link.onClick
											: (e) => {
													e.stopPropagation();
													location.pathname === link.path && setIsCollapsed(!isCollapsed);
													unreadHandler(link?.path);
											  }
									}
									onMouseOver={() => {
										setTabHovered(link?.name);
									}}
									onMouseOut={() => {
										setTabHovered("");
									}}>
									<span className={` ${!isCollapsed && link.unread > 0 ? styles.sidebar_icon : ""}`}>{link.icon}</span>
									{isCollapsed && (
										<span className={`${styles.sidebar_tabExpanded}`}>
											<span>{link.name}</span>
											{link.unread > 0 && <span className={styles.sidebar_unreadMsg}>{link.unread}</span>}
										</span>
									)}
								</NavLink>
							);
						})}
					</div>
				</div>
			</section>
		</section>
	);
};

export default Sidebar;
