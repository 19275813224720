import { apiService } from "./api";

export const statusService = apiService.injectEndpoints({
	endpoints: (build) => ({
		setStatus: build.query({
			query: (data) => ({
				method: "PATCH",
				url: `/status/v2/current-user`,
				data: data,
			}),
		}),
		setStatusUser: build.query({
			query: (payload) => ({
				method: "PATCH",
				url: `/status/v2/users/${payload.userID}`,
				data: payload.data,
			}),
		}),
		getStatus: build.query({
			query: (uuid) => ({
				method: "GET",
				url: `/statuses/v2/users?user_id=${uuid}`,
			}),
		}),
		getStatusAll: build.query({
			query: () => ({
				method: "GET",
				url: `/statuses/v2/users`,
			}),
		}),
	}),
});

export const { useLazySetStatusQuery, useLazySetStatusUserQuery, useLazyGetStatusQuery, useGetStatusAllQuery } =
	statusService;
