import { createSlice } from "@reduxjs/toolkit";
import { V2_0 } from "./updates";

const initialCommonState = {
	modal: false,
	playPause: false,
	notification: {
		type: "",
		msg: "",
	},
	simpleNotification: "",
	loader: false,
	extChange: false,
	sessionOut: false,
	updates: V2_0,
	version: "2.1",
	refreshVersion: false,
	contactName: "",
	badgeCounter: 0,
	showWarningModal: false,
	showSurveyModal: false,
};

const commonSlice = createSlice({
	name: "common",
	initialState: initialCommonState,
	reducers: {
		toggleModal(state) {
			state.modal = !state.modal;
		},
		togglePlayPause(state, action) {
			state.playPause = action.payload;
		},
		setNotification(state, action) {
			state.notification = action.payload;
		},
		setSimpleNotification(state, action) {
			state.simpleNotification = action.payload;
		},
		setLoader(state, action) {
			state.loader = action.payload;
		},
		setExtChange(state) {
			state.extChange = !state.extChange;
		},
		setSessionOut(state, action) {
			var cookies = document.cookie.split(";");

			for (var i = 0; i < cookies.length; i++) {
				var cookie = cookies[i];
				var eqPos = cookie.indexOf("=");
				var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
				document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
			}

			state.sessionOut = action.payload;
		},
		setUpdates(state, action) {
			state.updates = action.payload;
		},
		setVersion(state, action) {
			state.version = action.payload;
		},
		setRefreshVersion(state, action) {
			state.refreshVersion = action.payload;
		},
		setContactName(state, action) {
			state.contactName = action.payload;
		},
		setBadgeCounter(state, action) {
			state.badgeCounter = action.payload;
		},
		setShowWarningModal(state, action) {
			state.showWarningModal = action.payload;
		},
		setShowSurveyModal(state, action) {
			state.showSurveyModal = action.payload;
		},
	},
});

export const {
	toggleModal,
	togglePlayPause,
	setNotification,
	setSimpleNotification,
	setLoader,
	setExtChange,
	setSessionOut,
	setUpdates,
	setRefreshVersion,
	setContactName,
	setBadgeCounter,
	setShowWarningModal,
	setShowSurveyModal,
} = commonSlice.actions;

export default commonSlice.reducer;
