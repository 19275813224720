const HolidayIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path
				d="M14.5047 3.42854C14.5047 4.6909 13.4814 5.71425 12.219 5.71425C10.9566 5.71425 9.93329 4.6909 9.93329 3.42854C9.93329 2.16617 10.9566 1.14282 12.219 1.14282C13.4814 1.14282 14.5047 2.16617 14.5047 3.42854Z"
				fill="#6C7A8B"
			/>
			<path
				d="M0.572166 16C0.147376 16 -0.128907 15.5529 0.0610646 15.173L5.42219 4.45074C5.63277 4.02957 6.23381 4.02957 6.44439 4.45074L9.93329 11.4285L11.1365 9.02217C11.3471 8.601 11.9481 8.601 12.1587 9.02217L15.2341 15.173C15.4241 15.5529 15.1478 16 14.723 16H0.572166Z"
				fill="#6C7A8B"
			/>
		</svg>
	);
};

export default HolidayIcon;
