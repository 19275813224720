import Backdrop from "components/UI/Backdrop";
import { useState } from "react";
import styles from "./MainStatusDialogue.module.scss";
import StatusIcon from "components/UI/Icons/Sidecar/Status";
import CloseIcon from "components/UI/Icons/Close";
import OnOffSwitch from "components/UI/OnOffSwitch";
import PlusIcon from "components/UI/Icons/Sidecar/Plus";
import ChooseStatus from "components/Sidecar/ChooseStatus";
import Available from "components/Sidecar/Status/Available";
import DNDThisPhone from "components/Sidecar/Status/DNDThisPhone";
import DND from "components/Sidecar/Status/DND";
import Away from "components/Sidecar/Status/Away";
import { useDispatch } from "react-redux";
import { addSidecarConfigNewTempActionList, setShowChooseActions, setShowMainStatusDialogue } from "redux/sidecar/sidecarSlice";

const MainStatusDialogue = ({to=[],from=[], uuid = "", toggleValue="0"} : {to?:Array<string>, from?:Array<string>, uuid:string, toggleValue?:string}) => {
	const dispatch = useDispatch()
	const [toForm, setToForm] = useState(false);
	const [fromForm, setFromForm] = useState(false);
	const [toVal, setToVal] = useState(to);
	const [fromVal, setFromVal] = useState(from);
	const [toggle, setToggle] = useState(toggleValue);
	const back = ()=>{
		dispatch(setShowMainStatusDialogue({uuid:"", show:false}));
	}
	const calcFromVal = (value:number)=>{
		const newVal = [...fromVal]
		if(toggle==="1"){
			newVal.length = 0;
		}
		switch (value){
			case 1://available
				if(newVal.indexOf("available") === -1){
					newVal.push("available")
				}
				break;
			case -1://available
				if(newVal.indexOf("available") !== -1){
					newVal.splice(newVal.indexOf("available"), 1);
				}
				break; 
			case 2://away
				if(newVal.indexOf("away") === -1){
					newVal.push("away")
				}
				break;
			case -2://away
				if(newVal.indexOf("away") !== -1){
					newVal.splice(newVal.indexOf("away"), 1);
				}
				break;
			case 3://dnd
				if(newVal.indexOf("do_not_disturb") === -1){
					newVal.push("do_not_disturb")
				}
				break;
			case -3://dnd
				if(newVal.indexOf("do_not_disturb") !== -1){
					newVal.splice(newVal.indexOf("do_not_disturb"), 1);
				}
				break; 
			case 4://dndthis
				if(newVal.indexOf("do_not_disturb_local") === -1){
					newVal.push("do_not_disturb_local")
				}
				break;
			case -4://dndthis
				if(newVal.indexOf("do_not_disturb_local") !== -1){
					newVal.splice(newVal.indexOf("do_not_disturb_local"), 1);
				}
				break; 
		}
		setFromVal(newVal);
	}
	const calcToVal = (value:number)=>{
		const newVal = []
		switch (value){
			case 1://available
				newVal.push("available")
				break;
			case 2://away
				newVal.push("away")
				break;
			case 3://dnd
				newVal.push("do_not_disturb")
				break;
			case 4://dndthis
				newVal.push("do_not_disturb_local")
				break; 
		}
		setToVal(newVal);
	}
	return (
		<>
			<Backdrop onCancel={()=>{dispatch(setShowMainStatusDialogue({uuid:"", show:false}));if(!uuid)dispatch(setShowChooseActions(true))}}/>
			<div className={styles.dialogueBox} onClick={()=>{setToForm(false);setFromForm(false)}}>
				<h1 className={styles.topHeading}>
					<span>
						<StatusIcon />
						<span>Main Status</span>
					</span>

					<span className={styles.closeBox} onClick={()=>{dispatch(setShowMainStatusDialogue({uuid:"", show:false}));if(!uuid)dispatch(setShowChooseActions(true))}}>
						<CloseIcon />
					</span>
				</h1>

				<div className={styles.toggleBox}>
					<span>Use as a toggle</span>
					<OnOffSwitch onClick={()=>{setToggle(toggle==="0"?"1":"0");setFromVal([])}} checked={toggle==="0"?false:true} />
				</div>

				<div className={styles.fromStatus}>
					<span>From Status:</span>
					<span>
						{fromVal.indexOf("available") !== -1 && <Available onClick={()=>{calcFromVal(-1)}}/>}
						{fromVal.indexOf("away") !== -1 && <Away onClick={()=>{calcFromVal(-2)}}/>}
						{fromVal.indexOf("do_not_disturb") !== -1 && <DND onClick={()=>{calcFromVal(-3)}}/>}
						{fromVal.indexOf("do_not_disturb_local") !== -1 && <DNDThisPhone onClick={()=>{calcFromVal(-4)}}/>}
						<span className={styles.plusIcon} onClick={(e)=>{setToForm(false);setFromForm(true);e.stopPropagation();}}>
							<PlusIcon />
						</span>
					</span>
					{fromForm && <ChooseStatus onClick={calcFromVal}/>}
				</div>

				<div className={styles.toStatus}>
					<span>To Status:</span>
					<span>
						{toVal.indexOf("available") !== -1 && <Available onClick={()=>{calcToVal(-1)}}/>}
						{toVal.indexOf("away") !== -1 && <Away onClick={()=>{calcToVal(-2)}}/>}
						{toVal.indexOf("do_not_disturb") !== -1 && <DND onClick={()=>{calcToVal(-3)}}/>}
						{toVal.indexOf("do_not_disturb_local") !== -1 && <DNDThisPhone onClick={()=>{calcToVal(-4)}}/>}
						<span className={styles.plusIcon} onClick={(e)=>{setToForm(true);setFromForm(false);e.stopPropagation();}}>
							<PlusIcon />
						</span>
					</span>
					{toForm && <ChooseStatus onClick={calcToVal}/>}
				</div>

				<div className={styles.btnBox}>
					<button  
						disabled={ toVal.length === 0 || fromVal.length === 0 ? true:false}
						className={`${toVal.length === 0 || fromVal.length === 0 ? styles.disabled:{}}`}
						onClick={()=>{dispatch(addSidecarConfigNewTempActionList({name:"ModifyMainStatus", uuid:uuid, fromStatuses:fromVal, toStatus:toVal, toggleMode:toggle }));back();} }>{uuid ? "Update":"Add Action"}</button>
				</div>
			</div>
		</>
	);
};

export default MainStatusDialogue;
