import { IIcon } from "constants/interfaces";

const ChevronUpIcon = ({ color }: IIcon) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<path
				d="M6 14L12 8L18 14"
				stroke={`var(--${color ? color : "icon-primary"})`}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default ChevronUpIcon;
