import styles from "./Login.module.scss";
import { useNavigate } from "react-router-dom";
import loginSideImage from "./../../assets/images/bg/login.png";
import Input from "./../../components/UI/Forms/Input";
import Button from "./../../components/UI/Forms/Button";
import Logo from "./../../components/UI/Logo";
import ErrorMessage from "components/UI/ErrorMessage";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../components/UI/Loader";
import sip from "../../lib/sip";
import { store } from "../../redux/store";
import { getGoBackUrl, getLoginUrl } from "config/env.config";
import { useTheme } from "hooks/useTheme";
import OpenApp from "components/shared/OpenApp";
import ChevronUpIcon from "components/UI/Icons/Lines/ChevronUp";
import ChevronDownIcon from "components/UI/Icons/Lines/ChevronDown";
import MailIcon from "components/UI/Icons/Lines/Mail";
import LockIcon from "components/UI/Icons/Lines/Lock";
import ExtensionIcon from "components/UI/Icons/Extension";
import BtnLarge from "components/UI/BtnLarge";
import { useLazyGetLoginSSOQuery } from "services/account";
import { deleteCookieDynamically } from "utils";
import ChevronLeftIcon from "components/UI/Icons/Lines/ChevronLeft";
import ChevronRightIcon from "components/UI/Icons/Lines/ChevronRight";
import Cookies from "js-cookie";

const Login = () => {
	const navigate = useNavigate();
	const theme = useTheme();
	const [userEmail, setUserEmail] = useState("");
	const [userEmailError, setUserEmailError] = useState("");
	const [getLoginSSO] = useLazyGetLoginSSOQuery();
	const { authMessage, authLoading } = useSelector((state: any) => state.sip);
	const [acctList, setAcctList] = useState<any[]>([]);

	interface FormData {
		extension?: string;
		server?: string;
		secret?: string;
		extensionErrorMsg?: string;
		serverErrorMsg?: string;
		secretErrorMsg?: string;
	}

	const [withExt, setWithExt] = useState(false);
	const [form, setForm] = useState<FormData>({ extension: "", secret: "", server: "" });

	// useEffect(() => {
	// 	const subdomains = [window.location.hostname, ".beta.ringplan.com", ".ringplan.com"];
	// 	const paths = ["/", "/callback", "/auth/login", "/auth"];

	// 	subdomains.forEach((domain) => {
	// 		paths.forEach((path) => {
	// 			Cookies.remove("id_token", { path: path, domain: domain });
	// 			Cookies.remove("refresh_token", { path: path, domain: domain });
	// 		});
	// 	});
	// }, [acctList]);

	const onContinueWithRingplan = async () => {
		if (userEmail) {
			const getLoginSSOFun = async () => {
				const { data, error } = await getLoginSSO(userEmail);

				if (error) {
					sip.logout(false);
					store.dispatch({ type: "sip/authMessage", payload: "" });
					// navigate("/dashboard"); // Remove the extra parentheses
					const loginUrl = getLoginUrl();
					const backUrl = getGoBackUrl();
					window.location.href = `${loginUrl}/login?back=${backUrl}`;
					// if (error?.response?.status == 404) {
					// 	setUserEmailError("Login URL for the provided email not found.");
					// } else if (error?.response?.status == 400) {
					// 	setUserEmailError("Invalid email address provided.");
					// } else {
					// 	setUserEmailError("Something went wrong.");
					// }
				}

				if (data) {
					if (data?.length > 1) {
						setAcctList(data);
					} else {
						sip.logout(false);
						store.dispatch({ type: "sip/authMessage", payload: "" });
						const backUrl = getGoBackUrl();
						// window.location.href = data?.login_url;

						if (data?.length) {
							window.location.href = `${data?.[0]?.login_url}?back=${backUrl}`;
						} else {
							window.location.href = `${data?.login_url}?back=${backUrl}`;
						}
					}
				}
			};

			getLoginSSOFun();
		} else {
			sip.logout(false);
			store.dispatch({ type: "sip/authMessage", payload: "" });
			// navigate("/dashboard"); // Remove the extra parentheses
			const loginUrl = getLoginUrl();
			const backUrl = getGoBackUrl();
			deleteCookieDynamically("id_token");
			deleteCookieDynamically("refresh_token");
			window.location.href = `${loginUrl}/login?back=${backUrl}`;
		}
	};

	const handleOpenAcctLink = (url: string) => {
		sip.logout(false);
		store.dispatch({ type: "sip/authMessage", payload: "" });
		const backUrl = getGoBackUrl();
		window.location.href = `${url}?back=${backUrl}`;
	};

	const loginWithExtension = () => {
		sip.logout(false);
		store.dispatch({ type: "sip/extAuth", payload: true });

		localStorage.setItem("extAuth", "true");

		form?.extension === ""
			? setForm((prevState) => {
					return { ...prevState, extensionErrorMsg: "This field is required" };
			  })
			: setForm((prevState) => {
					return { ...prevState, extensionErrorMsg: "" };
			  });
		form?.server === ""
			? setForm((prevState) => {
					return { ...prevState, serverErrorMsg: "This field is required" };
			  })
			: setForm((prevState) => {
					return { ...prevState, serverErrorMsg: "" };
			  });
		form?.secret === ""
			? setForm((prevState) => {
					return { ...prevState, secretErrorMsg: "This field is required" };
			  })
			: setForm((prevState) => {
					return { ...prevState, secretErrorMsg: "" };
			  });
		form.extension && form.server && form.secret && sip.CreateUserAgent(form.extension, form.secret, form.server);
		// sip.CreateUserAgent("", "", "")
	};

	useEffect(() => {
		if (authMessage === "continue") {
			navigate("/dashboard");
		}
	}, [authMessage]);

	const login = () => {
		return (
			<section className={`${styles.login} ${theme}`}>
				{/* {suggestPortraitOnMobileModalShow && <SuggestPortraitOnMobileModal />} */}
				<div className={styles.login_image}>
					<img src={loginSideImage} alt="" />
				</div>
				<div className={styles.login_textBox}>
					<div className={styles.login_text}>
						<OpenApp />
						<h1 className={styles.login_join}>Join the RingPlan Team</h1>
						<p className={styles.login_doMore}>Do more with Ringplan.</p>

						{!acctList?.length ? (
							<div className={styles.continueRingplan}>
								<div className={styles.userEmail}>
									<label htmlFor="user_email">
										Email
										{/* <span>optional</span> */}
									</label>
									<input
										type="text"
										id="user_email"
										placeholder="user@company.com"
										onChange={(e) => setUserEmail(e.target.value)}
									/>
									{userEmailError ? <p className={styles.userEmailError}>{userEmailError}</p> : null}
								</div>
								<Button onClick={onContinueWithRingplan} icon={<Logo type="ri" />} border>
									<span>Continue with Ringplan</span>
								</Button>
							</div>
						) : (
							<div className={styles.mulAcct}>
								<p>
									<button onClick={() => setAcctList([])}>
										<ChevronLeftIcon />
									</button>
									We found multiple accounts associated with your email choose below
								</p>

								<div>
									{acctList?.map((acct) => (
										<Button onClick={() => handleOpenAcctLink(acct?.login_url)} border>
											<div>
												<h3>{String(acct?.name)?.toUpperCase()}</h3>
												<span>{acct?.email_domain}</span>
											</div>

											<ChevronRightIcon />
										</Button>
									))}
								</div>
							</div>
						)}

						<div className={styles.or}>or</div>

						{/* <button className={styles.login_withExtension}> */}
						<button className={styles.loginWithExtension_toggle} onClick={() => setWithExt(!withExt)}>
							Login with extension and secret
							{withExt ? (
								<div className={styles.loginWithExtension_chevronUp}>
									<ChevronUpIcon />
								</div>
							) : (
								<div className={styles.loginWithExtension_chevronDown}>
									<ChevronDownIcon />
								</div>
							)}
						</button>

						<div className={styles.loginWithExtension}>
							{withExt ? (
								<>
									<div className={styles.loginWithExtension_inputs}>
										<Input
											type="text"
											errorMsg={form?.extensionErrorMsg}
											value={form?.extension}
											onChange={(e) => setForm({ ...form, extension: e.target.value })}
											placeholder="Enter extension here..."
											required
											icon={<ExtensionIcon />}
										/>
										<Input
											type="text"
											errorMsg={form?.serverErrorMsg}
											value={form?.server}
											onChange={(e) => setForm({ ...form, server: e.target.value })}
											placeholder="zraytechnoloDoobh.ringplan.com"
											required
											icon={<MailIcon />}
										/>
										<Input
											type="password"
											errorMsg={form?.secretErrorMsg}
											value={form?.secret}
											onChange={(e) => setForm({ ...form, secret: e.target.value })}
											placeholder="Enter secret code here..."
											required
											icon={<LockIcon />}
										/>
										{authMessage && authMessage !== "continue" ? <ErrorMessage msg={authMessage} /> : ""}
									</div>

									<div className={styles.btn}>
										<BtnLarge
											btnType="primary"
											isDanger={false}
											isDisabled={false}
											isLoading={false}
											onClick={loginWithExtension}
											btnText="Sign In"
										/>
									</div>

									{/* <Button fill onClick={loginWithExtension}>
										Sign In
									</Button> */}
								</>
							) : null}
						</div>

						{/* <p className={`caption_1 ${styles.login_forgotPassword}`}>
							Forgot your password? <span>Click here</span>
						</p> */}
					</div>
				</div>
			</section>
		);
	};
	return authLoading ? <Loader /> : login();
};

export default Login;
