import React from "react";
import Backdrop from "components/UI/Backdrop";
import styles from "./WarningModal.module.scss";
import WarningIcon from "components/UI/Icons/Warning";
import { useDispatch } from "react-redux";
import { setShowWarningModal } from "redux/common/commonSlice";

interface IWarningModal {
	msg: string;
}

const WarningModal: React.FC<IWarningModal> = ({ msg }) => {
	const dispatch = useDispatch();

	return (
		<div className={styles.warningModal}>
			<div>
				<Backdrop onCancel={() => dispatch(setShowWarningModal(false))} />
				<section className={styles.warningMiddle}>
					<div className={styles.warningHeader}>
						<WarningIcon />
						<h4>Warning</h4>
					</div>
					<p>{msg}</p>

					<div className={styles.btn}>
						<button onClick={() => dispatch(setShowWarningModal(false))}>Close</button>
					</div>
				</section>
			</div>
		</div>
	);
};

export default WarningModal;
