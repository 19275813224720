import React from "react";
import styles from "../Status.module.scss";

const DNDThisPhone = ({onClick}:{onClick?:()=>void}) => {
	return (
		<span className={styles.statusIcon}>
			{/* <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8.39484 1.45098C8.47293 1.49445 8.53421 1.56281 8.56891 1.64517C8.60362 1.72753 8.60974 1.81914 8.5863 1.90538L7.5239 5.80031H11.4002C11.4781 5.80032 11.5544 5.8231 11.6196 5.86586C11.6847 5.90862 11.736 5.96949 11.767 6.04099C11.7981 6.1125 11.8076 6.19151 11.7943 6.26833C11.7811 6.34515 11.7457 6.41642 11.6924 6.47338L6.09244 12.4734C6.03144 12.5389 5.95025 12.582 5.86184 12.5959C5.77343 12.6098 5.68291 12.5937 5.60476 12.55C5.52662 12.5064 5.46536 12.4379 5.43078 12.3553C5.3962 12.2728 5.39031 12.181 5.41404 12.0947L6.47644 8.20031H2.60017C2.52222 8.20031 2.44596 8.17753 2.38079 8.13477C2.31561 8.09201 2.26434 8.03114 2.23329 7.95964C2.20224 7.88813 2.19277 7.80912 2.20602 7.7323C2.21928 7.65548 2.25469 7.58421 2.3079 7.52725L7.9079 1.52725C7.9689 1.46199 8.04998 1.41903 8.13823 1.40521C8.22648 1.39139 8.31681 1.4075 8.39484 1.45098Z"
					fill="white"
				/>
			</svg> */}
			<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle cx="15.9999" cy="16.0004" r="10.6" fill="#EE3939" stroke="white" strokeWidth="2" />
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M17.3948 10.451C17.4729 10.4944 17.5342 10.5628 17.5689 10.6452C17.6036 10.7275 17.6097 10.8191 17.5863 10.9054L16.5239 14.8003H20.4002C20.4781 14.8003 20.5544 14.8231 20.6196 14.8659C20.6847 14.9086 20.736 14.9695 20.767 15.041C20.7981 15.1125 20.8076 15.1915 20.7943 15.2683C20.7811 15.3451 20.7457 15.4164 20.6924 15.4734L15.0924 21.4734C15.0314 21.5389 14.9502 21.582 14.8618 21.5959C14.7734 21.6098 14.6829 21.5937 14.6048 21.55C14.5266 21.5064 14.4654 21.4379 14.4308 21.3553C14.3962 21.2728 14.3903 21.181 14.414 21.0947L15.4764 17.2003H11.6002C11.5222 17.2003 11.446 17.1775 11.3808 17.1348C11.3156 17.092 11.2643 17.0311 11.2333 16.9596C11.2022 16.8881 11.1928 16.8091 11.206 16.7323C11.2193 16.6555 11.2547 16.5842 11.3079 16.5272L16.9079 10.5272C16.9689 10.462 17.05 10.419 17.1382 10.4052C17.2265 10.3914 17.3168 10.4075 17.3948 10.451Z"
					fill="white"
				/>
			</svg>

			<span onClick={()=>{if(onClick){onClick()}}}>
				<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 6 6" fill="none">
					<g clipPath="url(#clip0_1235_136112)">
						<path
							d="M1.125 1.125L4.875 4.875M4.875 1.125L1.125 4.875"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
						/>
					</g>
					<defs>
						<clipPath id="clip0_1235_136112">
							<rect width="24" height="24" fill="white" />
						</clipPath>
					</defs>
				</svg>
			</span>
		</span>
	);
};

export default DNDThisPhone;
