import { apiService, apiStorageService } from "./api";

export const callService = apiService.injectEndpoints({
	endpoints: (build) => ({
		getCallHistories: build.query({
			query: (queries) => ({
				method: "GET",
				url: `/cdrs/v3/cdrs?${queries}&sort=-starttime&extension=${localStorage.getItem("ext_user_id")}`,
			}),
		}),
	}),
});

export const callStorageService = apiStorageService.injectEndpoints({
	endpoints: (build) => ({
		getCallHistoryFile: build.query({
			query: (userID) => ({
				method: "GET",
				url: `/resources?unique_name=recent_${userID}`,
			}),
		}),
		createCallHistoryFile: build.query({
			query: (data) => ({
				method: "POST",
				url: `/resources`,
				data,
			}),
		}),
		updateCallHistoryFile: build.query({
			query: (data) => ({
				method: "PUT",
				url: `/resources`,
				data,
			}),
		}),
	}),
});

export const { useLazyGetCallHistoriesQuery } = callService;

export const { useLazyCreateCallHistoryFileQuery, useLazyGetCallHistoryFileQuery, useLazyUpdateCallHistoryFileQuery } =
	callStorageService;
