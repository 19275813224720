import { useState } from "react";
import styles from "./MainStatus.module.scss";
import DeleteIcon from "components/UI/Icons/Delete";
import StatusIcon from "components/UI/Icons/Sidecar/Status";
import AvailableIcon from "components/UI/Icons/Status/Available";
import DNDIcon from "components/UI/Icons/Status/DND";
import DNDThisDeviceIcon from "components/UI/Icons/Status/DNDThisDevice";
import AwayIcon from "components/UI/Icons/Status/Away";
import ReloadIcon from "components/UI/Icons/ReloadIcon";
import EditIcon from "components/UI/Icons/Lines/Edit";
import { useDispatch } from "react-redux";
import { removeSidecarConfigNewTempActionList, setShowMainStatusDialogue } from "redux/sidecar/sidecarSlice";
import ArrowRight from "components/UI/Icons/ArrowRight";
import MoveUpDown from "components/UI/Icons/Sidecar/MoveUpDown";

const MainStatusActionCard = ({fromStatuses, toStatus, toggle, uuid, drag, changesMade}:{fromStatuses:Array<string>, toStatus:Array<string>, toggle: string, uuid:string, drag?:any, changesMade:()=>void}) => {
	const [editHover, setEditHover] = useState(false);
	const [deleteHover, setDeleteHover] = useState(false);
	const dispatch = useDispatch();
	return (
		<div className={styles.card} draggable onDragEnd={drag?.handlesort}  onDragEnter={()=>{drag?.dragEnter(drag)}} onDragStart={()=>{drag?.dragStart(drag)}}> 
			<p>
				{drag && <MoveUpDown />}
				<StatusIcon />
				<span>Main Status</span>
			</p>
			<p>
				<span className={styles.extension}>
					<span className={styles.status}>
						{fromStatuses.indexOf("available") !== -1 && <span><AvailableIcon /></span>}
						{fromStatuses.indexOf("away") !== -1 && <span><AwayIcon /></span>}
						{fromStatuses.indexOf("do_not_disturb") !== -1 && <span><DNDIcon /></span>}
						{fromStatuses.indexOf("do_not_disturb_local") !== -1 && <span><DNDThisDeviceIcon /></span>}
					</span>
					<span>
						{toggle === "1"? < ReloadIcon />:<ArrowRight />}
					</span>
					<span className={styles.status}>
						{toStatus.indexOf("available") !== -1 && <span><AvailableIcon /></span>}
						{toStatus.indexOf("away") !== -1 && <span><AwayIcon /></span>}
						{toStatus.indexOf("do_not_disturb") !== -1 && <span><DNDIcon /></span>}
						{toStatus.indexOf("do_not_disturb_local") !== -1 && <span><DNDThisDeviceIcon /></span>}
					</span>
				</span>
				{uuid &&
				<>
					<span className={styles.actionButton} onClick={()=>{changesMade(); dispatch(setShowMainStatusDialogue({uuid:`${uuid}`, show:true, toggleMode:`${toggle}`, fromValue:fromStatuses, toValue:toStatus }))}} onMouseOver={() => {setEditHover(true);}} onMouseOut={() => {setEditHover(false);}}><EditIcon color={editHover?"primary-default" : "icon-primary"}/></span>
					<span className={styles.actionButton} onClick={()=>{changesMade(); dispatch(removeSidecarConfigNewTempActionList(uuid))}} onMouseOver={() => {setDeleteHover(true);}} onMouseOut={() => {setDeleteHover(false);}}><DeleteIcon color={deleteHover?"support-danger-default" : "icon-primary"} /></span>
				</>}
				
			</p>
		</div>
	);
};

export default MainStatusActionCard;
