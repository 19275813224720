import React, { useEffect, useState } from "react";
import Backdrop from "components/UI/Backdrop";
import styles from "./SurveyModal.module.scss";
import StarIcon from "components/UI/Icons/Star";
import { useDispatch } from "react-redux";
import { setShowSurveyModal } from "redux/common/commonSlice";
import { Link } from "react-router-dom";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import CheckIcon from "components/UI/Icons/ChatIcons/Check";

// const videoQualityMetrics = ["Distorted speech", "Electronic feedback", "Background noise", "Muffled speech", "Echo"];
// const audioQualityMetrics = ["Frozen video", "Pixelate video", "Blurry image", "Poor color", "Dark video"];

const videoQualityMetrics = ["Distorted speech", "Not Working"];
const audioQualityMetrics = ["Frozen video", "Not Good"];

interface ISurveyModal {
	name: string;
	number: string;
}

const SurveyModal: React.FC<ISurveyModal> = ({ name, number }) => {
	const dispatch = useDispatch();
	// const [surveyData, setSurveyData] = useState<{
	// 	stars?: number;
	// 	audioQuality?: string[];
	// 	videoQuality?: string[];
	// 	additionalNote?: string;
	// }>({
	// 	audioQuality: [],
	// 	videoQuality: [],
	// 	additionalNote: "",
	// });
	const [surveyData, setSurveyData] = useState<{
		stars?: number;
		audioQuality?: string;
		videoQuality?: string;
		additionalNote?: string;
	}>({
		audioQuality: "",
		videoQuality: "",
		additionalNote: "",
	});
	const [loading, setLoading] = useState(false);
	const [surveyCompleted, setSurveyCompleted] = useState(false);
	const [timer, setTimer] = useState(5);

	useEffect(() => {
		if (timer > 0) {
			const timerFunc = setTimeout(() => {
				setTimer((prevTimer) => prevTimer - 1);
			}, 1000);

			return () => clearTimeout(timerFunc);
		} else {
			if (
				!surveyData?.stars &&
				!surveyData?.additionalNote?.length &&
				!surveyData?.videoQuality?.length &&
				!surveyData?.additionalNote
			) {
				dispatch(setShowSurveyModal(false));
			}
		}
	}, [timer]);

	// const handleCheckboxChange = (type: "audioQuality" | "videoQuality", metric: string, checked: boolean) => {
	// 	setSurveyData((prevData) => {
	// 		const updatedMetrics = checked
	// 			? [...(prevData[type] || []), metric]
	// 			: (prevData[type] || []).filter((item) => item !== metric);

	// 		return {
	// 			...prevData,
	// 			[type]: updatedMetrics,
	// 		};
	// 	});
	// };

	const handleSubmitSurvey = () => {
		setLoading(true);
		const userData = JSON.parse(String(localStorage.getItem("user_data")));
		const postSurveyData = {
			note: `${surveyData?.additionalNote} ---- Contact Info =  ${number}${
				name ? ` | ${name}` : ""
			} ---- User Info = email: ${userData?.email} | user id: ${userData?.id} | company id: ${userData.company_id}`,
			// partner: "Test Deep",
			call_quality: `${surveyData?.stars}`,
			audio_issues: `${surveyData?.audioQuality}`,
			video_issues: `${surveyData?.videoQuality}`,
		};
		axios
			.post("https://api.chillwireless.com/v1/survey", postSurveyData, {
				headers: {
					token: "97874a697e6b08624dd57afb24613dcc288a11b1e10670310a6b1306ef09c202",
				},
			})
			.then(() => {
				setLoading(false);
				setSurveyCompleted(true);

				setTimeout(() => {
					setSurveyCompleted(false);
					dispatch(setShowSurveyModal(false));
				}, 2000);
			})
			.catch((error) => {
				axios.post("https://api.chillwireless.com/v1/survey", postSurveyData, {
					headers: {
						token: "97874a697e6b08624dd57afb24613dcc288a11b1e10670310a6b1306ef09c202",
					},
				});
				setLoading(false);
				setSurveyCompleted(false);
				dispatch(setShowSurveyModal(false));
			});
	};

	return (
		<div className={styles.surveyModal}>
			<span>
				<Backdrop onCancel={() => dispatch(setShowSurveyModal(false))} />
			</span>
			<div>
				<section className={styles.surveyMiddle}>
					<div className={styles.surveyHeader}>
						<div>
							<StarIcon />
							<h4>Quality Survey</h4>
						</div>

						{timer == 0 ||
						surveyData?.stars ||
						surveyData?.additionalNote?.length ||
						surveyData?.videoQuality?.length ||
						surveyData?.additionalNote ? null : (
							<p className={styles.surveyTimer}>Survey closes in {timer} seconds</p>
						)}
					</div>

					{surveyCompleted ? (
						<div className={`${styles.surveyBody} ${styles.surveyCompleted}`}>
							<div className={styles.surveyCheck}>
								<CheckIcon />
							</div>

							<h3>Survey Submitted</h3>
							<p>Thank you for filling out the survey.</p>
						</div>
					) : (
						<div className={styles.surveyBody}>
							<div className={styles.cardProfile}>
								<div className={styles.cardProfile_circle}>
									<span className={styles.profile}>{name?.slice(0, 2)}</span>
								</div>

								<h4>{name}</h4>
								<p>{number}</p>
							</div>

							<div className={styles.surveyMain}>
								<p>How was the call quality?</p>

								<div className={styles.surveyStars}>
									<button onClick={() => setSurveyData((prevSurveyData) => ({ ...prevSurveyData, stars: 5 }))}>
										<StarIcon {...(surveyData?.stars == 5 ? { fill: "gold", stroke: "gold" } : {})} />
									</button>
									<button onClick={() => setSurveyData((prevSurveyData) => ({ ...prevSurveyData, stars: 4 }))}>
										<StarIcon
											{...(surveyData?.stars == 4 || surveyData?.stars == 5 ? { fill: "gold", stroke: "gold" } : {})}
										/>
									</button>
									<button onClick={() => setSurveyData((prevSurveyData) => ({ ...prevSurveyData, stars: 3 }))}>
										<StarIcon
											{...(surveyData?.stars == 3 || surveyData?.stars == 4 || surveyData?.stars == 5
												? { fill: "gold", stroke: "gold" }
												: {})}
										/>
									</button>
									<button onClick={() => setSurveyData((prevSurveyData) => ({ ...prevSurveyData, stars: 2 }))}>
										<StarIcon
											{...(surveyData?.stars == 2 ||
											surveyData?.stars == 3 ||
											surveyData?.stars == 4 ||
											surveyData?.stars == 5
												? { fill: "gold", stroke: "gold" }
												: {})}
										/>
									</button>
									<button onClick={() => setSurveyData((prevSurveyData) => ({ ...prevSurveyData, stars: 1 }))}>
										<StarIcon
											{...(surveyData?.stars == 1 ||
											surveyData?.stars == 2 ||
											surveyData?.stars == 3 ||
											surveyData?.stars == 4 ||
											surveyData?.stars == 5
												? { fill: "gold", stroke: "gold" }
												: {})}
										/>
									</button>
								</div>

								{surveyData?.stars && surveyData?.stars !== 5 ? (
									<>
										<div className={styles.issues}>
											<div className={styles.issuesSec}>
												<h5>Audio Issues</h5>
												{audioQualityMetrics?.map((metric: any, idx: number) => (
													<div>
														<input
															type="checkbox"
															name="metric"
															id={`audio-${metric}`}
															// checked={surveyData.audioQuality?.includes(metric) || false}
															// onChange={(e) => handleCheckboxChange("audioQuality", metric, e.target.checked)}

															checked={surveyData.audioQuality?.includes(`${idx + 1}`) || false}
															onChange={(e) =>
																setSurveyData((prevSurveyData) => ({
																	...prevSurveyData,
																	audioQuality: surveyData?.audioQuality + `${idx + 1}`,
																}))
															}
														/>
														<label htmlFor={`audio-${metric}`}>{metric}</label>
													</div>
												))}
											</div>

											<div className={styles.issuesSec}>
												<h5>Video Issues</h5>
												{videoQualityMetrics?.map((metric: any, idx: number) => (
													<div>
														<input
															type="checkbox"
															name="metric"
															id={`video-${metric}`}
															// checked={surveyData.videoQuality?.includes(metric) || false}
															// onChange={(e) => handleCheckboxChange("videoQuality", metric, e.target.checked)}

															checked={surveyData.videoQuality?.includes(`${idx + 1}`) || false}
															onChange={(e) =>
																setSurveyData((prevSurveyData) => ({
																	...prevSurveyData,
																	videoQuality: surveyData?.videoQuality + `${idx + 1}`,
																}))
															}
														/>
														<label htmlFor={`video-${metric}`}>{metric}</label>
													</div>
												))}
											</div>
										</div>

										<div className={styles.additional}>
											<textarea
												name=""
												id=""
												placeholder="Additional note..."
												onChange={(e) =>
													setSurveyData((prevSurveyData) => ({ ...prevSurveyData, additionalNote: e.target.value }))
												}></textarea>
											<Link to="#">Privacy Statement</Link>
										</div>
									</>
								) : null}
							</div>
						</div>
					)}

					<div className={styles.btn}>
						<button onClick={() => dispatch(setShowSurveyModal(false))}>Close</button>
						{surveyCompleted || !surveyData?.stars ? null : (
							<button onClick={handleSubmitSurvey} className={styles.submit}>
								{loading ? (
									<div style={{ padding: "2.5px 15px", marginBottom: "-5px" }}>
										<ClipLoader size={15} color="white" />
									</div>
								) : (
									"Submit"
								)}
							</button>
						)}
					</div>
				</section>
			</div>
		</div>
	);
};

export default SurveyModal;
