import { IIcon } from "constants/interfaces";

const EditIcon = ({ color }: IIcon) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<g clipPath="url(#clip0_3615_22166)">
				<path
					d="M7.33301 2.66714H2.66634C2.31272 2.66714 1.97358 2.80762 1.72353 3.05767C1.47348 3.30772 1.33301 3.64685 1.33301 4.00048V13.3338C1.33301 13.6874 1.47348 14.0266 1.72353 14.2766C1.97358 14.5267 2.31272 14.6671 2.66634 14.6671H11.9997C12.3533 14.6671 12.6924 14.5267 12.9425 14.2766C13.1925 14.0266 13.333 13.6874 13.333 13.3338V8.66714M12.333 1.66714C12.5982 1.40193 12.9579 1.25293 13.333 1.25293C13.7081 1.25293 14.0678 1.40193 14.333 1.66714C14.5982 1.93236 14.7472 2.29207 14.7472 2.66714C14.7472 3.04222 14.5982 3.40193 14.333 3.66714L7.99967 10.0005L5.33301 10.6671L5.99967 8.00048L12.333 1.66714Z"
					stroke={`var(--${color ? color : "icon-primary"})`}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_3615_22166">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default EditIcon;
