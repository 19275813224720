import React from "react";

const ReloadIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
			<path
				d="M13.3522 7.78922H17.5122L14.8613 5.13672C14.0079 4.28328 12.9448 3.66955 11.779 3.35722C10.6132 3.0449 9.38567 3.04499 8.21989 3.35749C7.0541 3.66998 5.99113 4.28387 5.13783 5.13744C4.28452 5.99101 3.67096 7.05417 3.35883 8.22005M2.48716 16.3692V12.2092M2.48716 12.2092H6.64716M2.48716 12.2092L5.13716 14.8617C5.99059 15.7152 7.05366 16.3289 8.21949 16.6412C9.38531 16.9535 10.6128 16.9534 11.7786 16.641C12.9444 16.3285 14.0074 15.7146 14.8607 14.861C15.714 14.0074 16.3275 12.9443 16.6397 11.7784M17.5122 3.62922V7.78755"
				stroke="#9298A0"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default ReloadIcon;
