// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchBar_search__WGUHc {
  position: relative;
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100%;
  height: 32px;
}
.SearchBar_search__WGUHc input {
  background: var(--background-tertiary);
  padding: 7px 12px 7px 32px;
  flex: 1 0;
  border-radius: 8px;
  border: 1px solid var(--border-secondary);
  width: 100%;
  height: 32px;
  color: var(--text-secondary);
  transition: border 0.3s;
}
.SearchBar_search__WGUHc input:hover {
  border: 1px solid var(--border-hover, #1480e1);
}
.SearchBar_search__WGUHc svg {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/SearchBar/SearchBar.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;EAEA,WAAA;EACA,YAAA;AAAD;AAEC;EACC,sCAAA;EACA,0BAAA;EACA,SAAA;EACA,kBAAA;EACA,yCAAA;EACA,WAAA;EACA,YAAA;EACA,4BAAA;EAEA,uBAAA;AADF;AAGE;EACC,8CAAA;AADH;AAKC;EACC,kBAAA;EACA,QAAA;EACA,SAAA;EACA,2BAAA;EAEA,aAAA;EACA,uBAAA;EACA,mBAAA;EAEA,WAAA;EACA,YAAA;AALF","sourcesContent":[".search {\n\tposition: relative;\n\tdisplay: flex;\n\tgap: 5px;\n\talign-items: center;\n\n\twidth: 100%;\n\theight: 32px;\n\n\t& input {\n\t\tbackground: var(--background-tertiary);\n\t\tpadding: 7px 12px 7px 32px;\n\t\tflex: 1 0 0;\n\t\tborder-radius: 8px;\n\t\tborder: 1px solid var(--border-secondary);\n\t\twidth: 100%;\n\t\theight: 32px;\n\t\tcolor: var(--text-secondary);\n\n\t\ttransition: border 0.3s;\n\n\t\t&:hover {\n\t\t\tborder: 1px solid var(--border-hover, #1480e1);\n\t\t}\n\t}\n\n\t& svg {\n\t\tposition: absolute;\n\t\ttop: 50%;\n\t\tleft: 8px;\n\t\ttransform: translateY(-50%);\n\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\n\t\twidth: 16px;\n\t\theight: 16px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": `SearchBar_search__WGUHc`
};
export default ___CSS_LOADER_EXPORT___;
