import React from "react";

const CalenderIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path d="M8 8H4.57143V11.4286H8V8Z" fill="#944AF5" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.57143 0H2.28571V2.28571C1.02335 2.28571 0 3.30906 0 4.57143V13.7143C0 14.9767 1.02335 16 2.28571 16H13.7143C14.9767 16 16 14.9767 16 13.7143V4.57143C16 3.30906 14.9767 2.28571 13.7143 2.28571V0H11.4286V2.28571H4.57143V0ZM13.7143 5.71429H2.28571V13.7143H13.7143V5.71429Z"
				fill="#944AF5"
			/>
		</svg>
	);
};

export default CalenderIcon;
