// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalMessage_modalMessage__29ebf {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ModalMessage_modalMessage_backdrop__BLMFK {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.4;
  z-index: 99;
}
.ModalMessage_modalMessage_center__cxmSp {
  position: relative;
  width: 400px;
  padding: 30px 0;
  background: var(--background-tertiary);
  border-radius: 5px;
  z-index: 199;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ModalMessage_modalMessage_center__cxmSp h1 {
  font-size: 1.4em;
  color: var(--text-primary);
}
.ModalMessage_modalMessage_center__cxmSp p {
  margin-top: 5px;
  color: var(--text-secondary);
}

.ModalMessage_actionButton__1i6T\\+ {
  padding: 10px 60px;
  background: var(--default-primary);
  margin-top: 25px;
  color: white;
  border-radius: 3px;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/ModalMessage/ModalMessage.module.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACD;AACC;EACC,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;AACF;AAEC;EACC,kBAAA;EACA,YAAA;EACA,eAAA;EACA,sCAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AAAF;AAEE;EACC,gBAAA;EACA,0BAAA;AAAH;AAGE;EACC,eAAA;EACA,4BAAA;AADH;;AAMA;EACC,kBAAA;EACA,kCAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;AAHD","sourcesContent":[".modalMessage {\n\tposition: fixed;\n\ttop: 0;\n\tleft: 0;\n\twidth: 100%;\n\theight: 100%;\n\tz-index: 999;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\n\t&_backdrop {\n\t\tposition: absolute;\n\t\ttop: 0;\n\t\tleft: 0;\n\t\twidth: 100%;\n\t\theight: 100%;\n\t\tbackground: black;\n\t\topacity: 0.4;\n\t\tz-index: 99;\n\t}\n\n\t&_center {\n\t\tposition: relative;\n\t\twidth: 400px;\n\t\tpadding: 30px 0;\n\t\tbackground: var(--background-tertiary);\n\t\tborder-radius: 5px;\n\t\tz-index: 199;\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\n\t\th1 {\n\t\t\tfont-size: 1.4em;\n\t\t\tcolor: var(--text-primary);\n\t\t}\n\n\t\tp {\n\t\t\tmargin-top: 5px;\n\t\t\tcolor: var(--text-secondary);\n\t\t}\n\t}\n}\n\n.actionButton {\n\tpadding: 10px 60px;\n\tbackground: var(--default-primary);\n\tmargin-top: 25px;\n\tcolor: white;\n\tborder-radius: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalMessage": `ModalMessage_modalMessage__29ebf`,
	"modalMessage_backdrop": `ModalMessage_modalMessage_backdrop__BLMFK`,
	"modalMessage_center": `ModalMessage_modalMessage_center__cxmSp`,
	"actionButton": `ModalMessage_actionButton__1i6T+`
};
export default ___CSS_LOADER_EXPORT___;
