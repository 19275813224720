// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Status_statusIcon__9z\\+NO {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 1px solid var(--border-tertiary, #e3eaf2);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.Status_statusIcon__9z\\+NO span {
  display: flex;
  width: 14px;
  height: 14px;
  padding: 3px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 23px;
  border: 1px solid var(--background-primary, #fff);
  background: var(--primary-default, #0c6dc7);
  position: absolute;
  top: -5px;
  right: -2px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Sidecar/Status/Status.module.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,YAAA;EACA,cAAA;EAEA,mBAAA;EACA,iDAAA;EAEA,aAAA;EACA,uBAAA;EACA,mBAAA;EAEA,kBAAA;AAFD;AAIC;EACC,aAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EAEA,mBAAA;EACA,iDAAA;EACA,2CAAA;EAEA,kBAAA;EACA,SAAA;EACA,WAAA;EAGA,eAAA;AANF","sourcesContent":[".statusIcon {\n\twidth: 32px;\n\theight: 32px;\n\tflex-shrink: 0;\n\n\tborder-radius: 32px;\n\tborder: 1px solid var(--border-tertiary, #e3eaf2);\n\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\n\tposition: relative;\n\n\tspan {\n\t\tdisplay: flex;\n\t\twidth: 14px;\n\t\theight: 14px;\n\t\tpadding: 3px;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\tflex-shrink: 0;\n\n\t\tborder-radius: 23px;\n\t\tborder: 1px solid var(--background-primary, #fff);\n\t\tbackground: var(--primary-default, #0c6dc7);\n\n\t\tposition: absolute;\n\t\ttop: -5px;\n\t\tright: -2px;\n\t\t// transform: translate(-50%, -50%);\n\n\t\tcursor: pointer;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusIcon": `Status_statusIcon__9z+NO`
};
export default ___CSS_LOADER_EXPORT___;
