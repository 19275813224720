import { IIcon } from "constants/interfaces";

const PlayerPauseIcon = ({ color }: IIcon) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M14.0001 3.2749C8.07665 3.2749 3.27515 8.0764 3.27515 13.9999C3.27515 19.9234 8.07665 24.7249 14.0001 24.7249C19.9236 24.7249 24.7251 19.9234 24.7251 13.9999C24.7251 8.0764 19.9236 3.2749 14.0001 3.2749ZM9.60015 10.6999C9.60015 10.0924 10.0926 9.5999 10.7001 9.5999H11.8001C12.4077 9.5999 12.9001 10.0924 12.9001 10.6999V17.2999C12.9001 17.9074 12.4077 18.3999 11.8001 18.3999H10.7001C10.0926 18.3999 9.60015 17.9074 9.60015 17.2999V10.6999ZM15.1001 10.6999C15.1001 10.0924 15.5926 9.5999 16.2001 9.5999H17.3001C17.9077 9.5999 18.4001 10.0924 18.4001 10.6999V17.2999C18.4001 17.9074 17.9077 18.3999 17.3001 18.3999H16.2001C15.5926 18.3999 15.1001 17.9074 15.1001 17.2999V10.6999Z"
				fill={`var(--${color ? color : "icon-primary"})`}
			/>
		</svg>
	);
};

export default PlayerPauseIcon;
