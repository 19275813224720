import React from "react";

interface IStarIcon {
	fill?: string;
	stroke?: string;
}

const StarIcon: React.FC<IStarIcon> = ({ fill, stroke }) => (
	<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="line / star">
			<path
				id="Vector"
				d="M5.73995 1.74961C5.7611 1.69802 5.79711 1.65389 5.84341 1.62284C5.88971 1.59178 5.9442 1.5752 5.99995 1.5752C6.0557 1.5752 6.11019 1.59178 6.15649 1.62284C6.20279 1.65389 6.23881 1.69802 6.25995 1.74961L7.32245 4.30511C7.34234 4.35292 7.37504 4.39433 7.41694 4.42476C7.45884 4.4552 7.50833 4.47348 7.55995 4.47761L10.319 4.69861C10.5685 4.71861 10.6695 5.03011 10.4795 5.19261L8.37745 6.99361C8.33818 7.0272 8.30892 7.07096 8.29286 7.12008C8.27681 7.16921 8.27459 7.2218 8.28645 7.27211L8.92895 9.96461C8.94187 10.0186 8.93848 10.0753 8.91923 10.1274C8.89997 10.1795 8.86571 10.2247 8.82077 10.2573C8.77583 10.2899 8.72222 10.3085 8.66673 10.3107C8.61123 10.3129 8.55632 10.2986 8.50895 10.2696L6.14645 8.82711C6.10232 8.80021 6.05164 8.78598 5.99995 8.78598C5.94827 8.78598 5.89759 8.80021 5.85345 8.82711L3.49095 10.2701C3.44358 10.2991 3.38868 10.3134 3.33318 10.3112C3.27768 10.309 3.22408 10.2904 3.17914 10.2578C3.1342 10.2252 3.09993 10.18 3.08068 10.1279C3.06142 10.0758 3.05804 10.0191 3.07095 9.96511L3.71345 7.27211C3.72537 7.22181 3.72318 7.16919 3.70712 7.12006C3.69107 7.07092 3.66177 7.02717 3.62245 6.99361L1.52045 5.19261C1.47836 5.15641 1.44791 5.10858 1.43293 5.05512C1.41795 5.00167 1.41911 4.94498 1.43626 4.89218C1.45342 4.83938 1.4858 4.79283 1.52934 4.75839C1.57287 4.72395 1.62562 4.70315 1.68095 4.69861L4.43995 4.47761C4.49158 4.47348 4.54106 4.4552 4.58297 4.42476C4.62487 4.39433 4.65756 4.35292 4.67745 4.30511L5.73995 1.74961Z"
				stroke={stroke || "#5C6168"}
				fill={fill || ""}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
	</svg>
);

export default StarIcon;
