import { useState, useEffect } from "react";

const useIsAppInstalled = () => {
	const [isInstalled, setIsInstalled] = useState(false);

	useEffect(() => {
		const checkInstalled = () => {
			if (window.matchMedia("(display-mode: standalone)").matches) {
				setIsInstalled(true);
			} else {
				setIsInstalled(false);
			}
		};

		checkInstalled();

		window.addEventListener("resize", checkInstalled);

		return () => {
			window.removeEventListener("resize", checkInstalled);
		};
	}, []);

	return isInstalled;
};

export default useIsAppInstalled;
