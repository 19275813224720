import React, { useState } from "react";
import styles from "./DialDialogue.module.scss";
import Backdrop from "components/UI/Backdrop";
import CloseIcon from "components/UI/Icons/Close";
import DialIcon from "components/UI/Icons/Sidecar/Dial";
import ContactBookIcon from "components/UI/Icons/ContactBook";
import { useDispatch } from "react-redux";
import { addSidecarConfigNewTempActionList, setShowChooseActions, setShowDialDialogue } from "redux/sidecar/sidecarSlice";
import GeneralContactDialog from "components/Modal/GeneralContactDialog";

const DialDialogue = ({uuid = "", numberValue=""}) => {
	const dispatch = useDispatch()
	const [number, setNumber] = useState(numberValue);
	const [showSelectContact, setShowSelectContact] = useState(false);
	const back = ()=>{
		dispatch(setShowDialDialogue({uuid:"", show:false}));
	}
	return (
		!showSelectContact?
		<>
			<Backdrop onCancel={ ()=>{dispatch(setShowDialDialogue({uuid:"", show:false}));if(!uuid)dispatch(setShowChooseActions(true))}}/>
			<div className={styles.dialogueBox}>
				<h1 className={styles.topHeading}>
					<span>
						<DialIcon />
						<span>Dial</span>
					</span>

					<span className={styles.closeBox} onClick={()=>{dispatch(setShowDialDialogue({uuid:"", show:false}));if(!uuid)dispatch(setShowChooseActions(true))}}>
						<CloseIcon />
					</span>
				</h1>
				<div className={styles.inputBox}>
					<label htmlFor="">Number</label>
					<input type="text" placeholder="" value={number} onChange={(e) => {setNumber(e.target.value);}}/>
					<span className={styles.contactIcon} onClick={()=>{setShowSelectContact(true)}}>
						<ContactBookIcon />
					</span>
				</div>

				<div className={styles.btnBox}>
					<button 
						disabled={number === "" ? true:false}
						className={`${number === "" ? styles.disabled:{}}`}
						onClick={()=>{dispatch(addSidecarConfigNewTempActionList({name:"Dial", uuid:uuid, number:number===""?"0":number })); back()} }>{uuid ? "Update":"Add Action"}</button>
				</div>
			</div>
		</>:<GeneralContactDialog setNumber={setNumber} setShowSelectContact={setShowSelectContact} />
	);
};

export default DialDialogue;
