import { IIcon } from "constants/interfaces";

const CallerReadyIcon  = ({ color }: IIcon) => {
	return (
		<svg opacity="0.8" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M10.9997 8.46005V9.96005C11.0003 10.0993 10.9718 10.2371 10.916 10.3647C10.8602 10.4923 10.7784 10.6068 10.6758 10.701C10.5732 10.7951 10.452 10.8668 10.3201 10.9114C10.1882 10.956 10.0484 10.9726 9.90972 10.96C8.37114 10.7929 6.89322 10.2671 5.59472 9.42505C4.38664 8.65738 3.36239 7.63313 2.59472 6.42505C1.74971 5.12065 1.22384 3.63555 1.05972 2.09005C1.04723 1.95178 1.06366 1.81243 1.10797 1.68086C1.15229 1.54929 1.22351 1.42839 1.31711 1.32586C1.4107 1.22332 1.52462 1.1414 1.65162 1.08531C1.77861 1.02922 1.91589 1.00018 2.05472 1.00005H3.55472C3.79738 0.99766 4.03262 1.08359 4.2166 1.24181C4.40059 1.40004 4.52076 1.61977 4.55472 1.86005C4.61803 2.34008 4.73545 2.81141 4.90472 3.26505C4.97199 3.44401 4.98655 3.63851 4.94668 3.82549C4.9068 4.01247 4.81415 4.1841 4.67972 4.32005L4.04472 4.95505C4.7565 6.20682 5.79295 7.24327 7.04472 7.95505L7.67972 7.32005C7.81567 7.18562 7.9873 7.09297 8.17428 7.0531C8.36126 7.01322 8.55576 7.02778 8.73472 7.09505C9.18836 7.26432 9.65969 7.38174 10.1397 7.44505C10.3826 7.47931 10.6044 7.60165 10.763 7.7888C10.9216 7.97594 11.0058 8.21484 10.9997 8.46005Z"  fill={color ? color : "#576A72" }/>
		</svg>
	);
};

export default CallerReadyIcon ;
