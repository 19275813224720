import React from "react";

const Status2Icon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
			<g clipPath="url(#clip0_2744_4168)">
				<path
					d="M16.4999 8.24999V8.99999C16.4923 10.5137 16.0269 11.9897 15.1647 13.234C14.3025 14.4782 13.0841 15.4325 11.6694 15.9713C10.2548 16.5101 8.71024 16.6082 7.23881 16.2527C5.76738 15.8972 4.43795 15.1048 3.4253 13.9796C2.41265 12.8545 1.76417 11.4492 1.56513 9.9486C1.36608 8.44798 1.62578 6.92225 2.31008 5.57199C2.99438 4.22174 4.07126 3.11015 5.39914 2.38337C6.72702 1.65659 8.24374 1.34864 9.74991 1.49999M5.99991 10.5C5.99991 10.5 7.12491 12 8.99991 12C10.8749 12 11.9999 10.5 11.9999 10.5M6.74991 6.74999H6.75679M11.2499 6.74999H11.2568M11.9999 3.74999H16.4999M14.2499 1.49999V5.99999"
					stroke="#6C7A8B"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2744_4168">
					<rect width="18" height="18" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Status2Icon;
