const OnLunchIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.1284 0.0450223C11.5775 0.197442 11.8202 0.691686 11.6705 1.14895L10.9281 3.41672C11.4737 3.50106 11.9982 3.61003 12.4893 3.74362L13.5193 1.64626C13.731 1.21515 14.2458 1.04041 14.6692 1.25596C15.0926 1.47152 15.2643 1.99574 15.0526 2.42685L14.1125 4.34107C15.266 4.91119 16 5.69496 16 6.69242V9.01819C16 12.8741 12.5714 16 8 16C3.42857 16 0 12.8741 0 9.01819V6.69242C0 4.13461 4.82883 2.9816 9.18091 3.234L10.0442 0.596984C10.1939 0.139724 10.6793 -0.107398 11.1284 0.0450223ZM11.3905 5.98114L10.6621 7.46445C10.4504 7.89556 10.622 8.41978 11.0454 8.63533C11.4688 8.85089 11.9837 8.67615 12.1954 8.24504L12.9563 6.6956C13.4385 7.03688 13.7143 7.43266 13.7143 7.85467C13.7143 9.13999 11.1559 10.1819 8 10.1819C4.84409 10.1819 2.28571 9.13999 2.28571 7.85467C2.28571 6.56935 4.84409 5.52739 8 5.52739C8.14397 5.52739 8.2867 5.52956 8.42799 5.53382L7.7585 7.57881C7.60881 8.03607 7.85151 8.53031 8.30061 8.68273C8.7497 8.83515 9.23512 8.58803 9.38482 8.13077L10.1797 5.7027C10.6126 5.77552 11.0188 5.86937 11.3905 5.98114Z"
				fill="#6C7A8B"
			/>
		</svg>
	);
};

export default OnLunchIcon;
