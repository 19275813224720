import React, { useState } from "react";
import styles from "./TransferDialogue.module.scss";
import Backdrop from "components/UI/Backdrop";
import CloseIcon from "components/UI/Icons/Close";
import TransferIcon from "components/UI/Icons/Sidecar/Transfer";
import ContactBookIcon from "components/UI/Icons/ContactBook";
import { useDispatch } from "react-redux";
import { addSidecarConfigNewTempActionList, setShowChooseActions, setShowTransferDialogue } from "redux/sidecar/sidecarSlice";
import GeneralContactDialog from "components/Modal/GeneralContactDialog";

const TransferDialogue = ({uuid = "", numberValue="", transferTypeValue="0"}:{uuid:string,numberValue:string,transferTypeValue:string}) => {
	const dispatch = useDispatch()
	const [number, setNumber] = useState(numberValue);
	const [transferType, setTransferType] = useState(transferTypeValue);
	const [showSelectContact, setShowSelectContact] = useState(false);
	const back = ()=>{
		dispatch(setShowTransferDialogue({uuid:"", show:false}));
	}
	return (
		!showSelectContact?
		<>
			<Backdrop onCancel={()=>{dispatch(setShowTransferDialogue({uuid:"", show:false}));if(!uuid)dispatch(setShowChooseActions(true))}} />
			<div className={styles.dialogueBox}>
				<h1 className={styles.topHeading}>
					<span>
						<TransferIcon />
						<span>Transfer</span>
					</span>

					<span className={styles.closeBox} onClick={()=>{dispatch(setShowTransferDialogue({uuid:"", show:false}));if(!uuid)dispatch(setShowChooseActions(true))}}>
						<CloseIcon />
					</span>
				</h1>

				<div className={styles.transferBox}>
					<div>
						<input type="radio" name="call" id="transfer_call" onChange={() => {setTransferType("0");}} checked={transferType === "0"}/>
						<label htmlFor="transfer_call">Transfer call</label>
					</div>
					<div>
						<input type="radio" name="call" id="attended_transfer_call" onChange={() => {setTransferType("1");}} checked={transferType === "1"}/>
						<label htmlFor="attended_transfer_call">Attended Transfer call</label>
					</div>
				</div>

				<div className={styles.row}>
					<label htmlFor="">Number</label>
					<input type="text" placeholder="" value={number} onChange={(e) => {setNumber(e.target.value);}}/>
					<span  onClick={()=>{setShowSelectContact(true)}}>
						<ContactBookIcon />
					</span>
				</div>

				<div className={styles.btnBox}>
					<button 
						disabled={ number === "" ? true:false}
						className={`${number === "" ? styles.disabled:{}}`}
						onClick={()=>{dispatch(addSidecarConfigNewTempActionList({name:"Transfer", uuid:uuid, number:number===""?"0":number, transferType:transferType })); back()} }>{uuid ? "Update":"Add Action"}</button>
				</div>
			</div>
		</>:<GeneralContactDialog setNumber={setNumber} setShowSelectContact={setShowSelectContact} />
	);
};

export default TransferDialogue;
