import { useLocation } from "react-router-dom";
import styles from "./MessagePopUpBar.module.scss";

const MessagePopUpBar = ({ message, color, icon }: { message: string; color?: string; icon?: string }) => {
	const location = useLocation();

	return (
		location.pathname === "/dashboard" && (
			<div className={styles.bar} onClick={() => {}} style={{ background: color ? color : "var(--text-danger)" }}>
				<div className={styles.bar_info}>
					{icon === "mic" ? (
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" viewBox="0 0 16 16">
							<path d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4.02 4.02 0 0 0 12 8V7a.5.5 0 0 1 1 0v1zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a4.973 4.973 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4zm3-9v4.879l-1-1V3a2 2 0 0 0-3.997-.118l-.845-.845A3.001 3.001 0 0 1 11 3z" />
							<path d="m9.486 10.607-.748-.748A2 2 0 0 1 6 8v-.878l-1-1V8a3 3 0 0 0 4.486 2.607zm-7.84-9.253 12 12 .708-.708-12-12-.708.708z" />
						</svg>
					) : (
						""
					)}
					<span>{message}</span>
				</div>
			</div>
		)
	);
};

export default MessagePopUpBar;
