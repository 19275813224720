import { Slide, toast } from "react-toastify";
import styles from "./MiniActiveCallControl.module.scss";
import { useDispatch, useSelector } from "react-redux";
import sip from "lib/sip";
import { store } from "redux/store";
import CallTransferIcon from "components/UI/Icons/Fills/CallTransfer";
import GroupIcon from "components/UI/Icons/Fills/Group";
import { emptyFunction } from "utils";
import { setCallNumber } from "redux/call/callSlice";
import { setSidecarView } from "redux/sidecar/sidecarSlice";
import TransferCallCard from "../TransferCallCard";
import { useState } from "react";
import BtnAction from "components/UI/BtnAction";
import PhoneEndIcon from "components/UI/Icons/Fills/PhoneEnd";
import PhoneIcon from "components/UI/Icons/Fills/Phone";
const MiniActiveCallControl = () => {
  const {
    ringingInboundCalls,
    answeredCalls,
    ringingOutboundCalls,
    activeCallLineNumber
  } = useSelector((state: any) => state.sip);
  const dispatch = useDispatch();
  const showConferenceCallsList = (
    LineNumber: number,
    showConferenceCallsList = true
  ) => {
    store.dispatch({
      type: "sip/answeredCalls",
      payload: {
        action: "showConferenceCallsList",
        data: {
          lineNum: LineNumber,
          showConferenceCallsList: showConferenceCallsList
        }
      }
    });
    dispatch(setCallNumber(""));
  };
  const showAddConferenceCall = (
    LineNumber: number,
    showAddConferenceCall = true
  ) => {
    dispatch(setSidecarView(false));
    store.dispatch({
      type: "sip/answeredCalls",
      payload: {
        action: "showAddConferenceCall",
        data: {
          lineNum: LineNumber,
          showAddConferenceCall: showAddConferenceCall
        }
      }
    });
    dispatch(setCallNumber(""));
  };
  const [isTransferButtonClicked, setIsTransferButtonClicked] = useState(false);
  const [
    hideMiniCallControlBtnHover,
    setHideMiniCallControlBtnHover
  ] = useState(false);
  const [hideMiniCallControl, setHideMiniCallControl] = useState(false);
  const attendedTransferModalShow = (LineNumber: number, show: boolean) => {
    store.dispatch({
      type: "sip/answeredCalls",
      payload: {
        action: "attendedTransferModalShow",
        data: { lineNum: LineNumber, attendedTransferModalShow: show }
      }
    });
  };
  return [
    ...ringingInboundCalls,
    ...answeredCalls,
    ...ringingOutboundCalls
  ].map(
    (call: any) =>
      activeCallLineNumber === call.LineNumber &&
      (hideMiniCallControl === false
        ? <div className={styles.container}>
            <span className={styles.callDetail}>
              <div>Call is Active</div>
              <div>
                {call.conferenceCallList
                  ? "Conference"
                  : call.DisplayName ? call.DisplayName : call.DisplayNumber}
              </div>
            </span>
            <span
              className={styles.hangup}
              onClick={() => {
                if (call.attendedTransferModal) {
                  store.dispatch({
                    type: "sip/answeredCalls",
                    payload: {
                      action: "attendedTransferModalShow",
                      data: {
                        lineNum: call.LineNumber,
                        attendedTransferModalShow: true
                      }
                    }
                  });
                } else if (call.conferenceCallList) {
                  toast("Conference Ended by Host", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    transition: Slide
                    // theme: theme ? "dark" : "light", //"light","dark"
                  });
                  sip.hungupConference(call.LineNumber);
                } else {
                  sip.hungup(call.LineNumber);
                }
              }}
            >
              <PhoneEndIcon color="support-danger-default" />
            </span>
            <span className={styles.transfer}>
              <div
                className={styles.icon}
                onClick={() => {
                  if (call.answered) {
                    if (call.attendedTransferModal) {
                      attendedTransferModalShow(call.LineNumber, true);
                    } else {
                      setIsTransferButtonClicked(!isTransferButtonClicked);
                    }
                  }
                }}
              >
                <CallTransferIcon
                  color={call.answered ? "icon-emphasis" : "icon-disabled"}
                />
              </div>
              <div className={styles.transferCard}>
                {isTransferButtonClicked &&
                  call.answered &&
                  <TransferCallCard
                    transferBtn={setIsTransferButtonClicked}
                    LineNumber={call.LineNumber}
                  />}
              </div>
            </span>
            <span
              className={styles.conf}
              onClick={() => {
                call.answered
                  ? call.conferenceCallList
                    ? showConferenceCallsList(
                        call.LineNumber,
                        call.conferenceCallList
                      )
                    : showAddConferenceCall(call.LineNumber)
                  : emptyFunction();
              }}
            >
              <GroupIcon
                color={call.answered ? "icon-emphasis" : "icon-disabled"}
              />
            </span>
            <span className={styles.timer}>
              {call.answered ? call.callTimer : "00:00"}
            </span>
            <span>
              <BtnAction
                btnType={"normal"}
                isDisabled={false}
                type="button"
                isActive={false}
                onMouseOut={() => {
                  setHideMiniCallControlBtnHover(false);
                }}
                onMouseOver={() => {
                  setHideMiniCallControlBtnHover(true);
                }}
                onClick={() => {
                  setHideMiniCallControl(true);
                }}
                icon={
                  <div
                    style={{
                      color: `var(--${hideMiniCallControlBtnHover
                        ? "primary-default"
                        : "icon-primary"})`
                    }}
                  >
                    Hide
                  </div>
                }
              />
            </span>
          </div>
        : <div>
            <BtnAction
              btnType={"normal"}
              isDisabled={false}
              type="button"
              isActive={false}
              onMouseOut={() => {
                setHideMiniCallControlBtnHover(false);
              }}
              onMouseOver={() => {
                setHideMiniCallControlBtnHover(true);
              }}
              onClick={() => {
                setHideMiniCallControl(false);
              }}
              icon={<PhoneIcon color="support-approve-default" />}
            />
          </div>)
  );
};

export default MiniActiveCallControl;
