import React from "react";
import { RouteObject } from "react-router-dom";
import routePaths from "./../../constants/routes";
import ProtectedRoutes from "utils/ProtectedRoutes";
import Loader from "components/UI/Loader";

const Dashboard = React.lazy(() => import("pages/Dashboard"));

export const dashboardRoutes: RouteObject[] = [
	{
		path: routePaths.DASHBOARD.__PATH,
		element: (
			<ProtectedRoutes>
				<React.Suspense fallback={<Loader />}>
					<Dashboard />
				</React.Suspense>
			</ProtectedRoutes>
		),
	},
];
