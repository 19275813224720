import styles from "./UpdateAvailable.module.scss";

const UpdateAvailable = () => {
	const handleRefresh = () => {
		window.location.reload();
	};

	return (
		<div className={styles.bar}>
			<div className={styles.bar_info}>
				<p>There is a new version of the web app available please refresh to get the latest version.</p>
				<button onClick={handleRefresh}>Refresh</button>
			</div>
		</div>
	);
};

export default UpdateAvailable;
