const AFKIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.2857 1.14258C13.4416 1.14258 16 3.70095 16 6.85686C16 10.0128 13.4416 12.5711 10.2857 12.5711H3.42857L0.97549 15.0242C0.61551 15.3842 0 15.1293 0 14.6202V6.85686C0 3.70095 2.55837 1.14258 5.71429 1.14258H10.2857ZM4.57143 7.99972C5.20261 7.99972 5.71429 7.48805 5.71429 6.85686C5.71429 6.22568 5.20261 5.71401 4.57143 5.71401C3.94025 5.71401 3.42857 6.22568 3.42857 6.85686C3.42857 7.48805 3.94025 7.99972 4.57143 7.99972ZM9.14286 6.85686C9.14286 7.48805 8.63118 7.99972 8 7.99972C7.36882 7.99972 6.85714 7.48805 6.85714 6.85686C6.85714 6.22568 7.36882 5.71401 8 5.71401C8.63118 5.71401 9.14286 6.22568 9.14286 6.85686ZM11.4286 7.99972C12.0598 7.99972 12.5714 7.48805 12.5714 6.85686C12.5714 6.22568 12.0598 5.71401 11.4286 5.71401C10.7974 5.71401 10.2857 6.22568 10.2857 6.85686C10.2857 7.48805 10.7974 7.99972 11.4286 7.99972Z"
				fill="#6C7A8B"
			/>
		</svg>
	);
};

export default AFKIcon;
