const getDaySuffix = (day: number) => {
	if (day >= 11 && day <= 13) {
		return "th";
	}
	switch (day % 10) {
		case 1:
			return "st";
		case 2:
			return "nd";
		case 3:
			return "rd";
		default:
			return "th";
	}
};
export const formatDate = (dateString: string) => {
	const date = new Date(dateString);

	const hours = date.getHours();
	const hours12 = hours % 12 === 0 ? 12 : hours % 12; // Adjust for 12 AM/PM
	const minutes = date.getMinutes();

	const hoursString = hours12 < 10 ? `0${hours12}` : hours12; // Ensure two digits for hours
	const minutesString = minutes < 10 ? `0${minutes}` : minutes;
	const ampm = hours >= 12 ? "PM" : "AM";

	return `${hoursString}:${minutesString} ${ampm}`;
};

export const toSecMinAndHr = (totalSeconds: number) => {
	const seconds = Math.floor(totalSeconds % 60);
	const minutes = Math.floor((totalSeconds - seconds) / 60);
	const hours = Math.floor(minutes / 60);
	const minutesRemaining = minutes % 60;

	const secondsString = seconds >= 0 ? seconds : "";
	const minutesString = minutesRemaining >= 0 ? minutesRemaining : "";
	const hoursString = hours >= 0 ? hours : "";

	return `${hoursString ? `${hoursString} hr ` : ""}${
		minutesString ? `${minutesString} min, ` : ""
	}${secondsString} sec`;
};

// export const convertDateFormat = (dateString: string) => {
// 	const dateComponents = dateString?.split("-");

// 	const year = parseInt(dateComponents[0]);
// 	const month = parseInt(dateComponents[1]);
// 	const day = parseInt(dateComponents[2]);

// 	const date = new Date(year, month - 1, day);

// 	const formattedDate = date?.toLocaleDateString("en-US", {
// 		month: "long",
// 		day: "numeric",
// 		year: "numeric",
// 	});

// 	return formattedDate;
// };

export const convertDateFormat = (dateString: string) => {
	const dateComponents = dateString?.split("-");

	if (dateComponents && dateComponents.length === 3) {
		const year = parseInt(dateComponents[0]);
		const month = parseInt(dateComponents[1]);
		const day = parseInt(dateComponents[2]);

		if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
			const date = new Date(year, month - 1, day);

			if (!isNaN(date.getTime())) {
				const formattedDate = date.toLocaleDateString("en-US", {
					month: "long",
					day: "numeric",
					year: "numeric",
				});

				return formattedDate;
			}
		}
	}

	return "Invalid Date"; // Handle the case where the input date is invalid
};

export const formatTime = (time: number) => {
	if (time && !isNaN(time)) {
		const minutes = Math.floor(time / 60);
		const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
		const seconds = Math.floor(time % 60);
		const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
		return `${formatMinutes}:${formatSeconds}`;
	}

	return "00:00";
};

export const convertInputDateFormat = (str: string) => {
	const date = new Date(str);
	const convertedDateStr = date.toISOString() + "Z";
	const millisecondsRegex = /\.\d+$/;
	return convertedDateStr.replace(millisecondsRegex, ".868000");
};

export const longDateTimeFormat = (str: string) => {
	const date = new Date(str);
	const weekday = date.toLocaleDateString("en-US", { weekday: "long" });
	const month = date.toLocaleDateString("en-US", { month: "long" });
	const day = date.getDate();
	const year = date.getFullYear();
	const formattedDateString = `${weekday}, ${month} ${day}, ${year}`;
	return formattedDateString;
};

export const convertToHourMinuteFormat = (dateTimeString) => {
	// Create a Date object from the input string
	const dateTime = new Date(dateTimeString);

	// Get hours and minutes
	const hours = dateTime.getUTCHours();
	const minutes = dateTime.getUTCMinutes();

	// Format hours and minutes as two-digit strings
	const formattedHours = hours.toString().padStart(2, "0");
	const formattedMinutes = minutes.toString().padStart(2, "0");

	// Return the time in "HH:MM" format
	return `${formattedHours}:${formattedMinutes}`;
};

export const getWeek = (date: Date) => {
	const d = new Date(date.getFullYear(), 0, 1);
	const dayNum = d.getDay();
	d.setMonth(0);
	d.setDate(d.getDate() - dayNum + 1);
	const weeks = Math.ceil((date.getTime() - d.getTime()) / (86400000 * 7));
	return weeks;
};

export const recentDateFormat = (dateStr: string) => {
	const dateNum = Date.parse(dateStr);
	const date = new Date(dateNum);
	const today = new Date();
	const yesterday = new Date(today);
	yesterday.setDate(yesterday.getDate() - 1);

	const formatTime = (date: Date) => {
		let hours = date.getHours();
		const minutes = date.getMinutes();
		const ampm = hours >= 12 ? "PM" : "AM";
		hours = hours % 12 || 12;
		const formattedTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;
		return formattedTime;
	};

	if (date.toDateString() === today.toDateString()) {
		return `Today, ${formatTime(date)}`;
	} else if (date.toDateString() === yesterday.toDateString()) {
		return `Yesterday, ${formatTime(date)}`;
	} else {
		const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
		// const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
		const currentWeek = today.getFullYear() === date.getFullYear() && getWeek(date) === getWeek(today);
		return currentWeek
			? `${days[date.getDay()]}, ${formatTime(date)}`
			: date.toLocaleDateString("en-US", {
					year: "numeric",
					month: "short",
					day: "numeric",
			  });
	}
};

export const messageDateFormat = (dateStr: string) => {
	const dateNum = Date.parse(dateStr);
	const date = new Date(dateNum);
	const today = new Date();
	const yesterday = new Date(today);
	yesterday.setDate(yesterday.getDate() - 1);

	const formatDate = (inputDate: string | number | Date) => {
		const dt = new Date(inputDate);
		const day = dt.getDate();
		const month = dt.toLocaleString("default", { month: "long" });
		const year = dt.getFullYear();
		const suffix = getDaySuffix(day);

		return `${dt.toLocaleString("default", { weekday: "long" })}, ${month} ${day}${suffix}, ${year}`;
	};

	if (date.toDateString() === today.toDateString()) {
		return `Today`;
	} else if (date.toDateString() === yesterday.toDateString()) {
		return `Yesterday`;
	} else {
		const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
		const beforeAddingDaySuffix = `${days[date.getDay()]}, ${date.toLocaleDateString("en-US", {
			year: "numeric",
			month: "long",
			day: "numeric",
		})}`;
		return formatDate(beforeAddingDaySuffix);
	}
};
export const formatDateAdvanced = (dateString: string) => {
	const date = new Date(dateString);
	const today = new Date();
	const yesterday = new Date(today);
	yesterday.setDate(yesterday.getDate() - 1);

	if (date.toDateString() === today.toDateString()) {
		return "Today";
	} else if (date.toDateString() === yesterday.toDateString()) {
		return "Yesterday";
	} else {
		const weekStart = new Date(today);
		weekStart.setDate(today.getDate() - today.getDay());

		if (date >= weekStart) {
			const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
			return days[date.getDay()];
		} else {
			const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

			const year = date.getFullYear() === today.getFullYear() ? "" : ` ${date.getFullYear()}`;
			const dateMonth = `${monthNames[date.getMonth()]}${year}`;
			return `${date.getDate()} ${dateMonth}`;
		}
	}
};

// export function getDate(dateTimeString: any) {
// 	const dateObj = new Date(dateTimeString);
// 	return dateObj?.toDateString(); // Returns the date in a human-readable format
// }

export const longDateFormat = (date) => {
	const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

	// Split the date string into day, month, and year
	const dateParts = date.split("/");
	const day = parseInt(dateParts[0], 10); // Extract day
	const month = parseInt(dateParts[1], 10) - 1; // Extract month (subtract 1 as months are zero-indexed)
	const year = parseInt(dateParts[2], 10); // Extract year

	// Create a new Date object using the extracted parts
	const currentDate = new Date(year, month, day);

	// Get the day, month abbreviation, and year from the Date object
	const formattedDay = currentDate.getDate();
	const formattedMonth = months[currentDate.getMonth()];
	const formattedYear = currentDate.getFullYear();

	// Construct the formatted date string
	const formattedDate = `${formattedDay} ${formattedMonth} ${formattedYear}`;
	// console.log(formattedDate);
	return formattedDate;
};

// export function passedTime(date) {
// 	const currentdate = new Date();
// 	const miliseconds = new Date(date);
// 	const seconds = Math.floor(miliseconds.getTime() / 1000);
// 	const currentseconds = Math.floor(currentdate.getTime() / 1000);
// 	const difference = Math.abs(seconds - currentseconds);

// 	var d = Math.floor(difference / (3600 * 24));
// 	var h = Math.floor((difference % (3600 * 24)) / 3600);
// 	var m = Math.floor((difference % 3600) / 60);
// 	var s = Math.floor(difference % 60);

// 	var dDisplay = d > 0 ? d + (d == 1 ? " day " : " days ") : "";
// 	var hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
// 	var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
// 	var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " secs") : "";

// 	if (d > 0) {
// 		return dDisplay;
// 	} else if (h > 0) {
// 		return hDisplay;
// 	} else if (m > 0) {
// 		return mDisplay;
// 	} else if (s > 0) {
// 		return sDisplay;
// 	}
// }

export function showPassedTimeDate(date) {
	const today = new Date();
	const givenDate = new Date(date);
	const yesterday = new Date(today);
	yesterday.setDate(yesterday.getDate() - 1);
	const dayBeforeYesterday = new Date(today);
	dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);

	const dateFormatOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
	const timeFormatOptions = { hour: "2-digit", minute: "2-digit", hour12: true };

	const isSameDay = (date1, date2) =>
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDate() === date2.getDate();

	if (isSameDay(givenDate, today)) {
		return givenDate.toLocaleTimeString("en-US", timeFormatOptions);
	} else if (isSameDay(givenDate, yesterday)) {
		return "Yesterday";
	} else if (isSameDay(givenDate, dayBeforeYesterday)) {
		return givenDate.toLocaleDateString("en-US", dateFormatOptions).replace(/\//g, ".");
	} else {
		// Check if the given date is further in the past
		if (givenDate < dayBeforeYesterday) {
			return givenDate.toLocaleDateString("en-US", dateFormatOptions).replace(/\//g, ".");
		} else {
			return "Date is further in the past";
		}
	}
}
export function convertIntoLongDateFormat(dateString) {
	const currentDate = new Date();

	const dateParts = dateString.split("/");
	const day = parseInt(dateParts[0], 10);
	const monthIndex = parseInt(dateParts[1], 10) - 1;
	const year = parseInt(dateParts[2], 10);

	const date = new Date(year, monthIndex, day);

	// Check if the date is today
	if (
		date.getDate() === currentDate.getDate() &&
		date.getMonth() === currentDate.getMonth() &&
		date.getFullYear() === currentDate.getFullYear()
	) {
		return "Today";
	}

	// Get yesterday's date
	const yesterday = new Date(currentDate);
	yesterday.setDate(currentDate.getDate() - 1);

	// Check if the date is yesterday
	if (
		date.getDate() === yesterday.getDate() &&
		date.getMonth() === yesterday.getMonth() &&
		date.getFullYear() === yesterday.getFullYear()
	) {
		return "Yesterday";
	}

	const options = {
		weekday: "long",
		month: "long",
		day: "numeric",
		year: "numeric",
	};

	const formattedDate = date.toLocaleDateString("en-US", options);

	const daySuffix = getDaySuffix(day);

	return formattedDate.replace(/\b\d{1,2}\b/, day + daySuffix);
}

// Example usage:
// const originalDate = "20/12/2023";
// const convertedDate = convertDateFormat(originalDate);
// console.log(convertedDate); // Output: Thursday, December 20th, 2023

export function convertNewDateToString(date) {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");
	const seconds = String(date.getSeconds()).padStart(2, "0");
	const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
	const timezoneOffset = -date.getTimezoneOffset();
	const timezoneOffsetHours = Math.floor(Math.abs(timezoneOffset) / 60)
		.toString()
		.padStart(2, "0");
	const timezoneOffsetMinutes = (Math.abs(timezoneOffset) % 60).toString().padStart(2, "0");
	const timezoneSign = timezoneOffset >= 0 ? "+" : "-";

	const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${timezoneSign}${timezoneOffsetHours}${timezoneOffsetMinutes}`;

	return formattedDate;
}

// Example usage:
// const currentDate = new Date(); // Create a new Date object with the current date and time
// const formattedDate = convertNewDateToString(currentDate);
// console.log(formattedDate);

export function getCurrentDate() {
	let currentDate = new Date();

	let month = currentDate.getMonth() + 1; // Adding 1 because months are zero-indexed
	let day = currentDate.getDate();
	let year = currentDate.getFullYear();

	// Formatting to MM/DD/YYYY format
	let formattedDate = `${month.toString().padStart(2, "0")}/${day.toString().padStart(2, "0")}/${year}`;

	return formattedDate;
}

export function convertToISO8601(dateString) {
	// Split the input string into month, day, and year
	let parts = dateString.split("/");

	// Rearrange the date parts to the ISO8601 format (YYYY-MM-DD)
	let isoDate = `${parts[2]}-${parts[0].padStart(2, "0")}-${parts[1].padStart(2, "0")}`;

	return isoDate;
}

// Example usage:
//   let dateInMMDDYYYY = '01/06/2024';
//   let isoDate = convertToISO8601(dateInMMDDYYYY);
//   console.log(isoDate);

export function getCurrentTimeZoneName() {
	return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function getCurrentYear() {
	const currentDate = new Date();
	return currentDate.getFullYear();
}

export const convertNextFetchDateTime = (timestamp: any) => {
	if (timestamp) {
		const date = new Date(parseInt(timestamp, 10));

		const dateOptions: any = { year: "numeric", month: "short", day: "numeric" };
		const formattedDate = new Intl.DateTimeFormat("en-US", dateOptions).format(date);

		const timeOptions: any = { hour: "numeric", minute: "2-digit", hour12: true };
		const formattedTime = new Intl.DateTimeFormat("en-US", timeOptions).format(date);

		return `${formattedDate} - ${formattedTime}`;
	} else {
		return "";
	}
};
