import { IIcon } from "constants/interfaces";

const PlayerPlayIcon = ({ color }: IIcon) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M4.125 22C4.125 12.1275 12.1275 4.125 22 4.125C31.8725 4.125 39.875 12.1275 39.875 22C39.875 31.8725 31.8725 39.875 22 39.875C12.1275 39.875 4.125 31.8725 4.125 22ZM29.8357 20.1978C30.1568 20.3766 30.4244 20.6379 30.6106 20.9547C30.7969 21.2716 30.8951 21.6325 30.8951 22C30.8951 22.3675 30.7969 22.7284 30.6106 23.0453C30.4244 23.3621 30.1568 23.6234 29.8357 23.8022L19.5635 29.5093C19.2496 29.6836 18.8957 29.7729 18.5367 29.7684C18.1777 29.7639 17.8262 29.6658 17.5167 29.4837C17.2073 29.3017 16.9507 29.0421 16.7724 28.7305C16.5941 28.4189 16.5002 28.0662 16.5 27.7072V16.2928C16.5 14.7217 18.1885 13.7262 19.5635 14.4907L29.8357 20.1978Z"
				fill={`var(--${color ? color : "icon-primary"})`}
			/>
		</svg>
	);
};

export default PlayerPlayIcon;
