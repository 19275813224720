import React, { useState } from "react";
import styles from "./ConferenceDialogue.module.scss";
import Backdrop from "components/UI/Backdrop";
import CloseIcon from "components/UI/Icons/Close";
import ContactBookIcon from "components/UI/Icons/ContactBook";
import { useDispatch } from "react-redux";
import { addSidecarConfigNewTempActionList, setShowChooseActions, setShowConferenceDialogue } from "redux/sidecar/sidecarSlice";
import ConferenceIcon from "components/UI/Icons/Sidecar/Conference";
import PlusIcon from "components/UI/Icons/Lines/Plus";
import DeleteIcon from "components/UI/Icons/Delete";
import GeneralContactDialog from "components/Modal/GeneralContactDialog";

const ConferenceDialogue = ({uuid = "", numbers=[]}:{uuid:string, numbers:Array<String>}) => {
	const dispatch = useDispatch()
	const [numberList, setNumberList] = useState(numbers);
	const [number, setNumber] = useState("");
	const [deleteHover, setDeleteHover] = useState(-1);
	const [showSelectContact, setShowSelectContact] = useState(false);
	const back = ()=>{
		dispatch(setShowConferenceDialogue({uuid:"", show:false}));
	}
	const removeNumber = (index:number)=>{
		setNumberList(
			[
				...numberList.slice(0, index),
				...numberList.slice(index + 1),
			]
		);
	}
	const addNumber = ()=>{
		setNumberList(
			[	
				...numberList,number
			]
		);
		setNumber("");
	}
	return (
		!showSelectContact?
		<>
			<Backdrop onCancel={ ()=>{dispatch(setShowConferenceDialogue({uuid:"", show:false}));if(!uuid)dispatch(setShowChooseActions(true))}}/>
			<div className={styles.dialogueBox}>
				<h1 className={styles.topHeading}>
					<span>
						<ConferenceIcon />
						<span>Conference</span>
					</span>

					<span className={styles.closeBox} onClick={()=>{dispatch(setShowConferenceDialogue({uuid:"", show:false}));if(!uuid)dispatch(setShowChooseActions(true))}}>
						<CloseIcon />
					</span>
				</h1>
				<div className={styles.inputBox}>
					<label htmlFor="">Number</label>
					<input type="text" placeholder="" value={number} onChange={(e) => {setNumber(e.target.value);}}/>
					<span className={styles.actions1}>
						<span className={styles.contactIcon} onClick={()=>{setShowSelectContact(true)}}>
							<ContactBookIcon />
						</span>
						<span className={styles.plus} onClick={addNumber}>
							<PlusIcon/>
						</span>
					</span>
				</div>
				<div className={styles.numberList}>
					{numberList?.map((number, index) => {
						return (
							<p>{number} <span onClick={()=>{removeNumber(index)}} onMouseOver={() => {setDeleteHover(index+0);}} onMouseOut={() => {setDeleteHover(-1);}}><DeleteIcon color={deleteHover === index?"support-danger-default" : "icon-primary"} /></span></p>
						);
					})}
				</div>
				<div className={styles.btnBox}>
					<button
						disabled={numberList?.length < 2  ? true:false}
						className={`${numberList?.length  < 2 ? styles.disabled:{}}`}
					 onClick={()=>{dispatch(addSidecarConfigNewTempActionList({uuid:uuid, name:"Conference", numbers:numberList })); back()} }>{uuid ? "Update":"Add Action"}</button>
				</div>
			</div>
		</>:<GeneralContactDialog setNumber={setNumber} setShowSelectContact={setShowSelectContact} />
	);
};

export default ConferenceDialogue;
