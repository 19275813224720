const ChevronUpIcon = () => (
	<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
		<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
		<g id="SVGRepo_iconCarrier">
			{" "}
			<path
				d="M6 15L12 9L18 15"
				stroke="var(--icon-on-color)"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"></path>{" "}
		</g>
	</svg>
);

export default ChevronUpIcon;
