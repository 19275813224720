import PlayerPlayIcon from "components/UI/Icons/Fills/PlayerPlay";
import styles from "./ActionBtnList.module.scss";
import AvailableIcon from "components/UI/Icons/Status/Available";

import HolidayIcon from "components/UI/Icons/Status/Holiday";
import PlayIcon from "components/UI/Icons/Voicemail/Play";
import PlayerPauseIcon from "components/UI/Icons/Fills/PlayerPause";
import PlayerStopIcon from "components/UI/Icons/Fills/PlayerStop";
import {
	IConferenceAction,
	IDialAction,
	IHangupAction,
	IHoldAction,
	IKeyPressAction,
	IMergeAction,
	IModifyAditionalStatusAction,
	IModifyMainStatusAction,
	IPauseAction,
	ISendMessageAction,
	ISidecarActionProgress,
	ITransferAction,
} from "redux/sidecar/sidecarTypes";
import { useDispatch, useSelector } from "react-redux";
import {
	executePauseSidecarAction,
	executePlaySidecarAction,
	executeStopSidecarAction,
	updateShowStatusMenu,
} from "redux/sidecar/sidecarSlice";
import index from "components/Conference/Header";
import AdditionalStatusActionCard from "../Actions/AdditionalStatus";
import ConferenceActionCard from "../Actions/Conference";
import DialActionCard from "../Actions/Dial";
import HangupActionCard from "../Actions/Hangup";
import HoldActionCard from "../Actions/Hold";
import KeypressActionCard from "../Actions/Keypress";
import MainStatusActionCard from "../Actions/MainStatus";
import MergeActionCard from "../Actions/Merge";
import PauseActionCard from "../Actions/Pause";
import SendMessageActionCard from "../Actions/SendMessage";
import TransferActionCard from "../Actions/Transfer";
import AFKIcon from "components/UI/Icons/Status/AFK";
import CalenderIcon from "components/UI/Icons/Status/Calender";
import OnCallIcon from "components/UI/Icons/Status/OnCall";
import OnLunchIcon from "components/UI/Icons/Status/OnLunch";
import { useEffect, useState } from "react";
import AwayIcon from "components/UI/Icons/Status/Away";
import DNDIcon from "components/UI/Icons/Status/DND";

const ActionBtnList = ({
	actionList,
	uuid,
	name,
	extension,
	actionCount,
	progress,
	showActionList,
	status,
	allExtensions,
}: {
	uuid: string;
	name: string;
	extension?: string;
	actionCount: string;
	progress?: ISidecarActionProgress;
	actionList: Array<
		| IDialAction
		| ISendMessageAction
		| IPauseAction
		| ITransferAction
		| IConferenceAction
		| IMergeAction
		| IKeyPressAction
		| IHangupAction
		| IHoldAction
		| IModifyMainStatusAction
		| IModifyAditionalStatusAction
	>;
	showActionList?:{reset:() => void, showList:boolean, show:() => void},
	status:any,
	allExtensions:any,
}) => {
	const dispatch = useDispatch();
	const [statusResult, setStatusResult] = useState<any>();
	const [extensionUserId, setExtensionUserId] = useState<any>();
	const [additionalStatusResult, setAdditionalStatusResult] = useState<any>();
	const {extensionStatusChanged} = useSelector((state: any) => state.sidecar);
	useEffect(()=>{
		for(let x = 0; x < allExtensions?.length; x++){
			if(String(allExtensions[x]?.data.extension) === extension){
				if(allExtensions[x]?.user?.id)
					setExtensionUserId(allExtensions[x]?.user?.id)
				break;
			}
		}
	},[allExtensions])
	useEffect(()=>{
		const filteredStatus = status?.filter((item: { user_id: any; })=>{return (item?.user_id && item?.user_id === extensionUserId) ? true: false })
		setStatusResult(filteredStatus && filteredStatus[0]?.main_status?.status ? String(filteredStatus[0]?.main_status?.status) : null)
		setAdditionalStatusResult(filteredStatus && filteredStatus[0]?.additional_status?.status ? String(filteredStatus[0]?.additional_status?.status) : null)
	},[extensionUserId, status])
	useEffect(() => {
		const extensionStatus = extensionStatusChanged?.filter((item:any)=>{return item?.user_id === extensionUserId ? true : false})
		if(extensionStatus.length){
			const newStatus = extensionStatus[0];
			setStatusResult(newStatus?.main_status?newStatus?.main_status:"")
			setAdditionalStatusResult(newStatus?.additional_status?newStatus?.additional_status:"")
		}
	}, [extensionStatusChanged, extensionUserId]);
	const updateStatus = ()=> {
		const userStatus = {
			userID:extensionUserId,
			main_status: statusResult,
			additional_status: additionalStatusResult,
		}
		dispatch(updateShowStatusMenu({setStatusByUser:true, userStatus:userStatus}))
	}
	return (
		<div className={styles.layer1}>
			<div className={styles.actionBlock}>
				<span className={styles.layer2}>
					<div className={styles.actionName}>{name}</div>
					<div>
						<span className={styles.statusBlock} onClick={updateStatus}>
							<span className={styles.status}>
							{ statusResult === "available" && <AvailableIcon />}
							{ statusResult === "away" && <AwayIcon />}
							{ statusResult === "do_not_disturb" && <DNDIcon />}
							</span>
							<span className={styles.watchNumber}>{extension}</span>
							<span>
							{ additionalStatusResult === "on_a_call" && <OnCallIcon />}
							{ additionalStatusResult === "in_a_meeting" && <CalenderIcon />}
							{ additionalStatusResult === "lunch" && <OnLunchIcon />}
							{ additionalStatusResult === "holiday" && <HolidayIcon />}
							{ additionalStatusResult === "afk" && <AFKIcon />}
							</span>
						</span>
						<span className={styles.showListLink} onClick={()=>{if(showActionList && progress?.status !== "Active"){showActionList?.showList?showActionList?.reset():showActionList?.show()}}}>
							<span>Actions: </span>
							<span>{actionCount}</span>
						</span>
					</div>
				</span>
				<span className={styles.playBlock}>
					{progress?.status === "Active" ? (
						progress.pause === false ? (
							<div className={styles.playBlockL2}>
								<span
									className={styles.pauseIcon}
									onClick={() => {
										dispatch(executeStopSidecarAction(uuid));
									}}>
									<PlayerStopIcon color="text-danger" />
								</span>
								<span
									onClick={() => {
										dispatch(executePauseSidecarAction(uuid));
									}}>
									<PlayerPauseIcon color="primary-default" />
								</span>
							</div>
						) : (
							<div className={styles.playBlockL2}>
								<span
									className={styles.pauseIcon}
									onClick={() => {
										dispatch(executeStopSidecarAction(uuid));
									}}>
									<PlayerStopIcon color="text-danger" />
								</span>
								<span
									className={styles.playBlockL3Play}
									onClick={() => {
										dispatch(executePlaySidecarAction(uuid));
										showActionList?.reset()
									}}>
									<PlayerPlayIcon color="primary-default" />
								</span>
							</div>
						)
					) : (
						<div>
							<span
								className={styles.playBlockL2Play}
								onClick={() => {
									dispatch(executePlaySidecarAction(uuid));
									showActionList?.reset()
								}}>
								<PlayerPlayIcon color="primary-default" />
							</span>
						</div>
					)}
				</span>
			</div>
			{progress?.status === "Active" &&
				actionList.map((action) => {
					const styleColor = `${
						action.status === "Inprogress"
							? styles.inProgressColor
							: action.status === "Fail"
							? styles.failColor
							: action.status === "Done"
							? styles.doneColor
							: {}
					}`;
					return (
						<>
							<p className={`${styles.statusName} ${styleColor}`}>{action.name}</p>
							<p className={`${styleColor}`}>{action.status === "Inprogress" ? "In Progress" : action.status}</p>
						</>
					);
				})}
			
			{showActionList?.showList && progress?.status !== "Active" &&
				actionList.map((action) => {
					const actionList = ()=>{
						switch(action.name){
							case "Dial": return <DialActionCard changesMade={()=>{}} extension={action.number} uuid={""}/>
							case "SendMessage": return <SendMessageActionCard changesMade={()=>{}} fromNumber={action.fromNumber} toNumber={action.toNumber} message={action.message}  uuid={""}/>
							case "Pause": return< PauseActionCard changesMade={()=>{}} sec={action.seconds} uuid={""} />
							case "Transfer": return <TransferActionCard changesMade={()=>{}} number={action.number} transferTypeValue={action.transferType} uuid={""}/>
							case "Conference": return <ConferenceActionCard changesMade={()=>{}} uuid={""} numbers={action.numbers} />
							case "Merge": return <MergeActionCard changesMade={()=>{}}  uuid={""}/>
							case "KeyPress": return <KeypressActionCard changesMade={()=>{}}  keyPress={action.number} uuid={""} />
							case "Hangup": return <HangupActionCard changesMade={()=>{}} uuid={""} />
							case "Hold": return <HoldActionCard changesMade={()=>{}} uuid={""}/>
							case "ModifyMainStatus": return <MainStatusActionCard changesMade={()=>{}} fromStatuses={action.fromStatuses} toStatus={action.toStatus} toggle={action.toggleMode} uuid={""}/>
							case "ModifyAditionalStatus": return <AdditionalStatusActionCard changesMade={()=>{}} fromStatuses={action.fromStatuses} toStatus={action.toStatus} toggle={action.toggleMode} uuid={""}/>
						}
					}
					return <div className={styles.doubleColumn}>
						{actionList()}
					</div>
					
				})}
		</div>
	);
};

export default ActionBtnList;
