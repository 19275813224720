// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_loader__lqUPL {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: var(--background-primary);
}

.Loader_loader_wrap__q-1bU {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
}

.Loader_loader_main__OewNN {
  position: relative;
  width: 100px;
  height: 100px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Loader_ellipse1__5GmEQ {
  position: absolute;
  animation: Loader_rotate__cz8WK 4s linear infinite;
}

.Loader_ellipse2__4NIIG {
  position: absolute;
  animation: Loader_rotate__cz8WK 4s linear infinite; /* 4s is the duration, adjust as needed */
}

.Loader_ellipse3__BJ1lm {
  position: absolute;
  animation: Loader_rotate2__r8e4i 4s linear infinite; /* 4s is the duration, adjust as needed */
}

@keyframes Loader_rotate__cz8WK {
  0% {
    transform: rotate(0deg) scale(1.2);
  }
  50% {
    transform: rotate(0deg) scale(1);
  }
  100% {
    transform: rotate(360deg) scale(1.2); /* Rotates the image in a full circle */
  }
}
@keyframes Loader_rotate2__r8e4i {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg); /* Rotates the image in a full circle */
  }
}
.Loader_ri__troVU {
  position: absolute;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Loader/Loader.module.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,qCAAA;AACD;;AAEA;EACC,kBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;AACD;;AAGA;EACC,kBAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAD;;AAGA;EACC,kBAAA;EACA,kDAAA;AAAD;;AAGA;EACC,kBAAA;EACA,kDAAA,EAAA,yCAAA;AAAD;;AAGA;EACC,kBAAA;EACA,mDAAA,EAAA,yCAAA;AAAD;;AAGA;EACC;IACC,kCAAA;EAAA;EAED;IACC,gCAAA;EAAA;EAED;IACC,oCAAA,EAAA,uCAAA;EAAA;AACF;AAGA;EACC;IACC,yBAAA;EADA;EAGD;IACC,uBAAA,EAAA,uCAAA;EADA;AACF;AAIA;EACC,kBAAA;AAFD","sourcesContent":[".loader {\n\tposition: fixed;\n\twidth: 100vw;\n\theight: 100vh;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tz-index: 1000;\n\tbackground: var(--background-primary);\n}\n\n.loader_wrap {\n\tposition: relative;\n\twidth: 100%;\n\theight: 100%;\n\tdisplay: flex;\n\t// background-color: red;\n}\n\n.loader_main {\n\tposition: relative;\n\twidth: 100px;\n\theight: 100px;\n\tmargin: auto;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n}\n\n.ellipse1 {\n\tposition: absolute;\n\tanimation: rotate 4s linear infinite;\n}\n\n.ellipse2 {\n\tposition: absolute;\n\tanimation: rotate 4s linear infinite; /* 4s is the duration, adjust as needed */\n}\n\n.ellipse3 {\n\tposition: absolute;\n\tanimation: rotate2 4s linear infinite; /* 4s is the duration, adjust as needed */\n}\n\n@keyframes rotate {\n\t0% {\n\t\ttransform: rotate(0deg) scale(1.2);\n\t}\n\t50% {\n\t\ttransform: rotate(0deg) scale(1);\n\t}\n\t100% {\n\t\ttransform: rotate(360deg) scale(1.2); /* Rotates the image in a full circle */\n\t}\n}\n\n@keyframes rotate2 {\n\t0% {\n\t\ttransform: rotate(360deg);\n\t}\n\t100% {\n\t\ttransform: rotate(0deg); /* Rotates the image in a full circle */\n\t}\n}\n\n.ri {\n\tposition: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `Loader_loader__lqUPL`,
	"loader_wrap": `Loader_loader_wrap__q-1bU`,
	"loader_main": `Loader_loader_main__OewNN`,
	"ellipse1": `Loader_ellipse1__5GmEQ`,
	"rotate": `Loader_rotate__cz8WK`,
	"ellipse2": `Loader_ellipse2__4NIIG`,
	"ellipse3": `Loader_ellipse3__BJ1lm`,
	"rotate2": `Loader_rotate2__r8e4i`,
	"ri": `Loader_ri__troVU`
};
export default ___CSS_LOADER_EXPORT___;
