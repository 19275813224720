// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Merge_card__BFlUu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: var(--background-tertiary, #f7f9fc);
  height: 48px;
  padding: 10px;
}
.Merge_card__BFlUu p {
  display: flex;
  gap: 6px;
  color: var(--text-secondary, #5c6168);
  /* Body/Regular */
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.5px;
}
.Merge_card__BFlUu .Merge_extension__6XOfQ {
  color: var(--text-link, #1480e1);
  text-align: right;
  /* Body/Bold */
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.5px;
}

.Merge_actionButton__UxzQd {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Sidecar/Actions/Merge/Merge.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EAEA,mBAAA;EAEA,8BAAA;EAEA,kBAAA;EACA,+CAAA;EAEA,YAAA;EACA,aAAA;AAHD;AAKC;EACC,aAAA;EACA,QAAA;EAEA,qCAAA;EAEA,iBAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;EACA,sBAAA;AALF;AAQC;EACC,gCAAA;EACA,iBAAA;EAEA,cAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;EACA,sBAAA;AAPF;;AAUA;EACC,eAAA;AAPD","sourcesContent":[".card {\n\tdisplay: flex;\n\t// flex-direction: column;\n\talign-items: center;\n\t// align-self: stretch;\n\tjustify-content: space-between;\n\n\tborder-radius: 8px;\n\tbackground: var(--background-tertiary, #f7f9fc);\n\n\theight: 48px;\n\tpadding: 10px;\n\n\tp {\n\t\tdisplay: flex;\n\t\tgap: 6px;\n\n\t\tcolor: var(--text-secondary, #5c6168);\n\n\t\t/* Body/Regular */\n\t\tfont-family: Poppins;\n\t\tfont-size: 15px;\n\t\tfont-style: normal;\n\t\tfont-weight: 400;\n\t\tline-height: 20px; /* 133.333% */\n\t\tletter-spacing: -0.5px;\n\t}\n\n\t.extension {\n\t\tcolor: var(--text-link, #1480e1);\n\t\ttext-align: right;\n\n\t\t/* Body/Bold */\n\t\tfont-family: Poppins;\n\t\tfont-size: 15px;\n\t\tfont-style: normal;\n\t\tfont-weight: 500;\n\t\tline-height: 20px; /* 133.333% */\n\t\tletter-spacing: -0.5px;\n\t}\n}\n.actionButton{\n\tcursor: pointer;\t\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `Merge_card__BFlUu`,
	"extension": `Merge_extension__6XOfQ`,
	"actionButton": `Merge_actionButton__UxzQd`
};
export default ___CSS_LOADER_EXPORT___;
