import React, { useEffect, useRef, useState } from "react";
import styles from "./SidecarSidebar.module.scss";
import Backdrop from "components/UI/Backdrop";
// import SettingsIcon from "components/Voicemail/Settings";
import DetachUndock from "components/UI/Icons/DetachUndock";
import NoActions from "../NoActions";
import Extension from "../Extension";
import Queues from "../Queues";
import { useDispatch, useSelector } from "react-redux";
import { sidecarConfig, tabSelected } from "redux/sidecar/sidecarSelectors";
import { setTabSelected, setShowSidecarSidebar, setFullScreen } from "redux/sidecar/sidecarSlice";
import SettingsIcon from "components/UI/Icons/Fills/Settings";
import { useNavigate } from "react-router";
import routePaths from "./../../../constants/routes";
import ActionsBlock from "../ActionsBlock";
import { useLazyGetSidecarConfigURLQuery } from "services/storage";
import axios from "axios";
import { saveSidecarConfigToLocalStorage, sidecarUploadFromServer } from "utils/sidecar/sidecarXML";
import { compareCacheTimeOver } from "helpers";
import FullScreen from "components/UI/Icons/FullScreen";

const SidecarSidebar = () => {
	const selectedTab = useSelector(tabSelected);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const sidecarConfigList = useSelector(sidecarConfig);
	const [getSidecarConfigURL] = useLazyGetSidecarConfigURLQuery();
	const sidecarApiCalled = useRef(false);
	const { userId, extAuth } = useSelector((state: any) => state.sip);
	const [confLoadingFromServer, setConfLoadingFromServer] = useState(false)
	useEffect(() => {
		if (extAuth !== false) return;
		if (sidecarApiCalled.current) return;
		if (userId === undefined) return;
		sidecarApiCalled.current = true;
		if (sidecarConfigList.length !== 0) return;
		const fetchData = async () => {
			try {
				setConfLoadingFromServer(true)
				const { data } = await getSidecarConfigURL(userId);
				const url = data.url;
				// console.log(url);
				if (url) {
					//   console.log(data);
					const response = await axios.get(url, { responseType: "blob" });
					const blob = new Blob([response.data], { type: "application/xml" });
					const xmlString = await blob.text();
					sidecarUploadFromServer(xmlString);
					saveSidecarConfigToLocalStorage(xmlString);
					setConfLoadingFromServer(false)
				}
			} catch (err) {
				// handle error
				setConfLoadingFromServer(false)
			}
		};

		const sidecarLocal = localStorage?.getItem("sidecarConfig");

		if (sidecarLocal) {
			if (compareCacheTimeOver(JSON.parse(localStorage.getItem("next_fetch") || "{}")?.sidecar, 8)) {
				fetchData();
			}else{
				sidecarUploadFromServer(sidecarLocal);
			}
		} else {
			fetchData();
		}
	}, [userId]);

	return (
		<>
			{/* <div className={styles.backdrop}></div> */}
			{/* <Backdrop
				onCancel={() => {
					dispatch(setShowSidecarSidebar(false));
				}}
			/> */}
			<div className={styles.sidecarBar}>
				<div className={styles.topSection}>
					<h1>
						<span>Sidecar</span>
						<span>
							{selectedTab === "general" && <span onClick={()=>{navigate(routePaths.SIDECAR.ROUTE);dispatch(setFullScreen(false));dispatch(setShowSidecarSidebar(false));}}><SettingsIcon /></span>}
							<span className={styles.alignCenter} onClick={()=>{navigate(routePaths.SIDECAR.ROUTE);dispatch(setFullScreen(true));dispatch(setShowSidecarSidebar(false))}} ><FullScreen/></span>
							<span onClick={()=>{dispatch(setShowSidecarSidebar(false))}} className={styles.alignCenter}><DetachUndock /></span>
						</span>
					</h1>
					<div className={styles.tabBox}>
						<span
							className={`${selectedTab === "extension" && styles.activeTab}`}
							onClick={() => {
								dispatch(setTabSelected("extension"));
							}}>
							Extension
						</span>
						<span
							className={`${selectedTab === "general" ? styles.activeTab : ""}`}
							onClick={() => {
								dispatch(setTabSelected("general"));
							}}>
							General
						</span>
						<span
							className={`${selectedTab === "queues" && styles.activeTab}`}
							onClick={() => {
								dispatch(setTabSelected("queues"));
							}}>
							Queues
						</span>
					</div>
				</div>
				<div className={styles.btmSection}>
					{selectedTab === "general" && (sidecarConfigList.length > 0 || confLoadingFromServer ? <ActionsBlock loading={confLoadingFromServer}/> : <NoActions />)}
					{selectedTab === "extension" && <Extension />}
					{selectedTab === "queues" && <Queues />}
				</div>
			</div>
		</>
	);
};

export default SidecarSidebar;
