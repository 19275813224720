import React from "react";

const MergeIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
			<path
				d="M9 6.75V6.85315M9 6.85315C9 7.68388 8.72416 9.093 8.21576 9.75L6.03424 11.9673C5.52584 12.6243 5.25 13.4315 5.25 14.2622V15M9 6.85315C9 7.68388 9.27584 9.093 9.78424 9.75L11.9658 11.9673C12.4742 12.6243 12.75 13.4315 12.75 14.2622V15M9 2.25L4.5 6.75H13.5L9 2.25Z"
				stroke="#6C7A8B"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default MergeIcon;
