import React, { useState } from "react";
import styles from "./PauseDialogue.module.scss";
import Backdrop from "components/UI/Backdrop";
import CloseIcon from "components/UI/Icons/Close";
import PauseIcon from "components/UI/Icons/Sidecar/Pause";
import { useDispatch } from "react-redux";
import { addSidecarConfigNewTempActionList, setShowChooseActions, setShowPauseDialogue } from "redux/sidecar/sidecarSlice";


const PauseDialogue = ({uuid = "", secondsValue=""}) => {
	const dispatch = useDispatch()
	const [seconds, setSeconds] = useState(secondsValue);
	const back = ()=>{
		dispatch(setShowPauseDialogue({uuid:"", show:false}));
	}
	return (
		<>
			<Backdrop onCancel={()=>{ dispatch(setShowPauseDialogue({uuid:"", show:false}));if(!uuid)dispatch(setShowChooseActions(true))}}/>
			<div className={styles.dialogueBox}>
				<h1 className={styles.topHeading}>
					<span>
						<PauseIcon />
						<span>Pause</span>
					</span>

					<span className={styles.closeBox} onClick={()=>{dispatch(setShowPauseDialogue({uuid:"", show:false})) ;if(!uuid)dispatch(setShowChooseActions(true))}} >
						<CloseIcon />
					</span>
				</h1>
				<div className={styles.inputBox}>
					<label htmlFor="">Seconds</label>
					<input type="number" placeholder="Seconds" value={seconds} onChange={(e) => {setSeconds(e.target.value);}}/>
				</div>

				<div className={styles.btnBox}>
					<button 
						disabled={seconds === "0" || seconds === "" ? true:false}
						className={`${seconds === "0" || seconds === "" ? styles.disabled:{}}`}
						onClick={()=>{dispatch(addSidecarConfigNewTempActionList({name:"Pause", uuid:uuid, seconds:seconds===""?"0":seconds })); back()} }>{uuid ? "Update":"Add Action"}</button>
				</div>
			</div>
		</>
	);
};

export default PauseDialogue;
