import styles from "./ActionSkeleton.module.scss";
import Skeleton from "react-loading-skeleton";
import { useTheme } from "hooks/useTheme";

const ActionSkeleton = () => {
	const theme = useTheme();
	return (
		<div className={styles.card}>
			<Skeleton
				height={70}
				borderRadius={5}
				highlightColor={theme === "light-mode" ? "#f5f5f5" : "var(--background-primary)"}
				baseColor={theme === "light-mode" ? "#ebebeb" : "var(--background-tertiary)"}
			/>
		</div>
	);
};

export default ActionSkeleton;
