// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoActions_noAction__yiXGC {
  width: 100%;
  height: 100%;
  position: relative;
}

.NoActions_noActionBox__ccq0k {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 280px;
  margin: auto;
}
.NoActions_noActionBox__ccq0k h1 {
  color: var(--text-primary, #1f2023);
  text-align: center;
  /* Title 3/Bold */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
  letter-spacing: -0.5px;
  margin-bottom: 10px;
}
.NoActions_noActionBox__ccq0k p {
  color: var(--text-secondary, #5c6168);
  text-align: center;
  /* Body/Regular */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.5px;
}

.NoActions_addActionBox__vWmuj {
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}
.NoActions_addActionBox__vWmuj button {
  color: var(--text-on-color-, #fff) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Sidecar/NoActions/NoActions.module.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,YAAA;EACA,kBAAA;AACD;;AAEA;EACC,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;AACD;AAEC;EACC,mCAAA;EACA,kBAAA;EAEA,iBAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;EACA,sBAAA;EAEA,mBAAA;AAFF;AAKC;EACC,qCAAA;EACA,kBAAA;EAEA,iBAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;EACA,sBAAA;AAJF;;AAOA;EACC,uBAAA;EAAA,kBAAA;EACA,YAAA;AAJD;AAKC;EACC,6CAAA;AAHF","sourcesContent":[".noAction {\n\twidth: 100%;\n\theight: 100%;\n\tposition: relative;\n\t// background-color: red;\n}\n.noActionBox {\n\tposition: absolute;\n\ttop: 45%;\n\tleft: 50%;\n\ttransform: translate(-50%, -50%);\n\ttext-align: center;\n\twidth: 280px;\n\tmargin: auto;\n\t// background-color: green;\n\n\th1 {\n\t\tcolor: var(--text-primary, #1f2023);\n\t\ttext-align: center;\n\n\t\t/* Title 3/Bold */\n\t\tfont-family: Poppins;\n\t\tfont-size: 20px;\n\t\tfont-style: normal;\n\t\tfont-weight: 600;\n\t\tline-height: 24px; /* 120% */\n\t\tletter-spacing: -0.5px;\n\n\t\tmargin-bottom: 10px;\n\t}\n\n\tp {\n\t\tcolor: var(--text-secondary, #5c6168);\n\t\ttext-align: center;\n\n\t\t/* Body/Regular */\n\t\tfont-family: Poppins;\n\t\tfont-size: 14px;\n\t\tfont-style: normal;\n\t\tfont-weight: 400;\n\t\tline-height: 20px; /* 142.857% */\n\t\tletter-spacing: -0.5px;\n\t}\n}\n.addActionBox{\n\twidth: fit-content;\n\tmargin: auto;\n\tbutton{\n\t\tcolor: var(--text-on-color-, #fff) !important;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noAction": `NoActions_noAction__yiXGC`,
	"noActionBox": `NoActions_noActionBox__ccq0k`,
	"addActionBox": `NoActions_addActionBox__vWmuj`
};
export default ___CSS_LOADER_EXPORT___;
