// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pause_card__vuQFY {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: var(--background-tertiary, #f7f9fc);
  height: 48px;
  padding: 10px;
  color: var(--text-secondary, #5c6168);
  /* Body/Regular */
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.5px;
}
.Pause_card__vuQFY p {
  display: flex;
  gap: 6px;
  color: var(--text-secondary, #5c6168);
  /* Body/Regular */
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.5px;
}
.Pause_card__vuQFY .Pause_extension__NS6AT {
  color: var(--text-link, #1480e1);
  text-align: right;
  /* Body/Bold */
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.5px;
}

.Pause_actionButton__rzs\\+R {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Sidecar/Actions/Pause/Pause.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EAEA,mBAAA;EAEA,8BAAA;EAEA,kBAAA;EACA,+CAAA;EAEA,YAAA;EACA,aAAA;EAEA,qCAAA;EAEA,iBAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;EACA,sBAAA;AALD;AAOC;EACC,aAAA;EACA,QAAA;EAEA,qCAAA;EAEA,iBAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;EACA,sBAAA;AAPF;AAUC;EACC,gCAAA;EACA,iBAAA;EAEA,cAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;EACA,sBAAA;AATF;;AAYA;EACC,eAAA;AATD","sourcesContent":[".card {\n\tdisplay: flex;\n\t// flex-direction: column;\n\talign-items: center;\n\t// align-self: stretch;\n\tjustify-content: space-between;\n\n\tborder-radius: 8px;\n\tbackground: var(--background-tertiary, #f7f9fc);\n\n\theight: 48px;\n\tpadding: 10px;\n\n\tcolor: var(--text-secondary, #5c6168);\n\n\t/* Body/Regular */\n\tfont-family: Poppins;\n\tfont-size: 15px;\n\tfont-style: normal;\n\tfont-weight: 400;\n\tline-height: 20px; /* 133.333% */\n\tletter-spacing: -0.5px;\n\n\tp {\n\t\tdisplay: flex;\n\t\tgap: 6px;\n\n\t\tcolor: var(--text-secondary, #5c6168);\n\n\t\t/* Body/Regular */\n\t\tfont-family: Poppins;\n\t\tfont-size: 15px;\n\t\tfont-style: normal;\n\t\tfont-weight: 400;\n\t\tline-height: 20px; /* 133.333% */\n\t\tletter-spacing: -0.5px;\n\t}\n\n\t.extension {\n\t\tcolor: var(--text-link, #1480e1);\n\t\ttext-align: right;\n\n\t\t/* Body/Bold */\n\t\tfont-family: Poppins;\n\t\tfont-size: 15px;\n\t\tfont-style: normal;\n\t\tfont-weight: 500;\n\t\tline-height: 20px; /* 133.333% */\n\t\tletter-spacing: -0.5px;\n\t}\n}\n.actionButton{\n\tcursor: pointer;\t\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `Pause_card__vuQFY`,
	"extension": `Pause_extension__NS6AT`,
	"actionButton": `Pause_actionButton__rzs+R`
};
export default ___CSS_LOADER_EXPORT___;
