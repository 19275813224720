import { IIcon } from "constants/interfaces";

const Transfer2Icon = ({ color }: IIcon) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path
				d="M11.3333 7.33333L14 4.66667M14 4.66667L11.3333 2M14 4.66667H6M4.66667 14L2 11.3333M2 11.3333L4.66667 8.66667M2 11.3333H10"
				stroke={`var(--${color ? color : "icon-primary"})`}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default Transfer2Icon;
