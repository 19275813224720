import { IIcon } from "constants/interfaces";
import React from "react";

const FaxIcon: React.FC<IIcon> = ({ color }) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="line / fax">
			<path
				id="Vector"
				d="M12.6663 10.3333C12.6663 9.78105 12.2186 9.33333 11.6663 9.33333H4.33301C3.78072 9.33333 3.33301 9.78105 3.33301 10.3333M12.6663 10.3333C12.6663 10.8856 13.1141 11.3333 13.6663 11.3333C14.2186 11.3333 14.6663 10.8856 14.6663 10.3333V6C14.6663 5.26362 14.0694 4.66667 13.333 4.66667H2.66634C1.92996 4.66667 1.33301 5.26362 1.33301 6V10.3333C1.33301 10.8856 1.78072 11.3333 2.33301 11.3333C2.88529 11.3333 3.33301 10.8856 3.33301 10.3333M12.6663 10.3333V13.3333C12.6663 13.7015 12.3679 14 11.9997 14H3.99967C3.63148 14 3.33301 13.7015 3.33301 13.3333V10.3333M12.6663 6.66667H11.9997M3.99967 3.33333V2.66667C3.99967 2.29848 4.29815 2 4.66634 2H11.333C11.7012 2 11.9997 2.29848 11.9997 2.66667V3.33333"
				stroke={`var(--${color ? color : "icon-primary"})`}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</g>
	</svg>
);

export default FaxIcon;
