import React, { useState } from "react";
import styles from "./Transfer.module.scss";
import PauseIcon from "components/UI/Icons/Sidecar/Pause";
import DeleteIcon from "components/UI/Icons/Delete";
import TransferIcon from "components/UI/Icons/Sidecar/Transfer";
import EditIcon from "components/UI/Icons/Lines/Edit";
import { useDispatch } from "react-redux";
import { removeSidecarConfigNewTempActionList, setShowTransferDialogue } from "redux/sidecar/sidecarSlice";
import MoveUpDown from "components/UI/Icons/Sidecar/MoveUpDown";

const TransferActionCard = ({uuid, number, transferTypeValue, drag, changesMade}:{uuid:string, number:string, transferTypeValue:string, drag?:any, changesMade:()=>void}) => {
	const [editHover, setEditHover] = useState(false);
	const [deleteHover, setDeleteHover] = useState(false);
	const dispatch = useDispatch();
	return (
		<div className={styles.card} draggable onDragEnd={drag?.handlesort}  onDragEnter={()=>{drag?.dragEnter(drag)}} onDragStart={()=>{drag?.dragStart(drag)}}> 
			<p>
				{drag && <MoveUpDown />}
				<TransferIcon />
				<span>{transferTypeValue==="1"?"Attended ":""}Transfer</span>
			</p>
			<p>
				<span className={styles.extension}>{number}</span>
				{uuid &&
				<>
					<span className={styles.actionButton} onClick={()=>{changesMade(); dispatch(setShowTransferDialogue({uuid:`${uuid}`, show:true, transferTypeValue:`${transferTypeValue}`, numberValue:`${number}`}))}} onMouseOver={() => {setEditHover(true);}} onMouseOut={() => {setEditHover(false);}}><EditIcon color={editHover?"primary-default" : "icon-primary"}/></span>
					<span className={styles.actionButton} onClick={()=>{changesMade(); dispatch(removeSidecarConfigNewTempActionList(uuid))}} onMouseOver={() => {setDeleteHover(true);}} onMouseOut={() => {setDeleteHover(false);}}><DeleteIcon color={deleteHover?"support-danger-default" : "icon-primary"} /></span>
				</>}
			</p>
		</div>
	);
};

export default TransferActionCard;
