import { apiService } from "./api";

export const accountService = apiService.injectEndpoints({
	endpoints: (build) => ({
		getAccountDetails: build.query({
			query: (queries) => ({
				method: "GET",
				url: `/system/account`,
			}),
		}),
		getLoginSSO: build.query({
			query: (email) => ({
				method: "GET",
				url: `/login-urls`,
				params: {
					email,
				},
			}),
		}),
	}),
});

export const { useLazyGetAccountDetailsQuery, useLazyGetLoginSSOQuery } = accountService;
